import {
  UpdateStartedMutationVariables,
  UpdateArrivedAtBreakdownLocationMutationVariables,
  UpdateFinishedAtBreakdownLocationMutationVariables,
  UpdateArrivedAtDestinationMutationVariables,
  UpdateFinalizedMutationVariables,
  UpdateFinishedAtDestinationMutationVariables,
} from './types'
import { EditStartRouteFormValues } from '../../../containers/TowingRecordPage/components/routes/subforms/StartRoute/types'
import { Geolocation } from '../../Geolocation/types'
import { toALScalarTimestamp } from '../../../containers/TowingRecordPage/components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { EditArrivalRouteFormValues } from '../../../containers/TowingRecordPage/components/routes/subforms/ArrivalRoute/types'
import { EditFinishRouteFormValues } from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/types'
import { toScalarShortDate, toScalarTime } from '../../../util/convertToScalar'
import { TowingRecord } from '../../../containers/TowingRecordPage/ViewTowingRecord/types'
import { SosServiceOrder, SosServiceOrderMutationInput } from '../types'
// import { RestSosIncidentCause, RestSosServiceOrderType } from '../../../functions/graphqlApi/types/sosServiceOrder'

export function updateStartedToMutationVariables(
  values: EditStartRouteFormValues,
  towingRecordId: number
): UpdateStartedMutationVariables {
  if (!values.sosServiceOrder) {
    return {
      input: {
        id: towingRecordId,
        startedDetails: {
          estimatedTimeOfArrival: null,
          location: {
            address: null,
            city: null,
            zipcode: null,
            description: null,
            coords: {
              lat: null,
              long: null,
            },
          },
          jobStartedAt: null,
        },
      },
    }
  }
  const {
    sosServiceOrder: { estimatedTimeOfArrival, currentLocation, jobStarted },
    startRoute,
  } = values
  return {
    input: {
      id: towingRecordId,
      startedDetails: {
        estimatedTimeOfArrival: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
        location: {
          address: currentLocation.address,
          city: currentLocation.city,
          zipcode: currentLocation.zipcode,
          description: startRoute.description,
          coords: getLatLong(currentLocation),
        },
        jobStartedAt: jobStarted ? toALScalarTimestamp(jobStarted) : null,
      },
    },
  }
}

export function updateArrivedAtBreakdownLocationToMutationVariables(
  values: EditArrivalRouteFormValues,
  towingRecordId: number
): UpdateArrivedAtBreakdownLocationMutationVariables {
  if (!values) {
    return {
      input: {
        id: towingRecordId,
        arrivedAtBreakdownLocationDetails: {
          location: {
            address: null,
            city: null,
            zipcode: null,
            description: null,
            coords: {
              lat: null,
              long: null,
            },
          },
          arrivedAtBreakdownLocation: null,
          notes: null,
          vehicleRegNo: null,
        },
      },
    }
  }

  const {
    arrivalRoute: {
      address,
      zipcode,
      city,
      description,
      date,
      time,
      coords: { lat, long },
    },
    sosServiceOrder,
  } = values

  return {
    input: {
      id: towingRecordId,
      arrivedAtBreakdownLocationDetails: {
        location: {
          address,
          zipcode,
          city,
          description,
          coords:
            sosServiceOrder && sosServiceOrder.currentLocation
              ? getLatLong(sosServiceOrder.currentLocation)
              : { lat, long },
        },
        arrivedAtBreakdownLocation: {
          date: date ? toScalarShortDate(date) : null,
          time: time ? toScalarTime(time) : null,
        },
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    },
  }
}

export function updateFinishedAtBreakdownLocationToMutationVariables(
  values: EditArrivalRouteFormValues,
  towingRecordId: number
): UpdateFinishedAtBreakdownLocationMutationVariables {
  if (!values) {
    return {
      input: {
        id: towingRecordId,
        finishedAtBreakdownLocationDetails: {
          actualTimeOfCompletion: null,
          vehicleMileage: null,
          additionalInfo: '',
          deliverable: null,
          cause: null,
          notes: null,
          vehicleRegNo: null,
        },
      },
    }
  }

  const { sosServiceOrder } = values

  return {
    input: {
      id: towingRecordId,
      finishedAtBreakdownLocationDetails: {
        actualTimeOfCompletion:
          sosServiceOrder && sosServiceOrder.actualTimeOfCompletion
            ? toALScalarTimestamp(sosServiceOrder.actualTimeOfCompletion)
            : null,
        vehicleMileage: sosServiceOrder ? sosServiceOrder.vehicleMileage : null,
        additionalInfo: sosServiceOrder ? sosServiceOrder.additionalInfo : null,
        cause: sosServiceOrder && sosServiceOrder.cause ? sosServiceOrder.cause : null,
        deliverable: sosServiceOrder && sosServiceOrder.deliverable ? sosServiceOrder.deliverable : null,
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    },
  }
}

export function updateArrivedAtDestinationToMutationVariables(
  values: EditFinishRouteFormValues
): UpdateArrivedAtDestinationMutationVariables {
  const {
    id,
    finishRoute: { address, city, coords, time, date, zipcode, description },
  } = values
  const location: Geolocation = { address, city, coords, zipcode }

  return {
    input: {
      id,
      arrivedAtDestinationDetails: {
        location: {
          address,
          city,
          zipcode,
          description,
          coords: getLatLong(location),
        },
        arrivedAtDestination: { date: date ? toScalarShortDate(date) : null, time: time ? toScalarTime(time) : null },
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    },
  }
}

export function updateFinishedAtDestinationToMutationVariables(
  values: EditFinishRouteFormValues
): UpdateFinishedAtDestinationMutationVariables {
  const { id, sosServiceOrder, additionalInfo } = values

  return {
    input: {
      id,
      finishedAtDestinationDetails: {
        additionalInfo,
        vehicleMileage: sosServiceOrder ? sosServiceOrder.vehicleMileage : null,
        keyLocation: sosServiceOrder ? sosServiceOrder.keyLocation : null,
        completedAtDestination: sosServiceOrder ? toALScalarTimestamp(sosServiceOrder.completedAtDestination) : null,
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    },
  }
}

export function updateFinalizedToMutationVariables(values: TowingRecord): UpdateFinalizedMutationVariables {
  const {
    id,
    sosServiceOrder,
    jobDetails: { additionalInfo },
  } = values
  if (!sosServiceOrder) {
    return {
      input: {
        id,
        finalizedDetails: {
          additionalInfo,
          keyLocation: '',
        },
      },
    }
  }
  const { vehicleKeyLocation } = sosServiceOrder
  return {
    input: {
      id,
      finalizedDetails: {
        additionalInfo,
        keyLocation: vehicleKeyLocation,
      },
    },
  }
}

function getLatLong(location: Geolocation) {
  const {
    coords: { lat, long },
  } = location
  return { lat, long }
}

export function toSosServiceOrderMutationVariables(values: SosServiceOrder): SosServiceOrderMutationInput {
  const {
    jobId,
    caseNumber,
    priority,
    agreementName,
    estimatedCause,
    estimatedServiceType,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime,
    preOrder,
    jobTimestamps,
  } = values
  return {
    jobId,
    caseNumber,
    priority,
    agreementName,
    estimatedCause,
    estimatedServiceType,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime: towingDateAndTime ? toALScalarTimestamp(towingDateAndTime) : null,
    preOrder: {
      beginEarliestAt: preOrder.beginEarliestAt ? toALScalarTimestamp(preOrder.beginEarliestAt) : null,
      beginLatestAt: preOrder.beginLatestAt ? toALScalarTimestamp(preOrder.beginLatestAt) : null,
    },
    jobTimestamps: {
      onHoldUntil: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      estimatedTimeOfArrival: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
    },
  }
}

export function toSosServiceOrderEditMutationVariables(values: SosServiceOrder): SosServiceOrderMutationInput {
  const {
    jobId,
    caseNumber,
    priority,
    agreementName,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime,
    preOrder,
    jobTimestamps,
  } = values
  return {
    jobId,
    caseNumber,
    priority,
    agreementName,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    estimatedCause: cause,
    estimatedServiceType: deliverable,
    towingDateAndTime: towingDateAndTime ? toALScalarTimestamp(towingDateAndTime) : null,
    preOrder: {
      beginEarliestAt: preOrder.beginEarliestAt ? toALScalarTimestamp(preOrder.beginEarliestAt) : null,
      beginLatestAt: preOrder.beginLatestAt ? toALScalarTimestamp(preOrder.beginLatestAt) : null,
    },
    jobTimestamps: {
      onHoldUntil: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      estimatedTimeOfArrival: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
    },
  }
}
