import React, { useEffect, useState } from 'react'
import { RouteRow, RouteColumn, Row } from '../../layout'
import { Maybe, Time, TowingRecordJobStatus } from '../../../common/types'
// import { translateInputOptions } from '../../../util/translateInputOptions'
// import { routeTypeOptions, SosFinishRouteType } from '../types'
import { TextInput } from '../../layout/form/input'
import { ClearButton } from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/FinishRoute'
import { LocationInputMap, toMapData } from '../../layout/form/input/LocationInputMap'
import { DateTimeInput } from '../../layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { CollapseProps } from '../../Collapse/types'
import { CollapseHeader } from '../../Collapse/CollapseHeader'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { FinishRouteAction, FinishRouteProps } from '../FinishRoute/types'
import { Button } from '../../Button'
import { finishedAtDestinationEditableJobStatuses } from './FinishedForm'
import styled from 'styled-components'

interface Props extends CollapseProps {
  // clear: () => void
  // disabled: boolean
  // getValue: any
  // setValue: (field: string, value: any) => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
}

export const SosArrivedAtDestinationForm: React.FunctionComponent<Props & FinishRouteProps> = ({
  clear,
  getValue,
  setValue,
  open,
  toggle,
  action,
  setAction,
  jobStatus,
  stationId,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-arrived')

  useEffect(() => {
    if (action === 'edit-arrived') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <>
      <CollapseHeader label="SAAPUNUT MÄÄRÄNPÄÄHÄN" open={open} position="top" toggle={toggle} />
      <>
        {open && (
          <CollapseContent>
            <RouteRow columns={`2fr 1fr`}>
              <RouteColumn>
                <LocationInputMap
                  addressLabel={'Osoite'}
                  addressName={`finishRoute.address`}
                  cityLabel={`Kaupunki`}
                  cityName={`finishRoute.city`}
                  coordsName={`finishRoute.coords`}
                  zipcodeName={`finishRoute.zipcode`}
                  descriptionName={`finishRoute.description`}
                  required={edit}
                  coordsRequired={edit}
                  disabled={!edit}
                  getValue={getValue}
                  values={toMapData(
                    getValue(`finishRoute.address`),
                    getValue(`finishRoute.city`),
                    getValue(`finishRoute.coords`),
                    getValue(`finishRoute.zipcode`)
                  )}
                  setValue={setValue}
                  includeNameInAddress={false}
                  stationId={stationId}
                />
              </RouteColumn>
              <RouteColumn>
                <DateTimeInput
                  label="Saapumisaika"
                  name={`finishRoute`}
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setValue('finishRoute.date', new Date())
                    setValue('finishRoute.time', value)
                  }}
                  required={edit}
                  disabled={!edit}
                  showCurrentTimeBtn
                />
                {edit && (
                  <ClearButton
                    label="Tyhjennä"
                    category="cancel"
                    onClick={() => {
                      clear()
                    }}
                    fontWeight="normal"
                    maxWidth="100%"
                    disabled={!edit}
                    size="s"
                  />
                )}
              </RouteColumn>
            </RouteRow>
            <RouteRow columns={`1fr 1fr 1fr`}>
              <RouteColumn>
                <TextInput name="finishRoute.description" label="Korjaamon nimi" disabled={!edit} />
              </RouteColumn>
              {/* <RouteColumn />
              <RouteColumn /> */}
            </RouteRow>
            {!edit && (action === 'edit-finished' || action === 'default') && (
              <RouteRow>
                <RouteColumn>
                  <Button
                    label="Muokkaa"
                    category="editSecondary"
                    size="s"
                    onClick={() => setAction('edit-arrived')}
                    mobileWidth="100%"
                  />
                </RouteColumn>
              </RouteRow>
            )}
            {edit && arrivedAtDestinationEditableJobStatuses.includes(jobStatus) && (
              <Row alignItems="start" justify="start" direction="row" marginBottom="double" marginMobile="0 0 1rem 0">
                <CustomButton
                  label="Peruuta"
                  category="editSecondary"
                  size="s"
                  onClick={() => {
                    if (finishedAtDestinationEditableJobStatuses.includes(jobStatus)) {
                      setAction('default')
                    } else {
                      setAction('edit-finished')
                    }
                  }}
                  useStyle={false}
                />
                <Button label="Tallenna" category="save" size="s" submit />
              </Row>
            )}
          </CollapseContent>
        )}
      </>
    </>
  )
}

export const arrivedAtDestinationEditableJobStatuses = [
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_destination,
]

export const CustomButton = styled(Button)`
  margin-right: 1rem;
`
