import React, { useState } from 'react'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { TimeSelectorInput } from '../../../../../../components/layout/form/input'
import { GeoLocateAddressData } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { AutoliittoSeparatorLine } from '../styled'
import { ClearButton } from '../FinishRoute/FinishRoute'
import { Maybe, Time, TowingRecordJobStatus } from '../../../../../../common/types'
import { Geolocation, GeolocationType } from '../../../../../../components/Geolocation/types'
import GetGeolocation from '../../../../../../components/Geolocation'
import { useApolloClient } from 'react-apollo'
import { setErrorNotification } from '../../../../../../components/notification'
import { toMapData, LocationInputMap } from '../../../../../../components/layout/form/input/LocationInputMap'
import { DateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { RouteNotes } from '../../RouteNotes'
import { SosArrivalRoute } from '../../../../../../components/SosSubForm/ArrivalRoute'
import { ArrivalRouteAction } from '../../../../../../components/SosSubForm/ArrivalRoute/types'
import { Images } from '../../../../../../components/images'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface ArrivalRouteProps {
  onCancel: () => void
  regNo: string
  isAutoliitto: boolean
  setAutoliittoDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate?: () => void
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  initialValues: any
  isSos: boolean
  // formatMessage: FormatMessageType
  routeDone: boolean
  setLocation?: (locationData: Geolocation, fieldName: string) => void
  getCoordsFromAddress: boolean
  // values: any
  setValue: (field: string, value: any) => void
  getValue: any
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
  towingRecordId: number
}

export const ArrivalRoute: React.FunctionComponent<ArrivalRouteProps> = ({
  onCancel,
  regNo,
  isAutoliitto,
  setAutoliittoDateField,
  setRouteLocation,
  clear,
  initialValues,
  isSos,
  routeDone,
  setLocation,
  getCoordsFromAddress,
  setValue,
  getValue,
  action,
  setAction,
  jobStatus,
  stationId,
  towingRecordId,
}) => {
  const client = useApolloClient()

  const [showImageModal, setShowImageModal] = useState<boolean>(false)

  const label = isSos ? 'Kohde' : 'Kohteessa'
  const disableCommonForm = isSos && routeDone

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal)
  }

  return (
    <RouteWrapper>
      <RouteHeader label={label} regNo={regNo} cancel={onCancel} toggleImageModal={toggleImageModal} />
      {showImageModal && <Images onClose={toggleImageModal} towingRecordId={towingRecordId} vehicleRegNo={regNo} />}
      {isSos ? (
        <SosArrivalRoute
          setDateField={setAutoliittoDateField}
          setRouteLocation={setRouteLocation}
          clear={clear}
          setValue={setValue}
          getValue={getValue}
          routeDone={disableCommonForm}
          action={action}
          setAction={setAction}
          jobStatus={jobStatus}
          stationId={stationId}
        />
      ) : (
        <>
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          <RouteRow disabled={disableCommonForm}>
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`arrivalRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`arrivalRoute.city`}
                coordsName={`arrivalRoute.coords`}
                zipcodeName={`arrivalRoute.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`arrivalRoute.address`),
                  getValue(`arrivalRoute.city`),
                  getValue(`arrivalRoute.coords`),
                  getValue(`arrivalRoute.zipcode`)
                )}
                setValue={setValue}
                disabled={disableCommonForm}
                setRouteLocation={setRouteLocation}
                stationId={stationId}
              />
            </RouteColumn>

            <RouteColumn>
              <DateTimeInput
                label="Saapumisaika"
                name={`arrivalRoute`}
                customOnChange={() => {
                  const value = (format(new Date(), 'HH:mm') as unknown) as Time
                  setAutoliittoDateField('arrivalRoute.date')
                  setValue('arrivalRoute.time', value)
                }}
                required={isAutoliitto || isSos}
                disabled={disableCommonForm}
                showCurrentTimeBtn
              />
              {!disableCommonForm && (
                <ClearButton
                  label="Tyhjennä"
                  category="cancel"
                  onClick={() => {
                    clear()
                  }}
                  fontWeight="normal"
                  maxWidth="100%"
                  size="s"
                  disabled={disableCommonForm}
                />
              )}
            </RouteColumn>
          </RouteRow>
          {isAutoliitto && (
            <>
              <AutoliittoSeparatorLine />
              <RouteRow>
                <RouteColumn>
                  <TimeSelectorInput
                    timeSelectorLabel="Arvioitu työnkesto"
                    timeFieldLabel="Arvioitu valmistumisaika"
                    name="automobileAndTouringClubFinland.estimatedTimeOfCompletion.time"
                    required={isAutoliitto}
                    customOnChange={() =>
                      setAutoliittoDateField('automobileAndTouringClubFinland.estimatedTimeOfCompletion.date')
                    }
                  />
                </RouteColumn>
              </RouteRow>
            </>
          )}
          {isSos && (
            <>
              <AutoliittoSeparatorLine />

              {getCoordsFromAddress && (
                <GetGeolocation
                  client={client}
                  setError={setErrorNotification}
                  type={GeolocationType.geocode}
                  reportGeolocation={setLocation}
                  fieldName={`sosServiceOrder.currentLocation`}
                  address={initialValues.arrivalRoute.address || ''}
                  city={initialValues.arrivalRoute.city || ''}
                />
              )}
            </>
          )}
        </>
      )}
      <RouteNotes removeLine={isSos} />
    </RouteWrapper>
  )
}
