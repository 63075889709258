import React, { memo, useContext, useState } from 'react'
import { FormatMessageType, Maybe, TowingOrderType } from '../../../common/types'
import { HalfButton } from '../../../components/Button'
import { messages as buttonMessages } from '../../../components/Button/messages'
import {
  FieldButtonRow,
  FormPadder,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupsList,
  FormSubGroupWrapper,
  Section,
  SectionColumn,
} from '../../../components/layout'
import { TextInput } from '../../../components/layout/form/input'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { SearchVehicleInfo } from '../../../components/SearchVehicleInfo'
import { SosServiceOrderDetailsForm } from '../../../components/SosSubForm/TowingOrderForm/AddTowingOrder'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { CustomerFormFields } from '../../Customer/CustomerFormFields'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { EditCustomer } from '../../Customer/EditCustomer'
import { CustomerFormValues } from '../../Customer/types'
import { calculateStationCount } from '../../Parameters/station'
import { StateStore } from '../../StoreProvider'
import { TowingOrderSaveButtons } from '../components/SaveButtons'
import { DateSectionColumn } from '../EditTowingOrder/TowingOrderDetailsSubFormContents'
import { messages } from '../messages'
import { VisibilityType } from '../types'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { TowingOrderDetailsSubFormContents } from './TowingOrderDetailsSubFormContents'
import { TowingOrderRoutesForm } from './TowingOrderRoutesForm'
import { TowingOrderSettingsForm } from './TowingOrderSettingsForm'

interface FormContentsProps {
  submitForm: (assign: boolean, operatorId: Maybe<number>, visibility: Maybe<VisibilityType>) => void
  formatMessage: FormatMessageType
  // translatedVisibilityOptions: Option[]
  onCancelVehicleDetails: () => void
  onHandleVehicleResults: (res: VehicleInfo) => void
  onSetOrderer: (customer: CustomerFormValues) => void
  operatorDisabled: boolean
  closeForm: () => void
  showTowingDateSpan: boolean
  orderer: CustomerFormValues
  ssn: string
  businessId: string
  registrationNumber: string
  setFieldValue: (field: string, value: any) => void
  getValue: any
  isSos: boolean
  ownersHolders: OwnersHoldersTrafi[]
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
  // onChangeVisibility: (value: string | number | undefined) => void
}

/*interface Option {
  label: string
  value: string
}*/

export const TowingOrderFormContentsMemo: React.FunctionComponent<FormContentsProps> = ({
  formatMessage,
  onCancelVehicleDetails,
  onHandleVehicleResults,
  onSetOrderer,
  showTowingDateSpan,
  orderer,
  operatorDisabled,
  ssn,
  registrationNumber,
  setFieldValue,
  submitForm,
  getValue,
  isSos,
  businessId,
  ownersHolders,
  setOrdererFromTrafi
}) => {
  const [showCustomerEditForm, setShowCustomerEditForm] = useState<boolean>(false)

  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  const stationRequired = calculateStationCount(state.settings.stations) > 0

  const userId = state.currentUser ? state.currentUser.userId : null
  const userRole = state.currentUser ? state.currentUser.role : null
  const operatorId = getValue('operatorId')
  // const buttonLabel = operatorId ? 'ja osoita' : 'avoimeksi'

  return (
    <>
      {showCustomerEditForm && (
        <EditCustomer
          orderer={orderer}
          onClose={() => setShowCustomerEditForm(false)}
          onUpdate={updatedOrderer => {
            onSetOrderer(updatedOrderer)
            setShowCustomerEditForm(false)
          }}
        />
      )}
      <Form>
        <FormSubGroupsList>
          <TowingOrderSettingsForm
            operatorDisabled={operatorDisabled}
            stationRequired={stationRequired}
            notificationSettings={state.settings.notifications}
            userRole={userRole}
            isSos={isSos}
          />

          <FormSubGroupWrapper>
            <FormSubGroupHeader>
              <FormSubGroupHeading>{formatMessage(messages.vehicleDetails)}</FormSubGroupHeading>
            </FormSubGroupHeader>
            <FormSubGroup>
              <Section>
                <SectionColumn>
                  <FieldButtonRow sameBorderRadius>
                    <TextInput
                      label={formatMessage(messages.registrationNumber)}
                      name="vehicleDetails.registrationNumber"
                      required
                      toUpperCase={true}
                    />
                    <SearchVehicleInfo
                      buttonText="Vie tilaukseen"
                      handleResults={onHandleVehicleResults}
                      searchString={registrationNumber}
                      vehicleInfoCheckId={getValue('vehicleInfoCheckId')}
                    />
                  </FieldButtonRow>
                </SectionColumn>

                <SectionColumn>
                  <TextInput label={formatMessage(messages.vehicleClass)} name="vehicleDetails.vehicleClass" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.makeAndModel)} name="vehicleDetails.makeAndModel" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.insuranceCompany)} name="vehicleDetails.insuranceCompany" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.owner)} name="vehicleDetails.owner" />
                </SectionColumn>
                <SectionColumn>
                  <TextInput label={formatMessage(messages.holder)} name="vehicleDetails.holder" />
                </SectionColumn>
              </Section>
              <Section>
                <SectionColumn hideOnMobile />
                <SectionColumn hideOnMobile hideOnTablet />
                <DateSectionColumn reverse>
                  <HalfButton
                    category="cancelSecondary"
                    label={formatMessage(buttonMessages.clear)}
                    onClick={() => onCancelVehicleDetails()}
                  />
                </DateSectionColumn>
              </Section>

              {/* <ButtonRow>
                <ButtonColumn>
                  <Button
                    category="cancelSecondary"
                    label={formatMessage(buttonMessages.clear)}
                    onClick={() => onCancelVehicleDetails()}
                  />
                </ButtonColumn>
              </ButtonRow> */}
            </FormSubGroup>
          </FormSubGroupWrapper>

          <CustomerFormFields
            editCustomer={() => setShowCustomerEditForm(true)}
            orderer={orderer}
            clearOrderer={() => onSetOrderer(initialOrderer)}
            onUpdate={updatedOrderer => onSetOrderer(updatedOrderer)}
            ssn={ssn}
            setFieldValue={setFieldValue}
            isSos={isSos}
            businessId={businessId}
            ownersHolders={ownersHolders}
            setOrdererFromTrafi={setOrdererFromTrafi}
          />
          {!isSos ? (
            <TowingOrderDetailsSubFormContents formatMessage={formatMessage} showTowingDateSpan={showTowingDateSpan} />
          ) : (
            <SosServiceOrderDetailsForm getValue={getValue} setValue={setFieldValue} />
          )}
          <TowingOrderRoutesForm
            setValue={setFieldValue}
            getValue={getValue}
            orderType={isSos ? TowingOrderType.sos : TowingOrderType.default}
            deliverableName="sosServiceOrder.estimatedServiceType"
          />
        </FormSubGroupsList>
        <FormPadder>
          <TowingOrderSaveButtons
            userId={userId}
            userRole={userRole}
            operatorId={operatorId}
            handleSubmit={submitForm}
          />
        </FormPadder>
        <ScrollToError />
      </Form>
    </>
  )
}

export const TowingOrderFormContents = memo(TowingOrderFormContentsMemo)
