import { useMutation, useQuery } from '@apollo/react-hooks'
import React, { useContext, useEffect, useState } from 'react'
import { GET_CURRENT_USER } from './queries'
import { DispatchStore, StateStore } from '../StoreProvider'
import { User } from '../../common/types'
import { setCurrentUser } from '../StoreProvider/actions'
import { FullscreenModal } from '../../components/ModalPage'
import { Form, Formik } from 'formik'
import { Section, SectionColumn, Text } from '../../components/layout'
import { CheckboxInput, TextInput } from '../../components/layout/form/input'
import { Button } from '../../components/Button'
import { ButtonContainer } from '../TowingRecordPage/components/routes/subforms/styled'
import {
  MultifactorAuthenticationCodeResponse,
  MULTIFACTOR_AUTHENTICATION_CODE_MUTATION,
  SendSmsCodeResponse,
  SEND_SMS_VERIFICATION_CODE,
} from './mutation/mutation'
import { setErrorNotifications, setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { toMutationVariables } from './mutation/toMutationVariables'
import { Loading } from '../../components/responses'
import { storeLogin } from '../../graphql-client/storedState'

interface UserResponse {
  user: {
    __typename: string
    user: User
  }
}

export interface MultifactorCodeFormValues {
  code: string
  rememberMe: boolean
}

export const GetCurrentUser: React.FunctionComponent = () => {
  const { dispatch } = useContext(DispatchStore)

  const {
    state: { currentUser },
  } = useContext(StateStore)

  const queryResponse = useQuery<UserResponse>(GET_CURRENT_USER)

  useEffect(() => {
    if (queryResponse && queryResponse.data && queryResponse.data.user) {
      dispatch(setCurrentUser(queryResponse.data.user.user))
    }
  }, [dispatch, queryResponse])

  return currentUser &&
    currentUser.mfa &&
    ((currentUser.mfa.active && currentUser.mfa.status === 'pending') || currentUser.mfa.status === 'sms_pending') ? (
    <MFAModal user={currentUser} />
  ) : null
}

interface MFAModalProps {
  user: User
}

const MFAModal: React.FunctionComponent<MFAModalProps> = ({ user }) => {
  const { dispatch } = useContext(DispatchStore)
  const [view, setView] = useState<SendSMSCodeModalView>('index')

  const [submitCode, { loading }] = useMutation<MultifactorAuthenticationCodeResponse>(
    MULTIFACTOR_AUTHENTICATION_CODE_MUTATION,
    {
      onCompleted(response) {
        const { submitCode } = response
        if (submitCode.__typename === 'MultifactorAuthenticationCodeSuccess') {
          storeLogin(submitCode.accessToken)
          dispatch(setCurrentUser(submitCode.user))
        } else {
          setErrorNotifications({ data: submitCode })
          if (submitCode.error.message === 'Koodi on vanhentunut, ole hyvä ja tilaa uusi koodi.') {
            setView('send_code')
          }
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const handleClose = () => {
    setView('index')
  }

  const handleViewChange = (view: SendSMSCodeModalView) => {
    setView(view)
  }

  useEffect(() => {
    console.log('load')
  }, [])

  console.log('view', view)
  console.log('mfa_status', user.mfa.status)

  return (
    <FullscreenModal
      label="2-vaiheinen tunnistus"
      onClose={
        (user.mfa.status === 'sms_pending' && view === 'send_code') || view === 'enable_mfa' ? handleClose : undefined
      }
    >
      {user.mfa.status === 'sms_pending' && view !== 'code_sent' && view !== 'enable_mfa' && (
        <SendSMSCodeModal user={user} view={view} onViewChange={handleViewChange} />
      )}
      <Formik
        initialValues={{ rememberMe: true, code: '' }}
        onSubmit={async (values: MultifactorCodeFormValues) => {
          await submitCode({ variables: toMutationVariables(values) })
        }}
        render={() => {
          return (
            <>
              <Loading loading={loading} />
              {(user.mfa.status === 'pending' || view === 'enable_mfa') && (
                <Form>
                  <Section mobileGap="0.5rem">
                    <SectionColumn flexDirection="column">
                      <TextInput name="code" label="Sovelluksen koodi" />
                    </SectionColumn>
                    {user && user.mfa && user.mfa.rememberMeForDays > 0 && (
                      <SectionColumn>
                        <CheckboxInput
                          name="rememberMe"
                          label={`Muista tunnistautuminen ${user.mfa.rememberMeForDays} päivää tällä laitteella`}
                          margin="0 0 0.1rem 0"
                        />
                      </SectionColumn>
                    )}
                  </Section>
                  <ButtonContainer floatAtBottomNoMenu>
                    <Button label="Lähetä" category="save" size="l" maxWidth="100%" submit />
                  </ButtonContainer>
                </Form>
              )}
              {user.mfa.status === 'sms_pending' && view === 'code_sent' && (
                <Form>
                  <Section mobileGap="0.5rem" height="auto" columns={1}>
                    <SectionColumn>
                      <Text>
                        Koodi lähetetty numeroon <b>{user.mfa.phone}</b>. Syötä tekstiviestissä oleva kuusinumeroinen
                        koodi alla olevaan <b>Tekstiviestin koodi</b> -kenttään. Koodi on voimassa 3 minuuttia.
                      </Text>
                    </SectionColumn>
                  </Section>
                  <Section mobileGap="0.5rem" height="auto">
                    <SectionColumn flexDirection="column">
                      <TextInput name="code" label="Tekstiviestin koodi" maxLength="6" trim />
                    </SectionColumn>
                    {user && user.mfa && user.mfa.rememberMeForDays > 0 && (
                      <SectionColumn>
                        <CheckboxInput
                          name="rememberMe"
                          label={`Muista tunnistautuminen ${user.mfa.rememberMeForDays} päivää tällä laitteella`}
                          margin="0 0 0.1rem 0"
                        />
                      </SectionColumn>
                    )}
                  </Section>
                  <ButtonContainer floatAtBottomNoMenu>
                    <Button label={'Lähetä'} category="save" size="l" maxWidth="100%" submit />
                  </ButtonContainer>
                </Form>
              )}
            </>
          )
        }}
      />
    </FullscreenModal>
  )
}

interface SendSMSCodeModalProps extends MFAModalProps {
  onViewChange: (view: SendSMSCodeModalView) => void
  view: SendSMSCodeModalView
}

type SendSMSCodeModalView = 'index' | 'send_code' | 'code_sent' | 'enable_mfa'

const SendSMSCodeModal: React.FC<SendSMSCodeModalProps> = ({ user, view, onViewChange }) => {
  const [sendSmsCode, { loading }] = useMutation<SendSmsCodeResponse>(SEND_SMS_VERIFICATION_CODE, {
    onCompleted(response) {
      const { sendSmsCode } = response
      if (sendSmsCode.__typename === 'SendSmsCodeSuccess') {
        onViewChange('code_sent')
      } else {
        setErrorNotifications({ data: sendSmsCode })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const handleActivateAuthenticatorClick = () => {
    let url = (process.env.REACT_APP_REST_API_URL as string).replace('rest', 'auth/mfa_index')

    url += `?returnUrl=${window.location.href}`
    window.open(encodeURI(url))
    onViewChange('enable_mfa')
  }

  const handleUseSmsCodeClick = () => {
    onViewChange('send_code')
  }
  return (
    <Formik
      initialValues={{ requestCode: true }}
      onSubmit={async () => {
        await sendSmsCode()
      }}
      render={({ handleSubmit }) => {
        return (
          <>
            <Loading loading={loading} />
            {view === 'send_code' ? (
              <>
                <Section mobileGap="0.5rem" height="auto" columns={1}>
                  <SectionColumn>
                    <Text>
                      Lähetä kertakäyttöinen koodi puhelinnumeroon <b>{user.mfa.phone}</b>. Koodi on voimassa 3
                      minuuttia.
                    </Text>
                  </SectionColumn>
                </Section>
                <ButtonContainer floatAtBottomNoMenu>
                  <Button label={'Lähetä koodi'} category="save" onClick={handleSubmit} size="l" maxWidth="100%" />
                </ButtonContainer>
              </>
            ) : (
              <>
                <Section mobileGap="0.5rem" height="auto" columns={2}>
                  <SectionColumn>
                    <Button
                      label="Ota autentikaattori käyttöön"
                      category="saveSecondary"
                      onClick={handleActivateAuthenticatorClick}
                      maxWidth="100%"
                      size="m"
                    />
                  </SectionColumn>
                  <SectionColumn>
                    <Button
                      label="Lähetä koodi tekstiviestillä"
                      category="new"
                      onClick={handleUseSmsCodeClick}
                      maxWidth="100%"
                      size="m"
                    />
                  </SectionColumn>
                </Section>
              </>
            )}
          </>
        )
      }}
    />
  )
}
