import React, { useEffect, useState } from 'react'
import { RouteRow, RouteColumn, Row } from '../../layout'
import { AutoliittoSeparatorLine } from '../../../containers/TowingRecordPage/components/routes/subforms/styled'
import { TextInput, TextAreaInput } from '../../layout/form/input'
import { DateTimeInput } from '../../layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time, TowingRecordJobStatus } from '../../../common/types'
import { CollapseProps } from '../../Collapse/types'
import { FinishRouteProps } from '../FinishRoute/types'
import { CollapseHeader } from '../../Collapse/CollapseHeader'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { Button } from '../../Button'
import { CustomButton } from './ArrivalForm'

// interface Props extends CollapseProps {
//   disabled: boolean
//   setValue: (fieldName: string, value: any) => void
// }

export const SosFinishedAtDestinationForm: React.FunctionComponent<FinishRouteProps & CollapseProps> = ({
  setValue,
  action,
  open,
  toggle,
  setAction,
  jobStatus,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])
  return (
    <>
      <CollapseHeader label="VALMIS MÄÄRÄNPÄÄSSÄ" open={open} position="bottom" toggle={() => toggle()} />
      {open && (
        <>
          <CollapseContent>
            <RouteRow columns={`2fr 1fr`}>
              <RouteColumn>
                <TextInput name="sosServiceOrder.keyLocation" label="Avainten jättöpaikka" disabled={!edit} />
              </RouteColumn>
              <RouteColumn>
                <DateTimeInput
                  name="sosServiceOrder.completedAtDestination"
                  label="Valmistumisaika"
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setValue('sosServiceOrder.completedAtDestination.time', value)
                    setValue('sosServiceOrder.completedAtDestination.date', new Date())
                  }}
                  required={edit}
                  disabled={!edit}
                  showCurrentTimeBtn
                />
              </RouteColumn>
            </RouteRow>
            <RouteRow columns={`1fr`}>
              <RouteColumn>
                <TextAreaInput name="additionalInfo" label="Lisäselvitykset" disabled={!edit} />
              </RouteColumn>
            </RouteRow>
            {!edit && action === 'default' && (
              <RouteRow>
                <RouteColumn>
                  <Button
                    label="Muokkaa"
                    category="editSecondary"
                    size="s"
                    onClick={() => setAction('edit-finished')}
                    mobileWidth="100%"
                  />
                </RouteColumn>
              </RouteRow>
            )}
            {edit && finishedAtDestinationEditableJobStatuses.includes(jobStatus) && (
              <Row alignItems="start" justify="start" direction="row" marginBottom="double" marginMobile="0 0 1rem 0">
                <CustomButton label="Peruuta" category="editSecondary" size="s" onClick={() => setAction('default')} />
                <Button label="Tallenna" category="save" size="s" submit />
              </Row>
            )}
          </CollapseContent>
          <AutoliittoSeparatorLine marginBottom="0" marginTop="0" />
        </>
      )}
    </>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
