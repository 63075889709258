import React from 'react'
import { Button, ButtonCategory } from '../Button'
import {
  ButtonColumn,
  ButtonRow,
  ModalContainer,
  ModalHeader,
  ModalText,
  ModalWindow,
  Text,
  ModalWindowContent,
} from '../layout'

interface ModalProps {
  label: string
  message: string
  close: () => void
  onConfirm?: () => void
  closeLabel?: string
  confirmLabel?: string
  confirmButtonCategory?: ButtonCategory
  border?: string
}

export const Modal: React.FunctionComponent<ModalProps> = ({
  close,
  label,
  message,
  onConfirm,
  closeLabel,
  confirmLabel,
  confirmButtonCategory,
  border = null,
}) => {
  return (
    <ModalContainer>
      <ModalWindow border={border}>
        <ModalHeader>{label}</ModalHeader>
        <ModalWindowContent>
          <ModalText>
            <Text>{message}</Text>
          </ModalText>
          <ButtonRow padding="0">
            {onConfirm && (
              <ButtonColumn>
                <Button
                  category={confirmButtonCategory ? confirmButtonCategory : 'danger'}
                  label={confirmLabel ? confirmLabel : 'Vahvista'}
                  onClick={() => {
                    onConfirm()
                    close()
                  }}
                />
              </ButtonColumn>
            )}
            <ButtonColumn>
              <Button category="regular" label={closeLabel ? closeLabel : 'OK'} onClick={close} />
            </ButtonColumn>
          </ButtonRow>
        </ModalWindowContent>
      </ModalWindow>
    </ModalContainer>
  )
}
