import ApolloClient from 'apollo-client'
import React, { useCallback, useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import styled, { css } from 'styled-components'
import { Maybe } from '../../common/types'
import { Button, buttonStyle } from '../Button'
import { REVERSE_GEOCODE } from './queries'

interface Props {
  className?: string
  onReverseGeocode: (result: Maybe<ReverseGeocodeResults & CurrentLocationCoords>) => void
  onError?: (error: string) => void
  category?: keyof typeof buttonStyle
  useStyle?: boolean
  size?: 'xl' | 'l' | 's' | 'm' | 'ml'
  maxWidth?: string
  disabled?: boolean
}

interface ReverseGeocodeResults {
  address: string
  city: string
  zipcode: string
}

interface CurrentLocationCoords {
  coords: {
    lat: number
    long: number
  }
}

export interface BrowserLocationResponse {
  data: any
}

export const ReverseGeocodeButton: React.FunctionComponent<Props> = ({
  className,
  onReverseGeocode,
  onError,
  category = 'locate',
  useStyle = true,
  size,
  maxWidth,
  disabled,
}) => {
  const [loading, setLoading] = useState<boolean>(false)

  const onClick = useCallback(
    (client: ApolloClient<any>) => async () => {
      setLoading(true)

      window.navigator.geolocation.getCurrentPosition(
        async (position: { coords: { latitude: number; longitude: number } }) => {
          const { data }: BrowserLocationResponse = await client.query({
            variables: { location: { lat: position.coords.latitude, long: position.coords.longitude } },
            query: REVERSE_GEOCODE,
          })

          if (data.reverseGeocode.__typename === 'GoogleAPIGeolocationError') {
            if (onError) {
              onError('Reverse geolocation returned an error')
            }
          }

          setLoading(false)
          onReverseGeocode({
            coords: { lat: position.coords.latitude, long: position.coords.longitude },
            ...data.reverseGeocode.result,
          })
        },
        () => {
          setLoading(false)
          if (onError) {
            onError('Reverse geolocation failed to get location from browser')
          }
        }
      )
    },
    [onError, onReverseGeocode, setLoading]
  )

  return (
    <ApolloConsumer>
      {client => (
        <StyledButton
          category={category}
          className={className}
          onClick={onClick(client)}
          loading={loading}
          label="Paikanna sijainti"
          alignLeft={useStyle}
          useStyle={useStyle}
          size={size}
          maxWidth={maxWidth}
          disabled={disabled}
        />
      )}
    </ApolloConsumer>
  )
}

export const StyledButton = styled(Button)`
  ${props =>
    props.useStyle &&
    css`
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      width: auto;
    `}
`
