import React, { useCallback, useEffect, useState } from 'react'
import { Maybe, TowingRecordJobStatus } from '../../../common/types'
// import { CollapseHeader } from '../../Collapse/CollapseHeader'
import { ArrivedAtBreakdownLocation } from './ArrivedAtBreakdownLocation'
import { FinishedAtBreakdownLocation } from './FinishedAtBreakdownLocation'
import { ArrivalRouteAction, ArrivalRouteForm, ArrivalRouteProps } from './types'

interface SosArrivalRouteProps {
  routeDone: boolean
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
}

export const SosArrivalRoute: React.FunctionComponent<ArrivalRouteProps & SosArrivalRouteProps> = props => {
  const [selectedForm, setSelectedForm] = useState<ArrivalRouteForm>(props.routeDone ? 'finished' : 'arrived')
  //   const [action, setAction] = useState<ArrivalRouteAction>(props.routeDone ? 'edit-finished' : 'edit-arrived')
  const { setAction } = props
  const toggle = useCallback(() => {
    //   setSelectedForm(form)
    if (selectedForm === 'arrived') {
      setSelectedForm('finished')
    } else {
      setSelectedForm('arrived')
    }
  }, [selectedForm, setSelectedForm])

  useEffect(() => {
    if (props.routeDone && props.action === 'edit-finished') {
      if (props.jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
        setSelectedForm('finished')
        // setAction('edit-finished')
      }
    }
  }, [props.routeDone, setSelectedForm, setAction, props.jobStatus, props.action])
  return (
    <>
      <ArrivedAtBreakdownLocation
        open={selectedForm === 'arrived'}
        // action={action}
        toggle={() => toggle()}
        {...props}
      />
      {/* <CollapseHeader
        label="COPART"
        position="middle"
        open={selectedForm === 'copart'}
        toggle={() => toggle('copart')}
      /> */}
      <FinishedAtBreakdownLocation
        open={selectedForm === 'finished'}
        // action={action}
        toggle={() => toggle()}
        {...props}
      />
    </>
  )
}
