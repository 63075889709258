import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../../../../components/Button'
import { ButtonColumn, ButtonRow, Heading2, ModalContainer, Text } from '../../../../../../components/layout'
import { SelectInput } from '../../../../../../components/layout/form/input'
import { Styling } from '../../../../../../components/Styling'
import { repairShopOptions } from './options'
import { Maybe } from '../../../../../../common/types'

interface RepairShopProps {
  onCancel: () => void
  onResetShop: () => void
  onSetShop: (shopId: Maybe<string>) => void
  repairShopId?: Maybe<string>
  serviceChannel: Maybe<string> // AL.serviceChannel
}

// ^ get AL.serviceChannel from parent

export const RepairShopModal: React.FunctionComponent<RepairShopProps> = ({
  onCancel,
  onResetShop,
  onSetShop,
  repairShopId = null,
  serviceChannel = null,
}) => {
  const [selectedShop, setSelectedShop] = useState<string | null>(repairShopId)

  const updateSelectedShop = (value: string | number | undefined | null) => {
    if (value) {
      setSelectedShop(value.toString())
    } else {
      setSelectedShop(null)
    }
  }
  return (
    <ModalContainer>
      <Modal>
        <ModalRow>
          <Heading2>Vaihda korjaamoa</Heading2>
          <Styling color="red" bold>
            <Text>HUOM! Korjaamon vaihto voi vaikuttaa työn korvattavuuteen.</Text>
          </Styling>
        </ModalRow>
        <ModalRow>
          <SelectInput
            label="Valitse uusi korjaamo"
            name="automobileAndTouringClubFinland.repairShopId"
            options={repairShopOptions(serviceChannel, repairShopId)}
            clearable
            onChange={updateSelectedShop}
          />
        </ModalRow>

        <ModalButtonRow>
          <ButtonColumn>
            <Button
              label="Valitse korjaamo"
              category="save"
              disabled={!selectedShop}
              onClick={() => onSetShop(selectedShop)}
            />
          </ButtonColumn>
          <ButtonColumn>
            <Button label="Muu osoite" category="clear" onClick={onResetShop} />
          </ButtonColumn>
          <ButtonColumn>
            <Button label="Peru" category="cancel" onClick={onCancel} />
          </ButtonColumn>
        </ModalButtonRow>
      </Modal>
    </ModalContainer>
  )
}

const ModalButtonRow = styled(ButtonRow)`
  justify-content: center;
`

const Modal = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalRow = styled.div`
  width: 100%;
  max-width: ${props => props.theme.screenSize.mobile};
  margin-top: 1rem;
  margin-bottom: 1rem;
`
