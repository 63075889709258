import { getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'

import {
  DateSectionColumn,
  HalfDateInput,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import sos from '../../../images/sos.gif'
import { Option } from '../../../styled/styled'
import { Column, FormSubGroup, FormSubGroupHeading, ImageWrapper, Row, Section, SectionColumn } from '../../layout'
import { SelectInput, TextAreaInput, TextInput } from '../../layout/form/input'
import { sosCauseFilters, sosVariables } from '../types'
import { SosFormSubGroupHeader, SosFormSubGroupWrapper } from './EditTowingOrder'

interface SosServiceOrderDetailsFormProps {
  getValue: any
  setValue: any
}

export const SosServiceOrderDetailsForm: React.FunctionComponent<SosServiceOrderDetailsFormProps> = ({
  getValue,
  setValue,
}) => {
  const deliverable = getValue('sosServiceOrder.estimatedServiceType')
  const [causeOptions, setCauseOptions] = useState<Option[]>(() => {
    if (!deliverable) {
      return []
    }

    const causes = getIn(sosCauseFilters, deliverable as string)

    if (!causes) {
      return []
    }

    const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

    return options
  })

  const onDeliverableChange = useCallback(
    (deliverable: string | number | undefined) => {
      if (!deliverable) {
        setCauseOptions([])
      }

      const causes = getIn(sosCauseFilters, deliverable as string)

      if (!causes) {
        setCauseOptions([])
      }

      const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

      setCauseOptions(options)
      setValue('sosServiceOrder.estimatedCause', null)
    },
    [setValue]
  )

  const deliverables = sosVariables.deliverable

  useEffect(() => {
    if ((deliverable as string) !== 'Empty ride' && deliverables.length === 9) {
      deliverables.pop()
    }
  }, [deliverable, deliverables])
  return (
    <SosFormSubGroupWrapper>
      <SosFormSubGroupHeader>
        <Row>
          <Column>
            <FormSubGroupHeading>
              <ImageWrapper>
                <img src={sos} alt="SOS" width={`95px`} />
              </ImageWrapper>
            </FormSubGroupHeading>
          </Column>
          {/* <Column justify="flex-end">
            <RadioButtonGroup
              label={''}
              name="jobDetails.towingDateType"
              options={translatedOrderScheduleOptions}
            ></RadioButtonGroup>
          </Column> */}
        </Row>
      </SosFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <HalfDateInput label={'Työn pvm'} name="jobDetails.towingDate" required />
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" />
          </DateSectionColumn>
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'SOS Viite'} name="sosServiceOrder.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vakuutusyhtiö"
              name="sosServiceOrder.insuranceCompany"
              options={sosVariables.insuranceCompany}
              required
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Arvioitu toimenpide"
              name="sosServiceOrder.estimatedServiceType"
              options={deliverables}
              onChange={value => onDeliverableChange(value)}
              required
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput label="Arvioitu vika" name="sosServiceOrder.estimatedCause" options={causeOptions} required />
          </SectionColumn>
        </Section>

        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Työn lisätiedot'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="SOS Merkinnät" name="sosServiceOrder.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section>
      </FormSubGroup>
    </SosFormSubGroupWrapper>
  )
}
