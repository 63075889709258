import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { TOWING_RECORD_QUERY, TowingRecordResponse } from './TowingRecordQuery'
import { ViewTowingRecordPage } from './ViewTowingRecordPage'

interface Props {
  id?: string
  action?: string
}

const QueryResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingRecordResponse>

export const ViewTowingRecord: React.FunctionComponent<RouteComponentProps<Props>> = ({
  match: {
    params: { id, action },
  },
}) => {
  const towingRecordId = id ? parseInt(id, 10) : null
  console.log('action', action)

  const towingRecordResponse = useQuery<TowingRecordResponse>(TOWING_RECORD_QUERY, {
    fetchPolicy: 'network-only',
    skip: !towingRecordId,
    variables: { id: towingRecordId },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const refetchData = () => towingRecordResponse.refetch()
  return (
    <QueryResultHandler queryResult={towingRecordResponse} loading unauthorized notFound>
      {data => {
        if (data && data.towingRecord.__typename === 'GetTowingRecordSuccess') {
          return (
            <ViewTowingRecordPage
              refetchData={refetchData}
              queryResult={data.towingRecord.towingRecord}
              action={action}
            />
          )
        }
      }}
    </QueryResultHandler>
  )
}
