import { ItemVatCategory, JobItemDiscountType, JobItemType } from '../../common/types'
import { JobItemInput } from './types'

export const newItem: JobItemInput = {
  id: null,
  itemId: null,
  type: JobItemType.normal,
  description: '',
  discount: 0,
  discountType: JobItemDiscountType.percentage,
  grossAmount: 0,
  name: '',
  netAmount: 0,
  quantity: 1,
  unit: 'kpl',
  unitPrice: 0,
  vat: 24,
  unitPriceHasVatIncluded: false,
  vatAmount: 0,
  vatCategory: ItemVatCategory.standard,
  limitedUsage: false,
}
