import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormatMessageType, Options } from '../../../common/types'
import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  Section,
  SectionColumn,
  Row,
  Column,
} from '../../../components/layout'
import {
  DateInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  TimeInput,
  RadioButtonGroup,
} from '../../../components/layout/form/input'
import { StateStore } from '../../StoreProvider'
import { translateInputOptions } from '../../../util/translateInputOptions'

interface FormContentsProps {
  formatMessage: FormatMessageType
  showTowingDateSpan: boolean
}

const DateSectionColumn = styled(SectionColumn)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

const HalfDateInput = styled(DateInput)`
  width: calc(50% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`

const HalfTimeInput = styled(TimeInput)`
  width: calc(50% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`

const HalfTextInput = styled(TextInput)`
  width: calc(50% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`

const Divider = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.inputFieldHeight};
  padding-top: 3px;

  &::after {
    content: '-';
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    display: none;
  }
`

export const orderScheduleOptions: Options = {
  options: {
    single: 'towingOrder.scheduling_fixed',
    span: 'towingOrder.scheduling_flexible',
  },
}

export const TowingOrderDetailsSubFormContents: React.FunctionComponent<FormContentsProps> = ({
  formatMessage,
  showTowingDateSpan,
}) => {
  const translatedOrderScheduleOptions = translateInputOptions(orderScheduleOptions, formatMessage)
  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  return (
    <FormSubGroupWrapper>
      <FormSubGroupHeader>
        <Row justify="space-between">
          <Column>
            <FormSubGroupHeading>Työn tiedot</FormSubGroupHeading>
          </Column>
          <Column>
            <RadioButtonGroup
              label={''}
              name="jobDetails.towingDateType"
              options={translatedOrderScheduleOptions}
            ></RadioButtonGroup>
          </Column>
        </Row>
      </FormSubGroupHeader>
      <FormSubGroup>
        {showTowingDateSpan ? (
          <Section columns={2}>
            <DateSectionColumn>
              <HalfDateInput label={'Pvm alkaen'} name="jobDetails.towingDate" required />
              <Divider />
              <HalfDateInput label={'Pvm viimeistään'} name="jobDetails.towingEndDate" />
            </DateSectionColumn>

            <DateSectionColumn>
              <HalfTimeInput label={'Alkaen klo'} name="jobDetails.towingTime" />
              <Divider />
              <HalfTimeInput label={'Viimeistään klo'} name="jobDetails.towingEndTime" />
            </DateSectionColumn>
          </Section>
        ) : (
          <Section columns={2} marginMobile="0">
            <DateSectionColumn>
              <HalfDateInput label={'Päivämäärä'} name="jobDetails.towingDate" required />
              <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" />
            </DateSectionColumn>
            <SectionColumn />
          </Section>
        )}

        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Hinauksen syy"
              name="jobDetails.towingReason"
              options={state.settings.towingReasons.map((label: string) => ({
                label,
                value: label,
              }))}
            />
          </SectionColumn>
          <DateSectionColumn>
            <HalfTextInput label={'Tilauksen viite'} name="jobDetails.orderReference" />
            <HalfTextInput label="Vahinkotunnus" name="jobDetails.incidentCaseNo" />
          </DateSectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Lisäselvitykset'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}
