import styled, { css } from 'styled-components'

interface RowProps {
  justify?: string
  space?: 'double'
  marginBottom?: string
  direction?: string
  marginMobile?: string
  alignItems?: string
  paddingMobile?: string
  cursor?: string
}

export const Row = styled.div<RowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
    ${props =>
      props.alignItems &&
      css`
        align-items: ${props.alignItems};
      `}
  ${props =>
    props.space === 'double' &&
    css`
      && {
        padding: 1.5em;
      }
    `}
  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
  padding: 0;
  margin: ${props => (props.marginBottom === 'double' ? '0 0 1rem 0' : 0)};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    flex-direction: ${props => (props.direction ? props.direction : 'column')};
    margin: ${props => (props.marginMobile ? props.marginMobile : '0.5rem 0')};
    ${props =>
      props.paddingMobile &&
      css`
        && {
          padding: ${props.paddingMobile};
        }
      `}
  }
  ${props => props.cursor && 
    css`
      cursor: ${props.cursor};
      `}
`

interface SimpleRowProps {
  reverse?: boolean
  justify?: string
  marginMobileBottom?: string
  margin?: string
  mobileMargin?: string
}

export const SimpleRow = styled.div<SimpleRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  padding: 0;
  margin: ${props => (props.margin ? props.margin : 0)};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin-bottom: ${props => (props.marginMobileBottom ? props.marginMobileBottom : '0')};
    ${props =>
      props.mobileMargin &&
      css`
        margin: ${props.mobileMargin};
      `}
  }
`
