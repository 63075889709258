import { format } from 'date-fns'
import { getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'

import { FormatMessageType, Time, TowingRecordJobStatus } from '../../../common/types'
import { Option } from '../../../styled/styled'
import { Button } from '../../Button'
import { RouteColumn, RouteRow, Row } from '../../layout'
import { NumberInput, SelectInput, TextAreaInput } from '../../layout/form/input'
import { DateTimeInput } from '../../layout/form/input/DateTimeInput'
import { ArrivalRouteAction } from '../ArrivalRoute/types'
import { CustomButton } from '../FinishRouteForm/ArrivalForm'
import { sosCauseFilters, sosVariables } from '../types'

// import { translateInputOptions } from '../../../util/translateInputOptions'
// import { incidentCauseOptions } from '../types'

// import { TextInput } from '../../layout/form/input/TextInput'

interface SosArrivalRouteFormProps {
  formatMessage?: FormatMessageType
  action: ArrivalRouteAction
  // arcCodes?: Maybe<SosArcCodesFilters>
  values?: any
  getValue: any
  setValue: any
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
}

export const SosArrivalRouteForm: React.FunctionComponent<SosArrivalRouteFormProps> = ({
  action,
  getValue,
  setValue,
  jobStatus,
  setAction,
  /* arcCodes,
  values, */
}) => {
  // const [component, setComponent] = useState<object[]>([])
  // const [description, setDescription] = useState<object[]>([])
  // const [completion, setCompletion] = useState<object[]>([])

  // const componentGroup = getComponentGroup(arcCodes)

  // const changeComponentGroup = (componentGroup: string | number | undefined) => {
  //   const component = getComponent(arcCodes, componentGroup as string)

  //   setComponent(component)
  // }

  // const changeComponent = (component: string | number | undefined) => {
  //   const descripion = getDescription(arcCodes, component as string, values.sosServiceOrder.arcCodes.componentGroup)

  //   setDescription(descripion)
  // }

  // const changeDescription = (description: string | number | undefined) => {
  //   const { componentGroup, component } = values.sosServiceOrder.arcCodes
  //   const completion = getCompletion(arcCodes, description as string, componentGroup, component)

  //   setCompletion(completion)
  // }

  // const incidentCauses = translateInputOptions(incidentCauseOptions, formatMessage)
  const deliverable = getValue('sosServiceOrder.deliverable')
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')
  const [disableDeliverable, setDisableDeliverable] = useState<boolean>(() => {
    if ((deliverable as string) === 'Empty ride') {
      return true
    }

    return false
  })

  const [causeOptions, setCauseOptions] = useState<Option[]>(() => {
    if (!deliverable) {
      return []
    }

    const causes = getIn(sosCauseFilters, deliverable as string)

    if (!causes) {
      return []
    }

    const options = sosVariables.cause.filter((cause) => causes.includes(cause.value))

    return options
  })

  const onDeliverableChange = useCallback(
    (deliverable: string | number | undefined) => {
      if (!deliverable) {
        setCauseOptions([])
      }

      const causes = getIn(sosCauseFilters, deliverable as string)

      if (!causes) {
        setCauseOptions([])
      }

      const options = sosVariables.cause.filter((cause) => causes.includes(cause.value))

      setCauseOptions(options)
      setValue('sosServiceOrder.cause', null)
    },
    [setValue]
  )

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  const deliverables = sosVariables.deliverable

  useEffect(() => {
    if ((deliverable as string) !== 'Empty ride' && deliverables.length === 9) {
      deliverables.pop()
    }

    if ((deliverable as string) === 'Empty ride') {
      setDisableDeliverable(true)
    }
  }, [deliverable, deliverables])

  return (
    <>
      {/* <RouteRow>
        <Heading2 color={edit ? 'grey600' : 'grey300'}>VALMIS KOHTEESSA</Heading2>
      </RouteRow> */}
      <>
        <RouteRow columns="1fr 1fr 1fr">
          <RouteColumn>
            <SelectInput
              name="sosServiceOrder.deliverable"
              label="Toimenpide"
              options={deliverables}
              required={edit}
              disabled={!edit || disableDeliverable}
              onChange={(value: string | number | undefined) => {
                console.log('value', value)
                onDeliverableChange(value)
              }}
            />
          </RouteColumn>
          <RouteColumn>
            <SelectInput
              name="sosServiceOrder.cause"
              label="Vika"
              options={causeOptions}
              required={edit}
              disabled={!edit || disableDeliverable}
              /* onChange={(value: string | number | undefined) => {
                  changeComponent(value)
                }} */
            />
          </RouteColumn>
          <RouteColumn>
            <DateTimeInput
              label="Valmistumisaika kohteessa"
              name="sosServiceOrder.actualTimeOfCompletion"
              required={edit}
              disabled={!edit}
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('sosServiceOrder.actualTimeOfCompletion.time', value)
                setValue('sosServiceOrder.actualTimeOfCompletion.date', new Date())
              }}
              showCurrentTimeBtn
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns="1fr 1fr 1fr">
          <RouteColumn>
            {/* <SelectInput
                name="sosServiceOrder.arcCodes.description"
                label="Vika"
                options={description}
                required={edit}
                disabled={!edit}
                onChange={(value: string | number | undefined) => {
                  changeDescription(value)
                }}
              /> */}
            <NumberInput
              name="sosServiceOrder.vehicleMileage"
              label="Asiakkaan mittarilukema"
              decimals={0}
              disabled={!edit}
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns="1fr">
          <TextAreaInput name="sosServiceOrder.additionalInfo" label="Lisäselvitykset" disabled={!edit} />
        </RouteRow>
        {!edit && action === 'default' && (
          <RouteRow>
            <RouteColumn>
              <Button
                label="Muokkaa"
                category="editSecondary"
                size="s"
                onClick={() => setAction('edit-finished')}
                mobileWidth="100%"
              />
            </RouteColumn>
          </RouteRow>
        )}
        {edit && finishedAtBreakdownLocationEditableJobStatuses.includes(jobStatus) && (
          <Row alignItems="start" justify="start" direction="row" marginBottom="double" marginMobile="0 0 1rem 0">
            <CustomButton label="Peruuta" category="editSecondary" size="s" onClick={() => setAction('default')} />
            <Button label="Tallenna" category="save" size="s" submit />
          </Row>
        )}
      </>
    </>
  )
}

export const finishedAtBreakdownLocationEditableJobStatuses = [
  // TowingRecordJobStatus.arrived_at_breakdown_location,
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_breakdown_location,
  TowingRecordJobStatus.finished_at_destination,
]

// function getComponentGroup(arcCodes: Maybe<SosArcCodesFilters>): object[] {
//   if (!arcCodes || !arcCodes.filters) {
//     return []
//   }

//   return Object.keys(arcCodes.filters).map((value: string) => {
//     return { value, label: value }
//   })
// }

// function getComponent(arcCodes: Maybe<SosArcCodesFilters>, componentGroup: string): object[] {
//   if (!arcCodes || !arcCodes.filters || !componentGroup) {
//     return []
//   }

//   let i = null

//   const values = Object.values(arcCodes.filters)

//   Object.keys(arcCodes.filters).forEach((value: string, index: number) => {
//     if (value === componentGroup) {
//       i = index
//     }
//   })

//   if (typeof i !== 'number') {
//     return []
//   }

//   const index = Object.keys(values[i]).map((value: string) => {
//     return parseInt(value)
//   })

//   const components = index.map(value => {
//     return arcCodes.components[value]
//   })

//   return components.map((value: any) => {
//     return { value: value.code, label: value.text }
//   })
// }

// function getDescription(arcCodes: Maybe<SosArcCodesFilters>, component: string, componentGroup: string): object[] {
//   if (!arcCodes || !arcCodes.filters || !component) {
//     return []
//   }

//   let i = null

//   const values = Object.values(arcCodes.filters)

//   Object.keys(arcCodes.filters).forEach((value: string, index: number) => {
//     if (value === componentGroup) {
//       i = index
//     }
//   })

//   if (typeof i !== 'number') {
//     return []
//   }

//   let y = null

//   Object.keys(values[i]).forEach((value: string, index: number) => {
//     if (value === component) {
//       y = index
//     }
//   })

//   if (typeof y !== 'number') {
//     return []
//   }

//   const componentFilter = Object.values(values[i])

//   const index = Object.keys(componentFilter[y]).map(value => {
//     return parseInt(value)
//   })

//   const descriptions = index.map(value => {
//     return arcCodes.descriptions[value]
//   })

//   return descriptions.map((value: any) => {
//     return { value: value.code, label: value.text }
//   })
// }

// function getCompletion(
//   arcCodes: Maybe<SosArcCodesFilters>,
//   description: string,
//   componentGroup: string,
//   component: string
// ) {
//   if (!arcCodes || !arcCodes.filters || !component) {
//     return []
//   }

//   let i = null

//   const values = Object.values(arcCodes.filters)

//   Object.keys(arcCodes.filters).forEach((value: string, index: number) => {
//     if (value === componentGroup) {
//       i = index
//     }
//   })

//   if (typeof i !== 'number') {
//     return []
//   }

//   let y = null

//   Object.keys(values[i]).forEach((value: string, index: number) => {
//     if (value === component) {
//       y = index
//     }
//   })

//   if (typeof y !== 'number') {
//     return []
//   }

//   const componentFilter = Object.values(values[i])

//   let t = null

//   Object.keys(componentFilter[y]).forEach((value: string, index: number) => {
//     if (value === description) {
//       t = index
//     }
//   })

//   if (typeof t !== 'number') {
//     return []
//   }

//   const descriptionFilter = Object.values(componentFilter[y])

//   console.log('descriptionFilter', descriptionFilter)

//   const filters = descriptionFilter.map((value: any) => {
//     return value.Repair.concat(value.Towing)
//   })

//   console.log('filters', filters[0])

//   const completionsFilter = filters[0]

//   const completions = completionsFilter.map((value: any) => {
//     return arcCodes.completions[value]
//   })

//   return completions.map((value: any) => {
//     return { value: value.code, label: value.text }
//   })
// }
