import React from 'react'
import { TowingOrderType } from '../../../common/types'
import { LocationInputMap, toMapData } from '../../../components/layout/form/input/LocationInputMap'
import { TextInput } from '../../../components/layout/form/input/TextInput'
import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
} from '../../../components/layout/formsubgroup'
import { Section, SectionColumn } from '../../../components/layout/section'

interface Props {
  getValue: any
  setValue: any
  orderType: TowingOrderType
  deliverableName?: string
}

export const TowingOrderRoutesForm: React.FunctionComponent<Props> = ({ getValue, setValue, orderType }) => {
  // const deliverable = getValue(deliverableName)
  // const [toRequired, setToRequired] = useState<boolean>(() => {
  //   if (orderType !== TowingOrderType.sos) {
  //     return false
  //   }

  //   if (!deliverable || !sosVariables.towingDeliverables.includes(deliverable)) {
  //     return false
  //   }
  //   return true
  // })

  // useEffect(() => {
  //   if (deliverable && sosVariables.towingDeliverables.includes(deliverable)) {
  //     setToRequired(true)
  //   }

  //   if (!deliverable || !sosVariables.towingDeliverables.includes(deliverable)) {
  //     setToRequired(false)
  //   }
  // }, [deliverable])

  // console.log('deliverable', deliverable)
  const stationId = getValue('stationId')
  return (
    <FormSubGroupWrapper>
      <FormSubGroupHeader>
        <FormSubGroupHeading>Kohteet</FormSubGroupHeading>
      </FormSubGroupHeader>
      <FormSubGroup>
        <Section columnCss="2fr 1fr" tabletColumnCss="2fr 1fr" justify="text">
          <SectionColumn>
            <LocationInputMap
              addressLabel="Mistä"
              addressName="from.address"
              cityName="from.city"
              cityLabel="Kaupunki"
              coordsName="from.coords"
              zipcodeName="from.zipcode"
              descriptionName={orderType === TowingOrderType.sos ? 'from.description' : undefined}
              getValue={getValue}
              setValue={setValue}
              required={orderType === TowingOrderType.sos}
              coordsRequired={orderType === TowingOrderType.sos}
              includeNameInAddress={!(orderType === TowingOrderType.sos)}
              values={toMapData(
                getValue(`from.address`),
                getValue(`from.city`),
                getValue(`from.coords`),
                getValue(`from.zipcode`)
              )}
              reverse
              stationId={stationId}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput label={'Lisätieto'} name="from.description" />
          </SectionColumn>
        </Section>
        <Section columnCss="2fr 1fr" tabletColumnCss="2fr 1fr" justify="text">
          <SectionColumn>
            <LocationInputMap
              addressLabel="Mihin"
              addressName="to.address"
              cityName="to.city"
              cityLabel="Kaupunki"
              coordsName="to.coords"
              zipcodeName="to.zipcode"
              descriptionName={orderType === TowingOrderType.sos ? 'to.description' : undefined}
              getValue={getValue}
              setValue={setValue}
              // required={toRequired}
              coordsRequired={orderType === TowingOrderType.sos}
              includeNameInAddress={!(orderType === TowingOrderType.sos)}
              values={toMapData(
                getValue(`to.address`),
                getValue(`to.city`),
                getValue(`to.coords`),
                getValue(`to.zipcode`)
              )}
              reverse
              stationId={stationId}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput label={'Lisätieto'} name="to.description" />
          </SectionColumn>
        </Section>
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}
