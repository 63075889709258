import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { FormattedDate, FormattedTime, InjectedIntlProps, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { RouteHeader } from '../../containers/TowingRecordPage/components/routes/subforms/RouteHeader'
import { HalfButton } from '../Button'
import { HeaderAndDateToDate, HeaderAndMultipleValues, HeaderAndValue } from '../DisplayFields'
import { Section, SectionColumn, SectionHeader, SectionTextColumn } from '../layout/section'
import { Heading4, Text } from '../layout/typography'
import { HeaderContainer, ModalContainer, ModalWindow } from '../Map'
import { Loading } from '../responses'
import { setErrorNotifications } from '../responses/setErrorNotifications'
import { INFO_SEARCH_QUERY, InfoSearchResponse } from '../SearchCustomerInfo/queries'
import { InfoSearch } from '../SearchCustomerInfo/types'

interface InfoSearchModalProps {
  customerIdentifier: string
  customerType: string
  close: () => void
  onSave: (infoSearch: InfoSearch) => void
  saveButtonLabel?: string
}

const InfoSearchModalIntl: React.FunctionComponent<InfoSearchModalProps & InjectedIntlProps> = ({
  customerIdentifier,
  customerType,
  close,
  onSave,
  saveButtonLabel,
}) => {
  const [infoSearch, setInfoSearch] = useState<InfoSearch | null>(null)

  const [fetchInfoSearch, { loading }] = useMutation<InfoSearchResponse>(INFO_SEARCH_QUERY, {
    onCompleted({ infoSearch }) {
      if (infoSearch.__typename === 'InfoSearchSuccess') {
        setInfoSearch(infoSearch.infoSearch)
      } else {
        setErrorNotifications({ data: infoSearch })
        close()
      }
    },
    onError(err) {
      console.error(err)
    },
  })

  const createPaymentDefaultsFields: () => JSX.Element[] | JSX.Element = () => {
    if (infoSearch && infoSearch.searchResults.paymentDefaults && infoSearch.searchResults.paymentDefaults.length > 0) {
      return infoSearch.searchResults.paymentDefaults.map((record, index) => {
        return (
          <HeaderAndMultipleValues
            key={index}
            header={record.code}
            values={[record.date, `${record.amount} €`, record.creditor]}
          />
        )
      })
    } else {
      return (
        <>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Ei maksuhäiriöitä</Text>
            </SectionHeader>
          </SectionTextColumn>
        </>
      )
    }
  }

  const createCompanyRegistersFields: () => JSX.Element[] | JSX.Element = () => {
    if (infoSearch && infoSearch.searchResults.registers !== null && infoSearch.searchResults.registers.length > 0) {
      return infoSearch.searchResults.registers.map((record, index) => {
        return (
          <HeaderAndDateToDate key={index} header={record.name} startDate={record.startDate} endDate={record.endDate} />
        )
      })
    } else {
      return (
        <>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Ei tietoja</Text>
            </SectionHeader>
          </SectionTextColumn>
        </>
      )
    }
  }

  const daysSinceLastSearch: (earchDate: Date) => number = (searchDate) => {
    const thisDate = new Date()
    return thisDate.getDate() - searchDate.getDate()
  }

  const fetch = useCallback(
    async (refetch: boolean = false) => {
      fetchInfoSearch({
        variables: {
          input: {
            refetch,
            customerIdentifier: { type: 'CUSTOMER_IDENTIFIER', value: customerIdentifier },
            customerType: customerType,
          },
        },
      })
    },
    [fetchInfoSearch, customerIdentifier, customerType]
  )

  const onClick = useCallback(() => {
    close()
  }, [close])

  const onReFetch = useCallback(() => {
    fetch(true)
  }, [fetch])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      <ModalContainer>
        <ModalWindow>
          <HeaderContainer>
            <RouteHeader cancel={onClick} marginBottom="0" />
          </HeaderContainer>
          <Container>
            {!infoSearch && !loading && <Text size="medium">Tietojen haku epäonnistui</Text>}
            {!loading && infoSearch && infoSearch.searchMeta.searchTimestamp instanceof Date && (
              <WarningText size="small">
                Haettu: <FormattedDate value={infoSearch.searchMeta.searchTimestamp} />{' '}
                <FormattedTime value={infoSearch.searchMeta.searchTimestamp} />
                {' Bisnode Finland Oy:n rekisteristä'}
              </WarningText>
            )}
            {!loading && infoSearch && (
              <>
                <Section justify="text" columns={1} height="auto">
                  <InfoSearchButtonSectionColumn alignItems="start">
                    <InfoSearchButton
                      category="save"
                      size="s"
                      onClick={() => onSave(infoSearch)}
                      label={saveButtonLabel || 'Vie tilaukseen'}
                    />

                    {infoSearch &&
                      infoSearch.searchMeta.searchTimestamp instanceof Date &&
                      daysSinceLastSearch(infoSearch.searchMeta.searchTimestamp) > 0 && (
                        <InfoSearchButton category="danger" size="s" onClick={onReFetch}>
                          Päivitä
                        </InfoSearchButton>
                      )}
                  </InfoSearchButtonSectionColumn>
                </Section>
                <InfoSearchContainer>
                  {infoSearch && infoSearch.customer.__typename === 'PersonCustomer' && (
                    <>
                      <CollumnHeader>Henkilö- ja Osoitetiedot</CollumnHeader>
                      <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        <HeaderAndValue header="Etunimi" value={infoSearch.customer.firstName} />
                        <HeaderAndValue header="Sukunimi" value={infoSearch.customer.lastName} />
                        <HeaderAndMultipleValues
                          header="Osoite"
                          values={[
                            infoSearch.customer.address && infoSearch.customer.address,
                            infoSearch.customer.zipcode &&
                              infoSearch.customer.city &&
                              `${infoSearch.customer.zipcode} ${infoSearch.customer.city}`,
                          ]}
                        />
                      </Section>
                      <CollumnHeader>Maksuhäiriöt</CollumnHeader>
                      <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        {createPaymentDefaultsFields()}
                      </Section>
                    </>
                  )}
                  {infoSearch && infoSearch.customer.__typename === 'CompanyCustomer' && (
                    <>
                      <CollumnHeader>Yrityksen nimi ja osoitetiedot</CollumnHeader>
                      <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        <HeaderAndValue header="Yrityksen nimi" value={infoSearch.customer.companyName} />
                        <HeaderAndMultipleValues
                          header="Osoite"
                          values={[
                            infoSearch.customer.address && infoSearch.customer.address,
                            infoSearch.customer.zipcode &&
                              infoSearch.customer.city &&
                              `${infoSearch.customer.zipcode} ${infoSearch.customer.city}`,
                          ]}
                        />
                      </Section>
                      <CollumnHeader>Yrityksen rekisteritiedot</CollumnHeader>
                      <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        {createCompanyRegistersFields()}
                      </Section>
                      <CollumnHeader>Maksuhäiriöt</CollumnHeader>
                      <Section gap="2rem" justify="text" columns={3} tabletColumnCss="repeat( 2, minmax(200px,1fr) )">
                        {createPaymentDefaultsFields()}
                      </Section>
                    </>
                  )}
                </InfoSearchContainer>
              </>
            )}

            {loading && <Loading loading={loading} />}
          </Container>
        </ModalWindow>
      </ModalContainer>
    </>
  )
}

// TODO: Add intl message definitions to support multiple languages

export const InfoSearchModal = injectIntl(InfoSearchModalIntl)

// @TODO: Implement generic modal and refactor every modal to use it

const InfoSearchContainer = styled.div`
  widht: 100%;
  margin-bottom: 4rem;
`

const Container = styled.div`
  padding: 0 1rem 0.5rem 1rem;
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    padding: 0 0.5rem;
  }
`

const WarningText = styled(Text)`
  margin-bottom: 0.5rem;
`

const CollumnHeader = styled(Heading4)`
  border-bottom: 2px solid lightgray;
  // margin-right: 1rem;
`

/* const InfoSearchFieldContainer = styled.div`
  widht: 100%;
  margin-bottom: 2rem;
` */

const InfoSearchButton = styled(HalfButton)`
  width: calc(16% - 1rem);
  @media (max-width: ${(props) => props.theme.screenSize.tablet}) {
    width: calc(25% - 1rem);
  }
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`

const InfoSearchButtonSectionColumn = styled(SectionColumn)`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
  grid-gap: 1rem;
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    grid-gap: 0.5rem;
  }
`
