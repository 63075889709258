import { CustomerType, DeliveryMethod, NotificationMethod } from '../../../common/types'
import {
  CompanyCustomerFormValues,
  CustomerFormValues,
  EditCompanyResponse,
  EditPersonResponse,
  PersonCustomerFormValues,
} from '../types'

export const companyToOrderer = (company: EditCompanyResponse): CustomerFormValues => {
  const updatedOrderer: CustomerFormValues = Object.assign({}, initialOrderer)
  const updatedCompany: CompanyCustomerFormValues = Object.assign({}, initialCompanyCustomer)
  if (updatedOrderer.companyCustomer) {
    updatedOrderer.id = company.customer.id
    updatedCompany.businessId = company.customer.businessId ? company.customer.businessId : ''
    updatedCompany.companyName = company.customer.companyName
    updatedCompany.contactPerson = company.customer.contactPerson
    updatedCompany.contactPersonEmail = company.customer.contactPersonEmail ? company.customer.contactPersonEmail : ''
    updatedCompany.phone = company.customer.phone ? company.customer.phone : ''
    updatedCompany.address = company.customer.address
    updatedCompany.additionalInfo = company.customer.additionalInfo
    updatedCompany.city = company.customer.city ? company.customer.city : ''
    updatedCompany.co = company.customer.co
    updatedCompany.identifier = company.customer.identifier
    updatedCompany.deliveryMethod = company.customer.deliveryMethod
    updatedCompany.eInvoiceAddress = company.customer.eInvoiceAddress
    updatedCompany.eInvoiceOperator = company.customer.eInvoiceOperator
    updatedCompany.extraInfo = company.customer.extraInfo
    updatedCompany.zipcode = company.customer.zipcode ? company.customer.zipcode : ''
    updatedCompany.email = company.customer.email ? company.customer.email : ''
    updatedCompany.notificationMethod = company.customer.notificationMethod
    updatedCompany.originatorApp = company.customer.originatorApp

    updatedOrderer.type = CustomerType.company
  }
  updatedOrderer.companyCustomer = updatedCompany
  return updatedOrderer
}

export const personToOrderer = (person: EditPersonResponse): CustomerFormValues => {
  const updatedOrderer: CustomerFormValues = Object.assign({}, initialOrderer)
  const updatedPerson: PersonCustomerFormValues = Object.assign({}, initialPersonCustomer)
  if (updatedOrderer.personCustomer) {
    updatedOrderer.id = person.customer.id
    updatedPerson.lastName = person.customer.lastName
    updatedPerson.firstName = person.customer.firstName
    updatedPerson.ssn = person.customer.ssn ? person.customer.ssn : ''
    updatedPerson.phone = person.customer.phone ? person.customer.phone : ''
    updatedPerson.email = person.customer.email ? person.customer.email : ''
    updatedPerson.address = person.customer.address
    updatedPerson.additionalInfo = person.customer.additionalInfo
    updatedPerson.city = person.customer.city ? person.customer.city : ''
    updatedPerson.co = person.customer.co
    updatedPerson.identifier = person.customer.identifier
    updatedPerson.deliveryMethod = person.customer.deliveryMethod
    updatedPerson.eInvoiceAddress = person.customer.eInvoiceAddress
    updatedPerson.eInvoiceOperator = person.customer.eInvoiceOperator
    updatedPerson.extraInfo = person.customer.extraInfo
    updatedPerson.zipcode = person.customer.zipcode ? person.customer.zipcode : ''
    updatedPerson.notificationMethod = person.customer.notificationMethod
    updatedPerson.originatorApp = person.customer.originatorApp

    updatedOrderer.type = CustomerType.person
  }
  updatedOrderer.personCustomer = updatedPerson
  return updatedOrderer
}

export const initialCompanyCustomer = {
  companyName: '',
  businessId: '',
  contactPerson: '',
  contactPersonEmail: '',
  phone: '',
  address: '',
  email: '',
  identifier: '',
  extraInfo: '',
  additionalInfo: '',
  co: '',
  zipcode: '',
  city: '',
  eInvoiceAddress: '',
  eInvoiceOperator: '',
  deliveryMethod: DeliveryMethod.no_delivery,
  notificationMethod: 'disabled' as NotificationMethod,
  originatorApp: null,
}

export const initialPersonCustomer = {
  ssn: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  identifier: '',
  extraInfo: '',
  additionalInfo: '',
  co: '',
  zipcode: '',
  city: '',
  eInvoiceAddress: '',
  eInvoiceOperator: '',
  deliveryMethod: DeliveryMethod.no_delivery,
  notificationMethod: 'disabled' as NotificationMethod,
  originatorApp: null,
}

export const initialOrderer: CustomerFormValues = {
  id: null,
  type: CustomerType.person,
  personCustomer: initialPersonCustomer,
  companyCustomer: initialCompanyCustomer,
}
