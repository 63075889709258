import React from 'react'
import { RouteRow } from '../../../../components/layout'
import { TextAreaInput } from '../../../../components/layout/form/input'
import { Styling } from '../../../../components/Styling'
import { AutoliittoSeparatorLine, RouteColumn } from './subforms/styled'

interface RouteNoteProps {
  removeLine?: boolean
}

export const RouteNotes: React.FunctionComponent<RouteNoteProps> = ({ removeLine }) => {
  return (
    <>
      {!removeLine && <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />}
      {removeLine && <Styling padding="1.5rem 0 0 0" />}
      <RouteRow columns="1fr">
        <RouteColumn>
          <TextAreaInput name="notes" label="Muistiinpanot" />
        </RouteColumn>
      </RouteRow>
    </>
  )
}
