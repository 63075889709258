import { cloneDeep } from 'lodash'

import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import { ArrivalRouteMutationVariables } from './mutation'
import { EditArrivalRouteFormValues } from '../types'
import { ArrivalRoute, ArrivalRouteMutationInput } from './types'
import { DateTime } from '../../../../../../../common/types'

export const toMutationVariables = (inputValues: EditArrivalRouteFormValues): ArrivalRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  const estimatedTimeOfArrival = values.automobileAndTouringClubFinland
    ? values.automobileAndTouringClubFinland.estimatedTimeOfCompletion
    : null

  return {
    input: {
      id: values.id,
      arrivalRoute: {
        arrivalRoute: routeToMutationVariables(values.arrivalRoute),
        estimatedTimeOfCompletion: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

const routeToMutationVariables = (route: ArrivalRoute): ArrivalRouteMutationInput => {
  return {
    id: route.id,
    ordering: 1,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    date: route.date && route.time ? toScalarShortDate(route.date) : null,
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    time: route.time ? toScalarTime(route.time) : null,
    type: route.type,
    geolocated: route.geolocated,
  }
}

export const toALScalarTimestamp = (eta: DateTime) => {
  if (!eta.date) {
    eta.date = new Date()
  }

  return {
    date: eta.date && eta.date instanceof Date ? toScalarShortDate(eta.date) : null,
    time: eta.time ? toScalarTime(eta.time) : null,
  }
}

export { routeToMutationVariables as arrivalRouteToMutationVariables }
