import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { RouteComponentProps } from 'react-router'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { EDIT_TOWING_ORDER_QUERY, EditTowingOrderQueryResponse } from './editTowingOrderQuery'
import { EditTowingOrderForm } from './EditTowingOrderForm'

interface Props {
  id?: string
  scrollTo?: string
}

const QueryResultHandler = GenericResultHandler as GenericResultHandlerDefinition<EditTowingOrderQueryResponse>

export const EditTowingOrder: React.FunctionComponent<RouteComponentProps<Props>> = ({
  match: {
    params: { id, scrollTo },
  },
}) => {
  const towingOrderId = id ? parseInt(id, 10) : null
  console.log('scrollTo', scrollTo)

  const towingOrderResponse = useQuery<EditTowingOrderQueryResponse>(EDIT_TOWING_ORDER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !towingOrderId,
    variables: { id: towingOrderId },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  return (
    <QueryResultHandler queryResult={towingOrderResponse} loading unauthorized>
      {data => {
        if (data && data.towingOrder.__typename === 'GetTowingOrderSuccess') {
          return <EditTowingOrderForm towingOrder={data.towingOrder.towingOrder} scrollTo={scrollTo} />
        }
      }}
    </QueryResultHandler>
  )
}
