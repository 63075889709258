import React from 'react'
import styled from 'styled-components'

import { AutoliittoSeparatorLine } from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { Icon } from '../icons/Icon'
import { Heading2, RouteRow } from '../layout'
import { CollapseProps } from './types'

interface CollapseHeaderProps extends CollapseProps {
  label: string
  position: 'top' | 'middle' | 'bottom'
  removeBottomLine?: boolean
}

export const CollapseHeader: React.FunctionComponent<CollapseHeaderProps> = ({
  label,
  open,
  position,
  toggle,
  removeBottomLine,
}) => {
  return (
    <div onClick={() => toggle()}>
      <AutoliittoSeparatorLine
        marginTop={position === 'top' ? '1rem' : '0'}
        marginBottom="1rem"
        marginMobile={position === 'top' ? '1rem 0 0.25rem 0' : '0 0 0.25rem 0'}
      />
      {/* {position === 'top' && (
        <AutoliittoSeparatorLine marginTop={position === 'top' ? '1rem' : '0'} marginBottom="1rem" />
      )} */}
      {/* {position === 'bottom' && <AutoliittoSeparatorLine marginTop="0" marginBottom="1rem" />} */}
      <RouteRow mobileColumns="3fr 1fr" marginMobile="0">
        <CollapseHeading color={open ? 'grey600' : 'grey300'}>{label}</CollapseHeading>
        <Icon
          icon={open ? 'minus' : 'plus'}
          size="small"
          justify="flex-end"
          align="center"
          color={open ? 'grey600' : 'grey300'}
        />
      </RouteRow>
      {!removeBottomLine && (
        <>
          {position === 'top' && open && (
            <AutoliittoSeparatorLine marginTop="1rem" marginBottom={'0'} marginMobile="0.25rem 0 0 0" />
          )}
          {position === 'bottom' && (
            <AutoliittoSeparatorLine marginTop="1rem" marginBottom={'0'} marginMobile="0.25rem 0 0 0" />
          )}
        </>
      )}
    </div>
  )
}

const CollapseHeading = styled(Heading2)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 1.2rem;
  }
`
