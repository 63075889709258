import { cloneDeep } from 'lodash'

import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import { FinishRouteMutationVariables } from './mutation'
import { EditFinishRouteFormValues } from '../types'
import { FinishRoute, FinishRouteMutationInput } from './types'
import { TowingRecordServiceType } from '../../../../../../../common/types'
import Maybe from 'graphql/tsutils/Maybe'
import { BatteryService } from '../../../../../../../components/ALSubForm/types'

export const toMutationVariables = (inputValues: EditFinishRouteFormValues): FinishRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  if (values.automobileAndTouringClubFinland) {
    return {
      input: {
        id: values.id,
        finishRoute: {
          finishRoute: routeToMutationVariables(values.finishRoute),
          additionalInfo: values.additionalInfo,
          serviceType: values.serviceType,
          repairShopId: values.automobileAndTouringClubFinland.repairShopId,
          componentCode: values.automobileAndTouringClubFinland.arcCodes.component.code,
          descriptionCode: values.automobileAndTouringClubFinland.arcCodes.description.code,
          completionCode: resolveALCompletionARC(values.serviceType),
          vehicleMileage: values.automobileAndTouringClubFinland.vehicleMileage,
          batteryService:
            values.serviceType === 'battery_service'
              ? batteryServiceToMutationVariables(values.automobileAndTouringClubFinland.batteryService)
              : null,
        },
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
        towingReason: values.towingReason,
      },
    }
  }

  return {
    input: {
      id: values.id,
      finishRoute: {
        finishRoute: routeToMutationVariables(values.finishRoute),
        additionalInfo: values.additionalInfo,
        serviceType: values.serviceType,
        repairShopId: null,
        componentCode: null,
        descriptionCode: null,
        completionCode: null,
        vehicleMileage: null,
        batteryService: null,
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
      towingReason: values.towingReason,
    },
  }
}

const routeToMutationVariables = (route: FinishRoute): FinishRouteMutationInput => {
  return {
    id: route.id,
    ordering: 100,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    date: route.date && route.time ? toScalarShortDate(route.date) : null,
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    time: route.time ? toScalarTime(route.time) : null,
    type: route.type,
    geolocated: route.geolocated,
  }
}

const resolveALCompletionARC = (serviceType: TowingRecordServiceType | null) => {
  switch (serviceType) {
    case 'phone_service':
      return '12'
    case 'battery_service':
      return '68'
    case 'road_service':
      return '96'
    case 'towing':
      return '6G'
    default:
      throw new Error(`Unrecognized service type for AL job '${serviceType}'`)
  }
}

const batteryServiceToMutationVariables = (batteryService: Maybe<BatteryService>) => {
  if (!batteryService) {
    return null
  }

  return {
    deliveredBattery: batteryService.deliveredBattery,
    paymentType: batteryService.paymentType,
    battery: batteryService.battery,
  }
}
