import styled, { css } from 'styled-components'

export const RoutesMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 1rem 0;
  }
  margin: 2rem 0;
`

export const RoutesMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  cursor: pointer;
`

interface RoutesMenuLabelProps {
  color?: string
}

export const RoutesMenuLabel = styled.div<RoutesMenuLabelProps>`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.green)};
  font-weight: 700;
`

interface RouteRowProps {
  columns?: string
  disabled?: boolean
  gridGap?: string
  mobileGridGap?: string
  mobileColumns?: string
  marginMobile?: string
}

export const RouteRow = styled.div<RouteRowProps>`
  display: grid;
  grid-gap: ${props => (props.gridGap ? props.gridGap : '1.5rem')};
  align-items: start;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  grid-template-columns: ${props => (props.columns ? props.columns : '2fr 1fr')};
  :disabled: ${props => (props.disabled ? 'true' : 'false')};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: ${props => (props.mobileColumns ? props.mobileColumns : 'minmax(120px, 1fr)')};
    grid-gap: ${props => (props.mobileGridGap ? props.mobileGridGap : '1.5rem')};
    ${props =>
      props.marginMobile &&
      css`
        margin: ${props.marginMobile};
      `}
  }
`

export const EvenRouteRow = styled(RouteRow)`
  grid-template-columns: 1fr 1fr;
`

export const RouteWrapper = styled.div`
  padding: 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0.5rem;
  }
`

export const RouteColumn = styled.div`
  padding: 0;
`

interface MiniButtonProps {
  selected?: boolean
}

export const MiniButtonRow = styled.div`
  display: flex;
  flex-directon: row;
`

export const MiniButton = styled.button<MiniButtonProps>`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: border-box;
border-radius: ${props => props.theme.radius.large};
cursor: pointer;
:not(:last-child) {
  margin: 0 1rem 0 0;
 }
padding: 0 1rem;
font-size: 1rem;
color: ${props => props.theme.colors.white}
background-color: ${props => (props.selected ? props.theme.colors.green : props.theme.colors.black)};
text-align: center;
height: 40px;
`
