import { TowingRecordRouteType, Time } from '../../../../common/types'
import { TowingRecordRoute } from './types'
import { format } from 'date-fns'

export function createNewRoute(routeType: TowingRecordRouteType, initialDate: boolean = true): TowingRecordRoute {
  const now = new Date()
  return {
    id: null,
    type: routeType,
    address: '',
    city: '',
    zipcode: '',
    description: '',
    coords: {
      lat: null,
      long: null,
    },
    date: initialDate ? now : null,
    time: initialDate ? ((format(now, 'HH:mm') as unknown) as Time) : null,
    ordering: 0,
    displayTimestampOnPrintables: false,
    geolocated: false,
  }
}
