import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Maybe, Coords, Time } from '../../../../../../common/types'
import { Button, ButtonCategory } from '../../../../../../components/Button'
import GetGeolocation from '../../../../../../components/Geolocation'
import { /* ComboInputWrapper, */ RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { TextInput, TimeInput } from '../../../../../../components/layout/form/input'
// import { CityInput } from '../../../../../../components/layout/form/input/CityInput'
import { LocationInputMap, toMapData } from '../../../../../../components/layout/form/input/LocationInputMap'
// import { ReverseGeocodeButton } from '../../../../../../components/ReverseGeocodeButton'
import { CustomButton, StationButtonGroup } from '../../../../../../components/StationButtonGroup'
import { StateStore } from '../../../../../../containers/StoreProvider'
import { GeoLocateAddressData } from '../../types'
import { ClearButton } from '../FinishRoute/FinishRoute'
import { RouteHeader } from '../RouteHeader'
import { ArrivalRouteArgs, ArrivalAddress, EditStartRouteFormValues } from '../StartRoute/types'
import { AutoliittoSeparatorLine } from '../styled'
import { useApolloClient } from 'react-apollo'
import { setErrorNotification } from '../../../../../../components/notification'
import { GeolocationType, Geolocation } from '../../../../../../components/Geolocation/types'
import { DateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { RouteNotes } from '../../RouteNotes'
// import { ArrivalRoute } from '../ArrivalRoute/types'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface StartRouteProps {
  onCancel: () => void
  regNo: string
  isAutoliitto: boolean
  isSos: boolean
  setAutoliittoDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate?: () => void
  showCustomAddressInitially: boolean
  initialValues: EditStartRouteFormValues
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  setLoading: (loading: boolean) => void
  reportPosition: (location: Geolocation, name: string) => void
  getValue: any
  setValue: (field: string, value: any) => void
  stationId: Maybe<number>
}

export const StartRoute: React.FunctionComponent<StartRouteProps> = ({
  onCancel,
  isAutoliitto,
  regNo,
  setAutoliittoDateField,
  setRouteLocation,
  showCustomAddressInitially,
  initialValues,
  clear,
  setGeolocatedField,
  setLoading,
  isSos,
  reportPosition,
  getValue,
  setValue,
  stationId,
}) => {
  const { state } = useContext(StateStore)
  const [calculateEta, setCalculateEta] = useState<boolean>(false)
  const [showEta] = useState<boolean>(() => {
    if (isSos && initialValues.routeDone) {
      return false
    }
    return true
  })

  const selectedBtnCategory: ButtonCategory = 'yellow'
  const unSelectedBtnCategory: ButtonCategory = 'new'

  const startRoute = initialValues && initialValues.startRoute ? initialValues.startRoute : null
  const routeAddress =
    startRoute && startRoute.address && startRoute.city ? `${startRoute.address}, ${startRoute.city}` : ''

  // const arrivalRoute = initialValues && initialValues.arrivalRoute ? initialValues.arrivalRoute : null

  const showStations = state.settings && state.settings.stations && state.settings.stations.length > 0
  const rowColumns = showStations === true ? '1fr 1fr 1fr' : '1fr 1fr'

  let selection = showStations && !showCustomAddressInitially ? '' : 'manual'
  let selectedStationId = null

  if (state.settings && state.settings.stations) {
    state.settings.stations.forEach(station => {
      if (`${station.address}, ${station.city}` === routeAddress) {
        selection = 'station'
        selectedStationId = station.id
      }
    })
  }

  const selectOption = (selectedOption: string) => {
    setCleared(false)
    setSelectedOption(selectedOption)
  }

  if (isSos) {
    selection = 'station'
  }

  const [showCustomAddress, setShowCustomAddress] = useState<boolean>(showCustomAddressInitially)
  const [selectedOption, setSelectedOption] = useState<string>(selection)
  const [selectedStation, setStation] = useState<number | null>(selectedStationId)
  const [cleared, setCleared] = useState<boolean>(false)
  const stations = state.settings ? state.settings.stations : null
  // const arrivalRouteQueryVariables = arrivalRouteToQueryVariable(arrivalRoute)
  const client = useApolloClient()

  return (
    <RouteWrapper>
      <RouteHeader label="Lähtöpaikka" regNo={regNo} cancel={onCancel} />
      {!isSos && (
        <>
          <RouteRow columns={rowColumns}>
            {showStations && (
              <RouteColumn>
                <CustomButton
                  label="Asemapaikka"
                  category={
                    selectedOption && selectedOption === 'station' ? selectedBtnCategory : unSelectedBtnCategory
                  }
                  onClick={() => {
                    selectOption('station')
                    setGeolocatedField(false)
                  }}
                  size="ml"
                  maxWidth="100%"
                />
              </RouteColumn>
            )}
            <RouteColumn>
              <CustomButton
                label="Muu"
                category={selectedOption && selectedOption === 'manual' ? selectedBtnCategory : unSelectedBtnCategory}
                onClick={() => {
                  setShowCustomAddress(true)
                  selectOption('manual')
                  setGeolocatedField(false)
                }}
                size="ml"
                maxWidth="100%"
              />
            </RouteColumn>
          </RouteRow>
        </>
      )}
      <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
      <RouteRow>
        <RouteColumn>
          {showCustomAddress && selectedOption !== 'station' && (
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`startRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`startRoute.city`}
                coordsName={`startRoute.coords`}
                zipcodeName={`startRoute.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`startRoute.address`),
                  getValue(`startRoute.city`),
                  getValue(`startRoute.coords`),
                  getValue(`startRoute.zipcode`)
                )}
                setValue={setValue}
                setRouteLocation={setRouteLocation}
                stationId={stationId}
              />
            </RouteColumn>
          )}
          {selectedOption === 'station' && (
            <StationButtonGroup
              stations={stations}
              label="Asemapaikka"
              setRouteLocation={setRouteLocation}
              stationId={selectedStation}
              setStation={stationId => {
                setStation(stationId)
              }}
              cleared={cleared}
              setLoading={setLoading}
              required={isSos}
            />
          )}
        </RouteColumn>
        <RouteColumn>
          <DateTimeInput
            label="Aika"
            name="startRoute"
            required={isAutoliitto}
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setAutoliittoDateField('startRoute.date')
              setValue('startRoute.time', value)
            }}
            showCurrentTimeBtn
          />
          <ClearButton
            label="Tyhjennä"
            category="cancel"
            onClick={() => {
              clear()
              setStation(null)
              setCleared(true)
            }}
            fontWeight="normal"
            maxWidth="100%"
            size="s"
          />
        </RouteColumn>
      </RouteRow>

      {isAutoliitto && (
        <>
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />

          <RouteRow>
            <RouteColumn>
              <TextInput disabled label="Kohdeosoite" name={`arrivalRoute.address`} />
            </RouteColumn>

            <RouteColumn>
              <TimeInput
                label="Arvioitu saapumisaika"
                name="automobileAndTouringClubFinland.estimatedTimeOfArrival.time"
                isAutoliitto={isAutoliitto}
                arrivalRoute={arrivalRouteToQueryVariable({
                  address: getValue(`arrivalRoute.address`) as string,
                  city: getValue(`arrivalRoute.city`) as string,
                  coords: getValue(`arrivalRoute.coords`) as Coords,
                })}
                calculateEta={calculateEta}
                setCalculateEta={setCalculateEta}
                required={isAutoliitto}
                customOnChange={() => {
                  setAutoliittoDateField('automobileAndTouringClubFinland.estimatedTimeOfArrival.date')
                }}
              />
            </RouteColumn>
          </RouteRow>
          <RouteRow>
            <RouteColumn />
            <RouteColumn>
              <Button
                label="Laske saapumisaika"
                category="new"
                loading={calculateEta}
                onClick={() => {
                  setCalculateEta(true)
                }}
                fontWeight="normal"
                maxWidth="100%"
              />
            </RouteColumn>
          </RouteRow>
        </>
      )}

      {isSos && (
        <>
          <GetGeolocation
            client={client}
            setError={setErrorNotification}
            type={GeolocationType.reverseGeocode}
            reportGeolocation={reportPosition}
            fieldName={'sosServiceOrder.currentLocation'}
          />
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          <RouteRow>
            <RouteColumn>
              <LocationInputMap
                addressLabel={'Yksikön sijainti'}
                addressName={`sosServiceOrder.currentLocation.address`}
                cityLabel={`Kaupunki`}
                cityName={`sosServiceOrder.currentLocation.city`}
                coordsName={`sosServiceOrder.currentLocation.coords`}
                zipcodeName={`sosServiceOrder.currentLocation.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`sosServiceOrder.currentLocation.address`),
                  getValue(`sosServiceOrder.currentLocation.city`),
                  getValue(`sosServiceOrder.currentLocation.coords`),
                  getValue(`sosServiceOrder.currentLocation.zipcode`)
                )}
                setValue={setValue}
                stationId={stationId}
              />
            </RouteColumn>
            <RouteColumn>
              <DateTimeInput
                label="Aloitusaika"
                name="sosServiceOrder.jobStarted"
                required={isSos}
                customOnChange={() => {
                  const value = (format(new Date(), 'HH:mm') as unknown) as Time
                  setAutoliittoDateField('sosServiceOrder.jobStarted.date')
                  setValue('sosServiceOrder.jobStarted.time', value)
                }}
                showCurrentTimeBtn
              />
            </RouteColumn>
          </RouteRow>
          <RouteRow>
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Kohdeosoite`}
                addressName={`arrivalRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`arrivalRoute.city`}
                coordsName={`arrivalRoute.coords`}
                zipcodeName={`arrivalRoute.zipcode`}
                descriptionName={`arrivalRoute.description`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`arrivalRoute.address`),
                  getValue(`arrivalRoute.city`),
                  getValue(`arrivalRoute.coords`),
                  getValue(`arrivalRoute.zipcode`)
                )}
                setValue={setValue}
                stationId={stationId}
              />
            </RouteColumn>
            <RouteColumn>
              {showEta && (
                <>
                  <TimeInput
                    label="Arvioitu saapumisaika"
                    name="sosServiceOrder.estimatedTimeOfArrival.time"
                    isAutoliitto={isSos}
                    arrivalRoute={arrivalRouteToQueryVariable({
                      address: getValue(`arrivalRoute.address`) as string,
                      city: getValue(`arrivalRoute.city`) as string,
                      coords: getValue(`arrivalRoute.coords`) as Coords,
                    })}
                    calculateEta={calculateEta}
                    setCalculateEta={setCalculateEta}
                    required={isSos}
                    customOnChange={() => {
                      setAutoliittoDateField('sosServiceOrder.estimatedTimeOfArrival.date')
                    }}
                  />
                  <EtaButton
                    label="Laske saapumisaika"
                    category="new"
                    loading={calculateEta}
                    onClick={() => {
                      setCalculateEta(true)
                    }}
                    fontWeight="normal"
                    maxWidth="100%"
                  />
                </>
              )}
            </RouteColumn>
          </RouteRow>
        </>
      )}
      <RouteNotes />
    </RouteWrapper>
  )
}

const EtaButton = styled(Button)`
  margin-top: 1rem;
`

export function arrivalRouteToQueryVariable(arrivalRoute: Maybe<ArrivalAddress>): ArrivalRouteArgs {
  if (!arrivalRoute) {
    return {
      address: '',
      city: '',
      lat: null,
      long: null,
    }
  }

  const coords = arrivalRoute.coords ? arrivalRoute.coords : { lat: null, long: null }

  return {
    address: arrivalRoute.address,
    city: arrivalRoute.city ? arrivalRoute.city : '',
    lat: coords.lat,
    long: coords.long,
  }
}
