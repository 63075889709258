import { cloneDeep } from 'lodash'

import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import { EndRouteMutationVariables } from './mutation'
import { EditEndRouteFormValues } from '../types'
import { EndRoute, EndRouteMutationInput } from './types'

export const toMutationVariables = (inputValues: EditEndRouteFormValues): EndRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  return {
    input: {
      id: values.id,
      endRoute: {
        endRoute: routeToMutationVariables(values.endRoute),
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

const routeToMutationVariables = (route: EndRoute): EndRouteMutationInput => {
  return {
    id: route.id,
    ordering: 102,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    date: route.date && route.time ? toScalarShortDate(route.date) : null,
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    time: route.time ? toScalarTime(route.time) : null,
    type: route.type,
    geolocated: route.geolocated,
  }
}
