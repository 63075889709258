import React from 'react'
import styled from 'styled-components'
import { Maybe, Time, TowingRecordRouteType, User} from '../../../../common/types'
import { Icon, IconType } from '../../../../components/icons/Icon'
import { RoutesMenuContainer, RoutesMenuItem, RoutesMenuLabel } from '../../../../components/layout'
import { Styling } from '../../../../components/Styling'
import { RouteMenuConfig } from './createRouteMenuConfig'
import { EditRouteSubforms } from './types'
import { TowingRecord } from '../../ViewTowingRecord/types'

const IconContainer = styled.div`
  margin-bottom: 0.5rem;
`

interface RoutesMenuProps {
  onClick: (id: EditRouteSubforms, done: boolean) => void
  routes: RouteMenuConfig[]
  towingRecord: TowingRecord
  user: Maybe<User>
  canEditRoutes: boolean
}

export const RoutesMenu: React.FunctionComponent<RoutesMenuProps> = ({
  onClick,
  routes,
  towingRecord,
  canEditRoutes,
}) => {
  // const isSos = towingRecord.sosServiceOrder ? true : false
  let disableFinishRoute = false
  // let disableAll = false

  if (towingRecord.sosServiceOrder) {
    disableFinishRoute = shouldDisableFinishRoute(towingRecord.sosServiceOrder.deliverable as string)
  }

  // if (user) {
  //   disableAll = shouldDisableAll(user, towingRecord)
  // }

  console.log('RoutesMenu', routes)

  return (
    <RoutesMenuContainer>
      {routes.map(route => (
        <RouteGroup
          key={route.routeId}
          label={route.label}
          routeId={route.routeId}
          onClick={onClick}
          time={route.time}
          done={route.done}
          disableFinishRoute={disableFinishRoute}
          disabled={!canEditRoutes}
          timeOfCompletion={route.timeOfCompletion}
        />
      ))}
    </RoutesMenuContainer>
  )
}

interface RouteGroupProps {
  onClick: (id: EditRouteSubforms, done: boolean) => void
  routeId: TowingRecordRouteType
  label: string
  time: Maybe<Time>
  timeOfCompletion: Maybe<Time>
  done: boolean
  disableFinishRoute: boolean
  disabled: boolean
}

const RouteGroup: React.FunctionComponent<RouteGroupProps> = ({
  routeId,
  label,
  onClick,
  time,
  done,
  disableFinishRoute,
  disabled,
  timeOfCompletion,
}) => {
  let iconType: IconType = 'map-marker'
  let color = done ? 'grey400' : 'green800'
  let onRouteMenuClick = () => onClick(routeId, done)
  switch (routeId) {
    case 'start':
      iconType = 'circle-arrow-right'
      break
    case 'arrival':
      iconType = 'map-marker'
      break
    case 'finish':
      iconType = 'thumbs-up'
      if (disableFinishRoute) {
        color = 'grey400'
        onRouteMenuClick = () => {}
      }
      break
    case 'end':
      iconType = 'done'
      break
  }

  if (disabled) {
    color = 'red400'
    onRouteMenuClick = () => {}
  }

  return (
    <RoutesMenuItem onClick={onRouteMenuClick}>
      <IconContainer>
        <Icon size="large" color={color} icon={iconType} />
      </IconContainer>
      <RoutesMenuLabel color={color}>{label}</RoutesMenuLabel>
      <Styling bold fontSize="m" color={color} textAlign="center">
        {done && time}
        {done && timeOfCompletion ? ` - ${timeOfCompletion}` : ''}
      </Styling>
    </RoutesMenuItem>
  )
}

export function shouldDisableFinishRoute(deliverable: string) {
  if (deliverable === 'Towage' || deliverable === 'Transport') {
    return false
  }

  return true
}

