import gql from 'graphql-tag'

export const ADD_TOWING_ORDER_MUTATION = gql`
  mutation EdiTowingOrder($input: EditTowingOrderInput!) {
    editTowingOrder(input: $input) {
      ... on AddTowingRecordSuccess {
        towingRecord {
          id
        }
      }
      ... on EditTowingOrderSuccess {
        towingOrder {
          id
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
        }
      }
    }
  }
`

export const CONVERT_TO_SOS_ORDER_MUTATION = gql`
  mutation ConvertToSosOrder($input: ConvertToSosOrderInput!) {
    convertToSosOrder(input: $input) {
      ... on ConvertToSosOrderSuccess {
        towingOrder {
          id
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
    }
  }
`
