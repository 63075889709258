import React, { useState, useEffect } from 'react'
import { LockMessage, LockedForName, TowingRecordRouteType } from '../../../common/types'
import { Page } from '../../../components/layout'
import { dismissNotifications } from '../../../components/notification'
import { useHistoryPush } from '../../../components/router'
import { CancelOrEndTowingRecord } from '../components/CancelTowingRecord'
import { EndTowingRecord } from '../components/EndTowingRecord'
import { Routes } from '../components/routes'
import { EditRouteSubforms } from '../components/routes/types'
import { TowingRecord } from './types'
import { ViewTowingRecordContent } from './ViewTowingRecordContent'
import { maybeStartTrackingRecord } from '../../../components/TrackedJobs/util'
import VehicleSearchModal from '../../../components/VehicleSearchModal'
import { useApolloClient } from 'react-apollo'
import { getStoredCompanyId } from '../../../graphql-client/storedState'
import { useSocket } from '../../../util/useSocket'
import { WarningBanner } from '../../../components/TowingCommon/WarningBanner'

interface Props {
  queryResult: TowingRecord
  refetchData?: () => void
  action?: string | undefined
  resetSubform?: TowingRecordRouteType
}

export const ViewTowingRecordPage: React.FunctionComponent<Props> = ({ queryResult, action, refetchData }) => {
  // const initialSubForm: EditRouteSubforms = action === 'start' ? TowingRecordRouteType.start : 'view-towing-record'
  const initialSubForm: EditRouteSubforms = action && ['start', 'arrival', 'finish', 'end'].includes(action) ? action as TowingRecordRouteType : 'view-towing-record'
  const [subformId, setSubformId] = useState<EditRouteSubforms>(initialSubForm)
  const [routeDone, setRouteDone] = useState<boolean>(false)
  const [towingRecord, setTowingRecord] = useState<TowingRecord>(queryResult)
  const [isLockedFor, setIsLockedFor] = useState<LockedForName>(queryResult.locked)
  const [sybFormType, setsybFormType] = useState<'end'|'cancel'>('end')
  const historyPush = useHistoryPush()
  const client = useApolloClient()

  const scrollTop = () => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }
  const selectSubform = (id: EditRouteSubforms, done?: boolean) => {
    dismissNotifications()
    if (typeof done === 'boolean') {
      setRouteDone(done)
    }
    setSubformId(id)
    scrollTop()
  }

  const setTowingRecordState = (tr: TowingRecord, view?: EditRouteSubforms) => {
    if(!!isLockedFor){
      historyPush(`/towing-record/${towingRecord.id}`)
      return;
    }
    setTowingRecord(tr)
    if (view) {
      selectSubform(view)
    }
  }
  const setNextForm = (id: EditRouteSubforms, type: 'end'|'cancel') => {
  
    setsybFormType(type)

    setSubformId(id)
    scrollTop()
  }

  const handleVehicleInfoLinkClick = () => {
    if (towingRecord.vehicleInfoCheck && towingRecord.vehicleInfoCheck.id) {
      selectSubform('view-vehicle-info')
    } else {
      historyPush(`/towing-record/${towingRecord.id}/edit/atj`)
    }
  }

  const showViewTowingRecord = subformId === 'view-towing-record'
  const showEditRoutes = subformId && ['start', 'arrival', 'finish', 'end'].includes(subformId)
  const showEndForm = subformId === 'end-towing-record'
  const showCancelOrEndForm = subformId === 'cancel-or-end-towing-record'
  const showVehicleInfo = subformId === 'view-vehicle-info'

  useEffect(() => {
    maybeStartTrackingRecord(towingRecord)
    const callback = ()=>{
      historyPush(`/towing-record/${towingRecord.id}`)
    }
    document.addEventListener(`record_${towingRecord.id}:changed`, callback);
    return ()=>{
      document.removeEventListener(`record_${towingRecord.id}:changed`, callback)
    }
  }, [towingRecord, subformId, historyPush])

  const socket = useSocket();
  
  useEffect(()=>{
    const companyId = getStoredCompanyId();
    if(!companyId){
      return;
    }
    
    const callback = (event:string) => {
        const data: LockMessage = JSON.parse(event);
        setIsLockedFor(data.userName ? data.userName : null)
    }

    socket.on(`company-${companyId}.towing_record-${queryResult.id}.locked`, callback);
    socket.on(`company-${companyId}.towing_record-${queryResult.id}.unlocked`, callback);
    return ()=>{
      console.log('removing event listeners from towing order');
      socket.removeEventListener(`company-${companyId}.towing_record-${queryResult.id}.locked`, callback);
      socket.removeEventListener(`company-${companyId}.towing_record-${queryResult.id}.unlocked`, callback);
    }
  },[socket, queryResult, setIsLockedFor]);

  console.log('towingRecord', towingRecord)

  return (
    <Page noPadding>
      {towingRecord.jobStatus !== 'cancelled' && towingRecord.sosServiceOrder && towingRecord.sosServiceOrder.orderStatus === 'cancelled_by_sos' 
        ? (<WarningBanner
            title='Tilaus peruttu'
            message='Tilaus on peruttu. Lopeta työ.'
            ></WarningBanner>) 
        : null}
      {!!isLockedFor && !showViewTowingRecord && (
        <WarningBanner title={'Tilaus lukittu'} message={`${isLockedFor} on avannut työn muokattavaksi. Tallentamattomat muutokset voivat hävitä ellei niitä tallenna heti.`}></WarningBanner>
        )}
      {!!isLockedFor && showViewTowingRecord && (
        <WarningBanner title={'Tilaus lukittu'} message={`${isLockedFor} on avannut työn muokattavaksi.`}></WarningBanner>
        )}
      {showViewTowingRecord && (
        <ViewTowingRecordContent
          towingRecord={towingRecord}
          onEditAction={() => historyPush(`/towing-record/${towingRecord.id}/edit`)}
          onSelectSubform={(id, done) => selectSubform(id, done)}
          onVehicleInfoLinkClick={handleVehicleInfoLinkClick}
          lockedFor={isLockedFor}
        />
      )}
      {showEditRoutes && (
        <Routes
          towingRecord={towingRecord}
          onCancel={() => setSubformId('view-towing-record')}
          subformId={subformId}
          routeDone={routeDone}
          onRefetch={refetchData}
          setTowingRecord={setTowingRecordState}
        />
      )}

      {showEndForm && (
        <EndTowingRecord
          towingRecord={towingRecord}
          onCancel={() => setSubformId(`view-towing-record`)}
          //onSelectCancelForm={() => setSubformId('cancel-towing-record')}
          //setTowingRecord={setTowingRecordState}
          setNextForm={setNextForm}
        />
      )}
      {/* EndTowingRecord stays ruffly the same but the CancelTowingRecord form will become a form for both end and cancel 
       - Need to pass EndTowingRecord a function that gets the subformId and cancel/end - parameter
       - Need to pass CancelTowingRecord a function that gets the subformId (EndTowingRecord) and resets cancel/end - parameter to null??
       - Queries from EndTowingRecord need to be transfered to CancelTowingRecord so it can perform both operations 
       */}

      {showCancelOrEndForm && (
        <CancelOrEndTowingRecord 
          subFormType={sybFormType} 
          towingRecord={towingRecord} 
          setTowingRecord={setTowingRecordState}
          onCancel={() => setSubformId('end-towing-record')} 
          />
      )}

      {showVehicleInfo && !isLockedFor && (
        <VehicleSearchModal
          vehicleRegistration={towingRecord.vehicleDetails.registrationNumber}
          vehicleInfoCheckId={
            towingRecord.vehicleInfoCheck && towingRecord.vehicleInfoCheck.id ? towingRecord.vehicleInfoCheck.id : null
          }
          close={() => setSubformId('view-towing-record')}
          client={client}
        />
      )}
    </Page>
  )
}


