import React, { useState, useContext } from 'react'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { GeoLocateAddressData } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { StateStore } from '../../../../../../containers/StoreProvider'
import { Button, ButtonCategory } from '../../../../../../components/Button'
import { AutoliittoSeparatorLine } from '../styled'
import { StationButtonGroup } from '../../../../../../components/StationButtonGroup'
import { ClearButton } from '../FinishRoute/FinishRoute'
import { LocationInputMap, toMapData } from '../../../../../../components/layout/form/input/LocationInputMap'
import { DateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Coords, Maybe, Time } from '../../../../../../common/types'
import { RouteNotes } from '../../RouteNotes'
import { useEtaCalculation } from '../../../../../../util/useEtaCalculation'
import { arrivalRouteToQueryVariable } from '../StartRoute/StartRoute'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface EndRouteProps {
  onCancel: () => void
  regNo: string
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate?: () => void
  showCustomAddressInitially: boolean
  initialValues?: any
  isAutoliitto?: boolean
  isSos?: boolean
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  setLoading: (loading: boolean) => void
  getValue: any
  setValue: (field: string, value: any) => void
  disableEtaBtn?: boolean
  stationId: Maybe<number>
}

export const EndRoute: React.FunctionComponent<EndRouteProps> = ({
  onCancel,
  regNo,
  setRouteLocation,
  showCustomAddressInitially,
  initialValues,
  isAutoliitto,
  clear,
  setGeolocatedField,
  setLoading,
  getValue,
  setValue,
  isSos,
  disableEtaBtn,
  stationId,
}) => {
  const [calculateEta, setCalculateEta] = useState<boolean>(false)
  const etaData = useEtaCalculation(
    {
      enableHighAccuracy: true,
      calculate: calculateEta,
      destination: arrivalRouteToQueryVariable({
        address: getValue(`endRoute.address`) as string,
        city: getValue(`endRoute.city`) as string,
        coords: getValue(`endRoute.coords`) as Coords,
      }),
    },
    eta => {
      const date = new Date(eta)

      const time = (format(new Date(eta), 'HH:mm') as unknown) as Time

      console.log('Calucated', { date, time })

      if (date) {
        setValue('endRoute.date', date)
      }

      if (time) {
        setValue('endRoute.time', time)
      }

      setCalculateEta(false)
    }
  )
  console.log('etaData', etaData)
  const { state } = useContext(StateStore)
  const selectedBtnCategory: ButtonCategory = 'yellow'
  const unSelectedBtnCategory: ButtonCategory = 'new'

  const endRoute = initialValues && initialValues.endRoute ? initialValues.endRoute : null
  const routeAddress = endRoute && endRoute.address && endRoute.city ? `${endRoute.address}, ${endRoute.city}` : ''

  const showStations = state.settings && state.settings.stations && state.settings.stations.length > 0
  const rowColumns = showStations === true ? '1fr 1fr 1fr' : '1fr 1fr'

  let selection = showStations && !showCustomAddressInitially ? '' : 'manual'
  let selectedStationId = null

  if (state.settings && state.settings.stations) {
    state.settings.stations.forEach(station => {
      if (`${station.address}, ${station.city}` === routeAddress) {
        selection = 'station'
        selectedStationId = station.id
      }
    })
  }

  const selectOption = (selectedOption: string) => {
    setCleared(false)
    setSelectedOption(selectedOption)
  }

  if (isSos) {
    selection = 'station'
  }

  const [showCustomAddress, setShowCustomAddress] = useState<boolean>(showCustomAddressInitially)
  const [selectedOption, setSelectedOption] = useState<string>(selection)
  const [selectedStation, setStation] = useState<number | null>(selectedStationId)
  const [cleared, setCleared] = useState<boolean>(false)

  return (
    <RouteWrapper>
      <RouteHeader label="Lopetuspaikka" regNo={regNo} cancel={onCancel} />
      {!isSos && (
        <>
          <RouteRow columns={rowColumns}>
            {showStations && (
              <RouteColumn>
                <Button
                  label="Asemapaikka"
                  category={
                    selectedOption && selectedOption === 'station' ? selectedBtnCategory : unSelectedBtnCategory
                  }
                  onClick={() => {
                    selectOption('station')
                    setGeolocatedField(false)
                  }}
                  size="ml"
                  maxWidth="100%"
                />
              </RouteColumn>
            )}
            <RouteColumn>
              <Button
                label="Muu"
                category={selectedOption && selectedOption === 'manual' ? selectedBtnCategory : unSelectedBtnCategory}
                onClick={() => {
                  setShowCustomAddress(true)
                  selectOption('manual')
                  setGeolocatedField(false)
                }}
                size="ml"
                maxWidth="100%"
              />
            </RouteColumn>
          </RouteRow>
        </>
      )}
      <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
      <RouteRow>
        <RouteColumn>
          {showCustomAddress && selectedOption !== 'station' && (
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`endRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`endRoute.city`}
                coordsName={`endRoute.coords`}
                zipcodeName={`endRoute.zipcode`}
                getValue={getValue}
                values={toMapData(
                  getValue(`endRoute.address`),
                  getValue(`endRoute.city`),
                  getValue(`endRoute.coords`),
                  getValue(`endRoute.zipcode`)
                )}
                setValue={setValue}
                setRouteLocation={setRouteLocation}
                stationId={stationId}
              />
            </RouteColumn>
          )}
          {selectedOption === 'station' && (
            <StationButtonGroup
              stations={state.settings ? state.settings.stations : null}
              label="Asemapaikka"
              setRouteLocation={setRouteLocation}
              stationId={selectedStation}
              setStation={stationId => {
                setStation(stationId)
              }}
              cleared={cleared}
              setLoading={setLoading}
              required={isSos}
            />
          )}
        </RouteColumn>
        <RouteColumn>
          <DateTimeInput
            label="Aika"
            name="endRoute"
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setValue(`endRoute.date`, new Date())
              setValue(`endRoute.time`, value)
            }}
            required={isAutoliitto || isSos}
            showCurrentTimeBtn
          />
          <ClearButton
            label="Tyhjennä"
            category="cancel"
            onClick={() => {
              clear()
              setStation(null)
              setCleared(true)
            }}
            fontWeight="normal"
            maxWidth="100%"
            size="s"
          />
          <ClearButton
            label="Laske saapumisaika"
            category="new"
            loading={calculateEta}
            onClick={() => {
              setCalculateEta(true)
            }}
            fontWeight="normal"
            maxWidth="100%"
            size="s"
            disabled={disableEtaBtn}
          />
        </RouteColumn>
      </RouteRow>
      <RouteNotes />
    </RouteWrapper>
  )
}
