import styled, { css } from 'styled-components'

import { FormPadder } from '../../../../../components/layout'
import { FormikForm as Form } from '../../../../../components/layout/form/style'

export const RouteRow = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  align-items: start;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  grid-template-columns: 2fr 1fr;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: minmax(120px, 1fr);
  }
`

export const RouteColumn = styled.div`
  padding: 0;
`

interface AutoliittoSeparatorLineProps {
  marginTop?: string
  marginBottom?: string
  marginMobile?: string
}

export const AutoliittoSeparatorLine = styled.hr<AutoliittoSeparatorLineProps>`
  margin-top: ${props => (props.marginTop ? props.marginTop : '5rem')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '2rem')};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    ${props =>
      props.marginMobile &&
      css`
        margin: ${props.marginMobile};
      `}
  }
`

export const RouteForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface ButtonContainerProps {
  floatAtBottom?: boolean
  floatAtBottomNoMenu?: boolean
}

export const ButtonContainer = styled(FormPadder)<ButtonContainerProps>`
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  display: flex;
  padding: 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0.5rem;
  }
  ${props =>
    props.floatAtBottom &&
    css`
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 0.5rem 1rem 5.5rem 1rem;
      @media (max-width: ${props => props.theme.screenSize.mobile}) {
        padding: 0.5rem 0.5rem 3.5rem 0.5rem;
      }
    `}
  ${props =>
    props.floatAtBottomNoMenu &&
    css`
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 1rem;
      @media (max-width: ${props => props.theme.screenSize.mobile}) {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      }
    `}
`
