import React from 'react'
import styled, { css } from 'styled-components'
import { JobItemDiscountType, Maybe } from '../../common/types'
import {
  calculateNetSum,
  calculateVat,
  calculateDiscountNetAmount,
  calculateDiscountGrossAmount,
  anyHasUndefinedPrice,
} from './priceCalculations'
import { JobItemInput } from './types'
import { Currency } from '../Currency'

interface Props {
  jobItems: JobItemInput[]
  itemsIncludeVat: boolean
  discountItem: Maybe<JobItemInput>
}

export const getItemsTotal = ({ itemsIncludeVat, jobItems, discountItem }: Props) => {
  if (!jobItems) return null
  const netAmountAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
  accumulator + calculateNetSum(item, vatIncluded)
  const vatAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
  accumulator + calculateVat(item, vatIncluded)
  const calculateNetAmount = netAmountAccumulator(itemsIncludeVat)
  const calculateVatAmount = vatAccumulator(itemsIncludeVat)

  const netAmount = jobItems.reduce(calculateNetAmount, 0)
  const vatAmount = jobItems.reduce(calculateVatAmount, 0)
  const grossAmount = netAmount + vatAmount
  const discountItemGrossAmount = !discountItem
    ? 0
    : discountItem.discountType === JobItemDiscountType.percentage
    ? (grossAmount * Math.abs(discountItem.unitPrice)) / 100
    : Math.abs(discountItem.unitPrice)
  
  return grossAmount - discountItemGrossAmount;
}

export const Totals: React.FC<Props> = ({ itemsIncludeVat, jobItems, discountItem }) => {
  if (!jobItems) return null

  const netAmountAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
    accumulator + calculateNetSum(item, vatIncluded)
  const vatAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
    accumulator + calculateVat(item, vatIncluded)
  const discountNetAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
    accumulator + calculateDiscountNetAmount(item, vatIncluded)
  const discountGrossAccumulator = (vatIncluded: boolean) => (accumulator: number, item: JobItemInput) =>
    accumulator + calculateDiscountGrossAmount(item, vatIncluded)

  const calculateNetAmount = netAmountAccumulator(itemsIncludeVat)
  const calculateVatAmount = vatAccumulator(itemsIncludeVat)
  const calculateDiscountNet = discountNetAccumulator(itemsIncludeVat)
  const calculateDiscountGross = discountGrossAccumulator(itemsIncludeVat)

  const netAmount = jobItems.reduce(calculateNetAmount, 0)
  const vatAmount = jobItems.reduce(calculateVatAmount, 0)
  const grossAmount = netAmount + vatAmount

  const discountNetAmount = jobItems.reduce(calculateDiscountNet, 0)
  const discountGrossAmount = jobItems.reduce(calculateDiscountGross, 0)

  const discountItemNetAmount = !discountItem
    ? 0
    : discountItem.discountType === JobItemDiscountType.percentage
    ? (netAmount * Math.abs(discountItem.unitPrice)) / 100
    : Math.abs(discountItem.unitPrice) / (1 + discountItem.vat / 100)

  const discountItemGrossAmount = !discountItem
    ? 0
    : discountItem.discountType === JobItemDiscountType.percentage
    ? (grossAmount * Math.abs(discountItem.unitPrice)) / 100
    : Math.abs(discountItem.unitPrice)

  const discountItemVatAmount = discountItemGrossAmount - discountItemNetAmount

  const discountNetTotal = -(discountNetAmount + discountItemNetAmount)
  const discountGrossTotal = -(discountGrossAmount + discountItemGrossAmount)
  const netAmountWithDiscountItem = netAmount - discountItemNetAmount
  const grossAmountWithDiscountItem = grossAmount - discountItemGrossAmount
  const vatAmountWithDiscountItem = vatAmount - discountItemVatAmount

  return (
    <TotalsLayout>
      {discountNetTotal !== 0 && (
        <>
          <Description>Alennus (netto/brutto)</Description>
          <Sum>
            <Currency>{discountNetTotal}</Currency>
            {' / '}
            <Currency>{discountGrossTotal}</Currency>
          </Sum>
        </>
      )}

      <Description>Veroton summa</Description>
      <Sum>{anyHasUndefinedPrice(jobItems) ? 'N/A' : <Currency>{netAmountWithDiscountItem}</Currency>}</Sum>

      <Description>ALV</Description>
      <Sum>{anyHasUndefinedPrice(jobItems) ? 'N/A' : <Currency>{vatAmountWithDiscountItem}</Currency>}</Sum>

      <Description bold>Yhteensä</Description>
      <Sum bold>{anyHasUndefinedPrice(jobItems) ? 'N/A' : <Currency>{grossAmountWithDiscountItem}</Currency>}</Sum>
    </TotalsLayout>
  )
}

const TotalsLayout = styled.div`
  padding: 0;
  margin: 0 0 1rem 0;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 5fr minmax(200px, 1fr);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`

interface DescriptionProps {
  bold?: boolean
}

const Description = styled.div<DescriptionProps>`
  text-align: right;
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 0.75rem 0 0.25rem 0;
    font-weight: 600;
  }
`

interface SumProps {
  bold?: boolean
}

const Sum = styled.div<SumProps>`
  text-align: right;
  font-weight: ${props => props.bold && props.theme.fontWeight.bold};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-weight: ${props => props.bold && props.theme.fontWeight.normal};
  }
`
