import gql from 'graphql-tag'
import { UnauthorizedError } from '../../../../../../errorTypes'
import { towingRecordFragment } from '../../../../../ViewTowingRecord/TowingRecordQuery'
import { TowingRecord } from '../../../../../ViewTowingRecord/types'
import { ArrivalRouteInputMutation } from './types'

export interface ArrivalRouteMutationVariables
  extends Readonly<{
    input: ArrivalRouteInputMutation
  }> {}

export interface EditArrivalRouteResponse {
  editArrivalRoute: ArrivalRouteOutput
}

type ArrivalRouteOutput = ArrivalRouteSuccess | UnauthorizedError

interface ArrivalRouteSuccess
  extends Readonly<{
    __typename: 'EditTowingRecordSuccess'
    towingRecord: TowingRecord
  }> {}

export const EDIT_ARRIVAL_ROUTE_MUTATION = gql`
  mutation EditArrivalRoute($input: EditArrivalRouteInput!) {
    editArrivalRoute(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on EditTowingRecordSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`
