import { Field } from 'formik'
import React, { ReactNode, useCallback } from 'react'
import { SelectField } from '../../field'

interface Props {
  name: string
  label?: string
  onChange?: (arg?: string | number) => void
  options: object[]
  required?: boolean
  clearable?: boolean
  searchable?: boolean
  placeholder?: string
  children?: ReactNode
  disabled?: boolean
  unsetAfterSelect?: boolean
}

export const SelectInput: React.FC<Props> = ({
  clearable,
  name,
  label,
  onChange,
  options,
  placeholder,
  required,
  searchable,
  disabled = false,
  unsetAfterSelect = false
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      disabled={disabled}
      clearable={clearable}
      component={SelectField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      options={options}
      onChange={onChange}
      searchable={searchable}
      placeholder={placeholder}
      unsetAfterSelect={unsetAfterSelect}
    />
  )
}
