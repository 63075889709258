// input towingRecord.routes
// output { routeId: 'start', time: Maybe<Time> }

import { Maybe, Time, TowingRecordRouteType, TowingRecordServiceType, TowingRecordType } from '../../../../common/types'
import { TowingRecordRoute } from './types'
import { getIndexOfRoute } from './getIndexOfRoute'

export interface RouteMenuConfig {
  routeId: TowingRecordRouteType
  label: string
  time: Maybe<Time>
  timeOfCompletion: Maybe<Time>
  done: boolean
}

export function createRouteMenuConfig(
  routes: TowingRecordRoute[],
  finishRouteAddressRequired: boolean,
  towingRecordType: TowingRecordType
): RouteMenuConfig[] {
  console.log('routes', routes)
  const getRouteTime = (t: TowingRecordRouteType) =>
    getIndexOfRoute(routes, t) > -1 ? routes[getIndexOfRoute(routes, t)].time : null

  const hasRoute = (t: TowingRecordRouteType) => {
    const routeIndex = getIndexOfRoute(routes, t)
    if (routeIndex === -1) {
      return false
    }

    const route = routes[routeIndex]

    if (!finishRouteAddressRequired && route.type === TowingRecordRouteType.finish) return Boolean(route.time)

    return Boolean(route.time && route.address)
  }

  return [
    {
      routeId: TowingRecordRouteType.start,
      label: 'Lähtöpaikka',
      time: getRouteTime(TowingRecordRouteType.start),
      done: hasRoute(TowingRecordRouteType.start),
      timeOfCompletion: null,
    },
    {
      routeId: TowingRecordRouteType.arrival,
      label: towingRecordType === TowingRecordType.sos ? 'Kohde' : 'Kohteessa',
      time: getRouteTime(TowingRecordRouteType.arrival),
      done: hasRoute(TowingRecordRouteType.arrival),
      timeOfCompletion:
        towingRecordType === TowingRecordType.sos
          ? getRouteTime(TowingRecordRouteType.finish_breakdown_location)
          : null,
    },
    {
      routeId: TowingRecordRouteType.finish,
      label: towingRecordType === TowingRecordType.sos ? 'Määränpää' : 'Työ valmis',
      time: getRouteTime(TowingRecordRouteType.finish),
      done: hasRoute(TowingRecordRouteType.finish),
      timeOfCompletion:
        towingRecordType === TowingRecordType.sos ? getRouteTime(TowingRecordRouteType.finish_destination) : null,
    },
    {
      routeId: TowingRecordRouteType.end,
      label: 'Lopetuspaikka',
      time: getRouteTime(TowingRecordRouteType.end),
      done: hasRoute(TowingRecordRouteType.end),
      timeOfCompletion: null,
    },
  ]
}

export function finishRouteAddressRequired(serviceType: TowingRecordServiceType, towingRecordType: TowingRecordType) {
  if (serviceType !== TowingRecordServiceType.TOWING && towingRecordType === TowingRecordType.autoliitto) return false
  return true
}
