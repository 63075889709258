import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Maybe, Time, TowingRecordJobStatus } from '../../../common/types'
import { ClearButton } from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/FinishRoute'
import { Button } from '../../Button'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { CollapseHeader } from '../../Collapse/CollapseHeader'
import { CollapseProps } from '../../Collapse/types'
import { RouteColumn, RouteRow, Row } from '../../layout'
import { DateTimeInput } from '../../layout/form/input/DateTimeInput'
import { LocationInputMap, toMapData } from '../../layout/form/input/LocationInputMap'
import { finishedAtBreakdownLocationEditableJobStatuses } from '../ArrivalRouteForm'
import { CustomButton } from '../FinishRouteForm/ArrivalForm'
import { ArrivalRouteAction, ArrivalRouteProps } from './types'

interface ArrivedAtBreakdownLocationProps extends CollapseProps {
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
}

export const ArrivedAtBreakdownLocation: React.FunctionComponent<
  ArrivedAtBreakdownLocationProps & ArrivalRouteProps
> = ({
  open,
  setDateField,
  setRouteLocation,
  setValue,
  getValue,
  clear,
  toggle,
  action,
  setAction,
  jobStatus,
  stationId,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-arrived')

  useEffect(() => {
    if (action === 'edit-arrived') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])
  return (
    <>
      <CollapseHeader label="SAAPUNUT KOHTEESEEN" open={open} position="top" toggle={toggle} />
      <>
        {open && (
          <CollapseContent>
            <RouteRow disabled={!edit}>
              <RouteColumn>
                <LocationInputMap
                  addressLabel={`Osoite`}
                  addressName={`arrivalRoute.address`}
                  cityLabel={`Kaupunki`}
                  cityName={`arrivalRoute.city`}
                  coordsName={`arrivalRoute.coords`}
                  zipcodeName={`arrivalRoute.zipcode`}
                  required={true}
                  coordsRequired={true}
                  getValue={getValue}
                  values={toMapData(
                    getValue(`arrivalRoute.address`),
                    getValue(`arrivalRoute.city`),
                    getValue(`arrivalRoute.coords`),
                    getValue(`arrivalRoute.zipcode`)
                  )}
                  setValue={setValue}
                  disabled={!edit}
                  setRouteLocation={setRouteLocation}
                  stationId={stationId}
                />
              </RouteColumn>

              <RouteColumn>
                <DateTimeInput
                  label="Saapumisaika"
                  name={`arrivalRoute`}
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setDateField('arrivalRoute.date')
                    setValue('arrivalRoute.time', value)
                  }}
                  required={true}
                  disabled={!edit}
                  showCurrentTimeBtn
                />
                {edit && (
                  <ClearButton
                    label="Tyhjennä"
                    category="cancel"
                    onClick={() => {
                      clear()
                    }}
                    fontWeight="normal"
                    maxWidth="100%"
                    size="s"
                    disabled={!edit}
                  />
                )}
              </RouteColumn>
            </RouteRow>
            {!edit && (action === 'edit-finished' || action === 'default') && (
              <RouteRow>
                <RouteColumn>
                  <Button
                    label="Muokkaa"
                    category="editSecondary"
                    size="s"
                    onClick={() => setAction('edit-arrived')}
                    mobileWidth="100%"
                  />
                </RouteColumn>
              </RouteRow>
            )}
            {edit && arrivedAtBreakdownLocationEditableJobStatuses.includes(jobStatus) && (
              <Row alignItems="start" justify="start" direction="row" marginBottom="double" marginMobile="0 0 1rem 0">
                <CustomButton
                  label="Peruuta"
                  category="editSecondary"
                  size="s"
                  onClick={() => {
                    if (finishedAtBreakdownLocationEditableJobStatuses.includes(jobStatus)) {
                      setAction('default')
                    } else {
                      setAction('edit-finished')
                    }
                  }}
                />
                <Button label="Tallenna" category="save" size="s" submit />
              </Row>
            )}
          </CollapseContent>
        )}
      </>
    </>
  )
}

export const arrivedAtBreakdownLocationEditableJobStatuses = [
  TowingRecordJobStatus.arrived_at_breakdown_location,
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_breakdown_location,
  TowingRecordJobStatus.finished_at_destination,
]
