import { FinnishBusinessIds } from 'finnish-business-ids'
import ssn from 'finnish-ssn'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-apollo'
import styled, { css } from 'styled-components'

import { CustomerType } from '../../common/types'
import { InfoSearchModal } from '../InfoSearch'
import { SelectInput } from '../layout/form/input'
import { CenteredLoader } from '../responses/Loading'
import { setErrorNotifications, setFatalErrorNotification } from '../responses/setErrorNotifications'
import { CREDIT_INFO_QUERY, CreditInfoResponse } from './queries'
import { CustomerInfo, InfoSearch, SearchType } from './types'

interface SearchCustomerInfoProps {
  customerType: CustomerType
  searchString?: string | null
  handleResults: (creditInfo: CustomerInfo) => void
  handleInfoSearchResults?: (infoSearch: InfoSearch) => void
  disabled?: boolean
  infoSearchModalButtonLabel?: string
}

export const CustomSelectInput = styled(SelectInput)`
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grey500};
      background-color: ${props.theme.colors.grey300};
      border-color: ${props.theme.colors.grey300};
      font-weight: ${props.theme.fontWeight.normal};
    `}
`

export const SearchCustomerInfo: React.FunctionComponent<SearchCustomerInfoProps> = ({
  searchString = null,
  handleResults,
  handleInfoSearchResults,
  disabled,
  customerType,
  infoSearchModalButtonLabel,
}) => {
  const [activeSearch, setActiveSearch] = useState<boolean>(false)
  const [isVisibleInfoSearchModal, setIsVisibleInfoSearchModal] = useState<boolean>(false)

  const validSsnOrBid =
    customerType === CustomerType.person
      ? ssn.validate(searchString ? searchString.toUpperCase() : null)
      : FinnishBusinessIds.isValidBusinessId(searchString || '')

  const validSearchString = searchString && validSsnOrBid

  const [queryCreditInfo, { loading }] = useMutation<CreditInfoResponse>(CREDIT_INFO_QUERY, {
    onCompleted({ creditInfoSearch }) {
      if (creditInfoSearch.__typename === 'CreditInfoSearchSuccess') {
        handleResults(creditInfoSearch.creditInfo)
      } else {
        setErrorNotifications({ data: creditInfoSearch })
      }

      setActiveSearch(false)
    },
    onError(err) {
      setActiveSearch(false)
      setFatalErrorNotification(err.message)
    },
  })

  const onSearch = useCallback(
    (searchType: SearchType) => {
      console.log({ validSearchString, searchType, customerType })
      if (!validSearchString || !searchType) {
        return
      }

      if (searchType) {
        switch (searchType) {
          case SearchType.credit_info:
            setActiveSearch(true)
            queryCreditInfo({
              variables: { input: { customerIdentifier: { type: 'CUSTOMER_IDENTIFIER', value: searchString } } },
            })
            break
          case SearchType.info_search:
            setIsVisibleInfoSearchModal(true)
            break
        }
      }
    },
    [searchString, queryCreditInfo, validSearchString, customerType]
  )

  const onSaveInfoSearchModal = (infoSearchResult: InfoSearch) => {
    if (handleInfoSearchResults) {
      handleInfoSearchResults(infoSearchResult)
      setIsVisibleInfoSearchModal(false)
    }
    return
  }

  console.log('disabled, validStr, activeSearch', disabled, validSearchString, activeSearch)
  // @TODO: Implement credit info search for companies
  // For now just hide credit_info option
  return (
    <>
      <CenteredLoader loading={loading} />
      <CustomSelectInput
        name=""
        disabled={(disabled || !validSearchString || activeSearch) as boolean}
        searchable={false}
        placeholder={'Hae'}
        options={
          customerType === 'person'
            ? [
                { value: SearchType.info_search, label: 'Osoite- ja maksuhäiriöt' },
                { value: SearchType.credit_info, label: 'Rahoituspäätös' },
              ]
            : [{ value: SearchType.info_search, label: 'Osoite- ja maksuhäiriöt' }]
        }
        onChange={(val) => {
          if (val) {
            onSearch(val as SearchType)
          }
        }}
      />
      {isVisibleInfoSearchModal && (
        <InfoSearchModal
          customerIdentifier={searchString || ''}
          customerType={customerType}
          close={() => setIsVisibleInfoSearchModal(false)}
          onSave={onSaveInfoSearchModal}
          saveButtonLabel={infoSearchModalButtonLabel && infoSearchModalButtonLabel}
        />
      )}
    </>
  )
}
