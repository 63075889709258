import posed from 'react-pose'
import styled from 'styled-components'

export const List = styled.div`
  display: grid;
  grid-template-columns: 4fr 120px 4fr 1fr;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 1fr auto 1fr;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
  }
`

const AnimatedExpand = posed.div({
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      y: {
        duration: 200,
        ease: 'easeIn',
      },
      opacity: { ease: 'linear', duration: 100 },
    },
  },
})

export const ExpandedList = styled(AnimatedExpand)`
  display: grid;
  padding: 0.5rem 0 0 0;
  grid-template-columns: 4fr 120px 4fr 1fr;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: 9fr 1fr;
    grid-auto-flow: row;
    grid-row-gap: 0.5rem;
  }
`

interface ListColumnProps {
  justify?: string
}

interface ListRowWrapperProps{
  isCanceled?: boolean 
}

export const ListColumn = styled.div<ListColumnProps>`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  padding: 0;
`

export const ListRowWrapper = styled.div<ListRowWrapperProps>`
  cursor: pointer;
  padding: 1rem 1.5rem;
  border-top: 1px solid ${props => props.theme.colors.grey300};
  border-bottom: 1px solid ${props => props.theme.colors.grey300};
  background-color: ${props => props.isCanceled ? props.theme.colors.red50 : props.theme.colors.white};
`

// change to status to enum type green, yellow, red
interface ListStatusProps {
  status: string
}

export const ListStatus = styled.div<ListStatusProps>`
  color: ${props =>
    (props.status === 'unfinished' && props.theme.colors.orange600) ||
    (props.status === 'accepted' && props.theme.colors.orange600) ||
    (props.status === 'assigned' && props.theme.colors.orange600) ||
    (props.status === 'finished' && props.theme.colors.green)};
  font-weight: ${props => props.theme.fontWeight.bold};
  //text-transform: uppercase;
  letter-spacing: 1px;
`
