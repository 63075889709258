import ApolloClient from 'apollo-client'
import gql from 'graphql-tag'
import { BadRequestError, UnauthorizedError } from '../../containers/errorTypes'

import { RelatedTowingOrder, RelatedTowingRecord, VehicleInfo } from './types'

export const SEARCH_VEHICLE_RELATED_TOWINGORDERS_QUERY = gql`
  query towingOrder($id: Int!) {
    towingOrder(id: $id) {
      __typename
      ... on GetTowingOrderSuccess {
        towingOrder {
          id
          status
        }
      }
    }
  }
`

export const SEARCH_VEHICLE_RELATED_TOWINGRECORDS_QUERY = gql`
  query towingRecord($id: Int!) {
    towingRecord(id: $id) {
      __typename
      ... on GetTowingRecordSuccess {
        towingRecord {
          id
          status
          label
          jobDetails {
            towingDate
          }
          vehicleDetails {
            owner
          }
        }
      }
    }
  }
`

export const vehicleInfoFragment = {
  success: gql`
    fragment VehicleInfoFields on VehicleInfo {
      id
      searchMeta {
        dataSource
        searchStatus
        trafiSearchPerformer
        trafiSearchDate
        daysSinceTrafiSearch
        relatedTowingOrders
        relatedTowingRecords
      }
      vehicle {
        serialNumber
        registrationNumber
        vehicleClass
        brand
        model
        modelYear
        makeAndModel
        color
        fuel
        transmission
        driveTrain
        displacement
        length
        width
        height
        weight
        holderName
        holderAddress
        remarks
        maxNetEffect
        maxNetWeight
        maxTechNetWeight
        initializationDate
        lastInspectionDate
        lastInspectionResult
        lastInspectionResultDescription
        nextInspectionDate
      }
      insurance {
        insuredParty
        insuranceCompany
        insuranceStartDate
        insuranceEndDate
      }
      ownersHolders {
        holdershipType
        ownershipType
        ownershipTypeDescription
        ownershipStartDate
        ownershipEndDate
        customer {
          identifier
          name
          address
          zipcode
          city
          type
        }
      }
      restrictions {
        type
        subType
        description
        seriousnessLevel
        seriousnessDescription
        startDate
        endDate
        handlerName
      }
    }
  `,
}

export const SEARCH_VEHICLE_QUERY = gql`
  query vehicleInfoSearch($vehicleRegistrationNumber: String!, $refreshDataFromTrafi: Boolean) {
    vehicleInfoSearch(
      vehicleRegistrationNumber: $vehicleRegistrationNumber
      refreshDataFromTrafi: $refreshDataFromTrafi
    ) {
      __typename
      ... on VehicleInfoSuccess {
        vehicleInfo {
          ...VehicleInfoFields
        }
      }
      ... on BadRequestError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
  ${vehicleInfoFragment.success}
`

export const GET_VEHICLE_QUERY = gql`
  query vehicleInfo($vehicleInfoId: Int!) {
    vehicleInfo(vehicleInfoId: $vehicleInfoId) {
      __typename
      ... on VehicleInfoSuccess {
        vehicleInfo {
          ...VehicleInfoFields
        }
      }
      ... on BadRequestError {
        error {
          message
        }
      }
      ... on UnauthorizedError {
        error {
          message
        }
      }
    }
  }
  ${vehicleInfoFragment.success}
`

const expectTypename = (actualTypename: string, expectedTypename: string) => {
  if (actualTypename !== expectedTypename) {
    throw new Error(`Failed to query vehicle record. Expected typename '${expectedTypename}', got '${actualTypename}'`)
  }
}

const queryRelatedTowingRecords = (client: ApolloClient<any>, ids: [number]): Promise<RelatedTowingRecord[]> =>
  Promise.all(
    ids.map(
      async (id: number): Promise<RelatedTowingRecord> => {
        const { data } = await client.query({
          query: SEARCH_VEHICLE_RELATED_TOWINGRECORDS_QUERY,
          variables: { id },
        })

        expectTypename(data.towingRecord.__typename, 'GetTowingRecordSuccess')

        return data.towingRecord.towingRecord
      }
    )
  )

const queryRelatedTowingOrders = (client: ApolloClient<any>, ids: [number]): Promise<RelatedTowingOrder[]> =>
  Promise.all(
    ids.map(
      async (id: number): Promise<RelatedTowingOrder> => {
        const { data } = await client.query({
          query: SEARCH_VEHICLE_RELATED_TOWINGORDERS_QUERY,
          variables: { id },
        })

        expectTypename(data.towingOrder.__typename, 'GetTowingOrderSuccess')

        return data.towingOrder.towingOrder
      }
    )
  )


export interface VehicleInfoResponse {
  __typename: string
  vehicleInfo: VehicleInfo 
}

export type VehicleInfoError = BadRequestError | UnauthorizedError

export const queryVehicleInfo = async (
  client: ApolloClient<any>,
  vehicleRegistration: string,
  reFetch: boolean
): Promise<VehicleInfoResponse | VehicleInfoError> => {
  const { data } = await client.query({
    query: SEARCH_VEHICLE_QUERY,
    variables: { vehicleRegistrationNumber: vehicleRegistration, refreshDataFromTrafi: reFetch },
    fetchPolicy: 'network-only',
  })
  const result = data.vehicleInfoSearch

  if(isVehicleInfoError(result)){
    return result
  }
  //const vehicleInfo = data.vehicleInfoSearch.vehicleInfo
  //expectTypename(data.vehicleInfoSearch.__typename, 'VehicleInfoSuccess')

  if (result.vehicleInfo && result.vehicleInfo.searchMeta) {
    result.vehicleInfo.searchMeta.relatedTowingRecords = await queryRelatedTowingRecords(
      client,
      result.vehicleInfo.searchMeta.relatedTowingRecords
    )

    result.vehicleInfo.searchMeta.relatedTowingOrders = await queryRelatedTowingOrders(
      client,
      result.vehicleInfo.searchMeta.relatedTowingOrders
    )
  }

  return result
}

export const getVehicleInfo = async (client: ApolloClient<any>, vehicleInfoId: number): Promise<VehicleInfoResponse | VehicleInfoError> => {
  const { data } = await client.query({
    query: GET_VEHICLE_QUERY,
    variables: { vehicleInfoId },
    fetchPolicy: 'network-only',
  })

  const result = data.vehicleInfo

  if(isVehicleInfoError(result)){
    return result
  }

  //expectTypename(result.vehicleInfo.__typename, 'VehicleInfoSuccess')

  if (result.vehicleInfo && result.vehicleInfo.searchMeta) {
    result.vehicleInfo.searchMeta.relatedTowingRecords = await queryRelatedTowingRecords(
      client,
      result.vehicleInfo.searchMeta.relatedTowingRecords
    )

    result.vehicleInfo.searchMeta.relatedTowingOrders = await queryRelatedTowingOrders(
      client,
      result.vehicleInfo.searchMeta.relatedTowingOrders
    )
  }

  return result
}

export function isVehicleInfoError(x: any): x is VehicleInfoError{
  return !(x.__typename === 'VehicleInfoSuccess')
}
