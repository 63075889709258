import styled from 'styled-components'

interface ModalContainerProps {
  backgroundColor?: string
}
interface ModalWindowProps {
  border?: string | null
}

export const ModalContainer = styled.div<ModalContainerProps>`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: (${props => (props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.3)')});
`

export const ModalWindow = styled.div<ModalWindowProps>`
  background-color: ${props => props.theme.colors.white};
  margin: 15% auto;
  border-radius: ${props => props.theme.radius.medium};
  border: ${props => (props.border ? props.border : 'none')};
  border-color: ${props => props.theme.colors.grey200};
  max-width: 500px;
  width: 80%;
`

export const ModalText = styled.div`
  margin: 1rem 0 1rem 0;
`

export const ModalHeader = styled.div`
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  background-color: ${props => props.theme.colors.grey200};
  border-radius: ${props => props.theme.radius.medium} ${props => props.theme.radius.medium} 0 0;
`

export const ModalWindowContent = styled.div`
  padding: 1rem;
`
