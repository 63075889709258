import styled, { css } from 'styled-components'

interface SectionProps {
  justify?: string
  gap?: string
  columns?: number
  columnCss?: string
  tabletColumnCss?: string
  marginBottom?: string
  margin?: string
  marginMobile?: string
  mobileGap?: string
  width?: string
  mobileColumns?: string
  height?: string
}

interface SectionTextColumnProps {
  width?: string
  widthTablet?: string
  widthMobile?: string
}

export const Section = styled.section<SectionProps>`
  display: grid;
  grid-gap: ${props => (props.gap ? props.gap : '1.5rem')};
  margin: 0;
  padding: 0;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1.5rem')};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  align-items: end;
  height: ${props => (props.height ? props.height : '100%')};
  justify-items: center;

  ${props =>
    props.justify &&
    props.justify === 'text' &&
    css`
      align-items: start;
      justify-items: start;
    `}

  ${props =>
    props.columns === 2
      ? 'grid-template-columns: repeat(2, minmax(200px, 1fr))'
      : 'grid-template-columns: repeat(3, minmax(200px, 1fr))'};
      ${props =>
        props.columns === 1 &&
        css`
          grid-template-columns: repeat(1, minmax(200px, 1fr));
        `}
${props =>
  props.columnCss &&
  css`
    grid-template-columns: ${props.columnCss};
  `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    grid-template-columns: repeat(
      ${props => (props.columns === 3 ? 3 : 2)},
      minmax(${props => (props.columns === 3 ? '120px' : '200px')}, 1fr)
    );
    ${props =>
      props.tabletColumnCss &&
      css`
        grid-template-columns: ${props.tabletColumnCss};
      `}
      ${props =>
        props.columns === 1 &&
        css`
          grid-template-columns: repeat(1, minmax(200px, 1fr));
        `}
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    ${props =>
      props.marginMobile &&
      css`
        margin: ${props.marginMobile};
      `}
      ${props =>
        props.mobileGap &&
        css`
          grid-gap: ${props.mobileGap};
        `}
        ${props =>
          props.mobileColumns &&
          css`
            grid-template-columns: ${props.mobileColumns};
          `}
  }
`

interface SectionColumnProps {
  alignItems?: string
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  flexDirection?: string
}

export const SectionColumn = styled.div<SectionColumnProps>`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'column-reverse')};
  width: 100%;
  flex-basis: 100%;
  flex: 1;
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    ${props =>
      props.hideOnTablet &&
      css`
        display: none;
      `}
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    ${props =>
      props.hideOnMobile &&
      css`
        display: none;
      `}
  }
`

export const SectionTextColumn = styled.div<SectionTextColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => (props.width ? props.width : 'auto')};
  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    ${props =>
      props.widthTablet &&
      css`
        width: ${props.widthTablet};
      `}
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    ${props =>
      props.widthMobile &&
      css`
        width: ${props.widthMobile};
      `}
  }
`

export const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.grey100};
  border-radius: ${props => props.theme.radius.small} ${props => props.theme.radius.small};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`

export const SectionBody = styled.div`
  width: 100%;
  padding: 0 0.5rem;
`
