import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { ACS } from '../../../../../../common/AccessControl'
import {
  Maybe,
  TowingRecordJobStatus,
  TowingRecordRouteType,
  TowingRecordType,
  TypeIdentifier,
} from '../../../../../../common/types'
import { Button } from '../../../../../../components/Button'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import {
  dismissNotifications,
  setErrorNotification,
  setSuccessNotification,
} from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { arrivedAtBreakdownLocationEditableJobStatuses } from '../../../../../../components/SosSubForm/ArrivalRoute/ArrivedAtBreakdownLocation'
import { ArrivalRouteAction } from '../../../../../../components/SosSubForm/ArrivalRoute/types'
import { finishedAtBreakdownLocationEditableJobStatuses } from '../../../../../../components/SosSubForm/ArrivalRouteForm'
import {
  UPDATE_ARRIVED_AT_BREAKDOWN_LOCATION_MUTATION,
  UPDATE_FINISHED_AT_BREAKDOWN_LOCATION_MUTATION,
} from '../../../../../../components/SosSubForm/mutation/mutation'
import {
  updateArrivedAtBreakdownLocationToMutationVariables,
  updateFinishedAtBreakdownLocationToMutationVariables,
} from '../../../../../../components/SosSubForm/mutation/toMutationVariables'
import {
  UpdateArrivedAtBreakdownLocationResponse,
  UpdateFinishedAtBreakdownLocationResponse,
} from '../../../../../../components/SosSubForm/mutation/types'
import { extractDate } from '../../../../../../util/dateConversion'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType, TowingRecord } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData } from '../../types'
import { getRouteDateTimeByType, validateRouteTimes } from '../../validateRouteTimes'
import { ButtonContainer, RouteForm } from '../styled'
import { ArrivalRoute } from './ArrivalRoute'
import { getInitialValues } from './getInitialValues'
import { EDIT_ARRIVAL_ROUTE_MUTATION, EditArrivalRouteResponse } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { EditArrivalRouteFormValues } from './types'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecord
  routeDone: boolean
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  routeDone,
  setTowingRecord,
}) => {
  // const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { currentUser },
  } = useContext(StateStore)
  const accessControl = new ACS(currentUser).setTowingRecord(towingRecord)
  const [done, setDone] = useState<boolean>(() => {
    if (towingRecord.type === TowingRecordType.sos) {
      return accessControl.canTowingRecord('editFinishedAtBreakdownLocation')
    }
    return routeDone
  })
  const [getCoords, setGetCoords] = useState<boolean>(false)
  const [action, setAction] = useState<ArrivalRouteAction>(() => {
    if (towingRecord.type === TowingRecordType.sos) {
      if (towingRecord.jobStatus === TowingRecordJobStatus.started) {
        return 'edit-arrived'
      }

      if (towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
        return 'edit-finished'
      }

      // return done ? 'edit-finished' : 'edit-arrived'
    }
    return 'default'
  })

  const [editTowingRecord, { loading: arrivalRouteLoading }] = useMutation<EditArrivalRouteResponse>(
    EDIT_ARRIVAL_ROUTE_MUTATION,
    {
      onCompleted(test) {
        const { editArrivalRoute } = test
        if (editArrivalRoute.__typename === 'EditTowingRecordSuccess') {
          setSuccessNotification(
            formatMessage(routeMessages.route_edit_notification_success_title),
            formatMessage(routeMessages.route_edit_notification_success_message)
          )
          if (!isSos && setTowingRecord) {
            // historyPush(`/towing-record/${towingRecord.id}`)
            setTowingRecord(editArrivalRoute.towingRecord, 'view-towing-record')
          }
        } else {
          setErrorNotifications({ data: editArrivalRoute })
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const [updateArrivedAtBreakdownLocation, { loading: updateArrivedLoading }] = useMutation<
    UpdateArrivedAtBreakdownLocationResponse
  >(UPDATE_ARRIVED_AT_BREAKDOWN_LOCATION_MUTATION, {
    onCompleted(response) {
      console.log('response', response)
      const { updateArrivedAtBreakdownLocation } = response
      if (updateArrivedAtBreakdownLocation.__typename === 'UpdateArrivedAtBreakdownLocationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateArrivedAtBreakdownLocation.towingRecord)
        }
        if (
          updateArrivedAtBreakdownLocation.towingRecord.jobStatus ===
          TowingRecordJobStatus.arrived_at_breakdown_location
        ) {
          setAction('edit-finished')
        } else {
          setAction('default')
        }
      } else {
        setErrorNotifications({ data: updateArrivedAtBreakdownLocation })
      }
    },
    onError(err) {
      console.error('err', err)
      setFatalErrorNotification(err.message)
    },
  })

  const [updateFinishedAtBreakdownLocation, { loading: updateFinishedLoading }] = useMutation<
    UpdateFinishedAtBreakdownLocationResponse
  >(UPDATE_FINISHED_AT_BREAKDOWN_LOCATION_MUTATION, {
    onCompleted(response) {
      console.log('updateFinishedAtBreakdownLocation', response)
      const { updateFinishedAtBreakdownLocation } = response
      if (updateFinishedAtBreakdownLocation.__typename === 'UpdateFinishedAtBreakdownLocationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        // historyPush(`/towing-record/${towingRecord.id}`)
        if (setTowingRecord) {
          setTowingRecord(updateFinishedAtBreakdownLocation.towingRecord, 'view-towing-record')
        }
      } else {
        setErrorNotifications({ data: updateFinishedAtBreakdownLocation })
      }
    },
    onError(err) {
      console.error('updateFinishedAtBreakdownLocation', err)
      setFatalErrorNotification(err.message)
    },
  })

  const initialValues = getInitialValues(towingRecord)
  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const isSos = towingRecord.type === TowingRecordType.sos
  const saveLabel = isSos ? (done ? 'Tallenna valmis' : 'Tallenna saapunut') : 'Tallenna kohteessa'

  async function submit(values: EditArrivalRouteFormValues) {
    setGetCoords(false)
    dismissNotifications()

    try {
      const routes = [getRouteDateTimeByType(TowingRecordRouteType.start, towingRecord.routes)]

      if (isSos && done && values.sosServiceOrder) {
        routes.push(getRouteDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes))
        if (values.sosServiceOrder.actualTimeOfCompletion) {
          validateRouteTimes(
            {
              date: values.sosServiceOrder.actualTimeOfCompletion.date as Date,
              time: values.sosServiceOrder.actualTimeOfCompletion.time,
              type: TowingRecordRouteType.finish_breakdown_location,
            },
            routes
          )
        }
      } else {
        validateRouteTimes(
          { date: values.arrivalRoute.date, time: values.arrivalRoute.time, type: values.arrivalRoute.type },
          routes
        )
      }
    } catch (e) {
      return setErrorNotification('Virhe', e.message)
    }

    if (isSos) {
      if (!done) {
        if (!accessControl.canTowingRecord('editArrivedAtBreakdownLocation')) {
          return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
        }
        if (values.sosServiceOrder) {
          const {
            currentLocation: { coords },
          } = values.sosServiceOrder

          const {
            coords: { lat, long },
          } = values.arrivalRoute
          console.log('coords', coords)

          if (!coords.lat || !coords.long) {
            if (lat && long) {
              values.sosServiceOrder.currentLocation.coords.lat = lat
              values.sosServiceOrder.currentLocation.coords.long = long
            } else {
              return setErrorNotification(
                'Virhe',
                'Kohdeosoitteen koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
              )
            }
          }
        }

        await editTowingRecord({ variables: toMutationVariables(values) })
        await updateArrivedAtBreakdownLocation({
          variables: updateArrivedAtBreakdownLocationToMutationVariables(values, towingRecord.id),
        })
      } else {
        if (!accessControl.canTowingRecord('editFinishedAtBreakdownLocation')) {
          return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
        }
        await updateFinishedAtBreakdownLocation({
          variables: updateFinishedAtBreakdownLocationToMutationVariables(values, towingRecord.id),
        })
      }
    } else {
      await editTowingRecord({ variables: toMutationVariables(values) })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: EditArrivalRouteFormValues) => {
        console.log('submit', values)
        submit(values)
        // if (ready === true && done && !isSos) {
        //   historyPush(`/towing-record/${towingRecord.id}`)
        // }
        // editTowingRecord({ variables: toMutationVariables(values) })
      }}
      render={({ setFieldValue, values }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            const location: Geolocation = locationData as Geolocation
            setLocation(location, `arrivalRoute`)
            // setValue(`arrivalRoute.address`, locationData.address)
            // setValue(`arrivalRoute.city`, locationData.city)
            // setValue(`arrivalRoute.coords.lat`, locationData.coords.lat)
            // setValue(`arrivalRoute.coords.long`, locationData.coords.long)

            if (isSos) {
              setLocation(location, `sosServiceOrder.currentLocation`)
              // setValue(`sosServiceOrder.currentLocation.address`, locationData.address)
              // setValue(`sosServiceOrder.currentLocation.city`, locationData.city)
              // setValue(`sosServiceOrder.currentLocation.coords.lat`, locationData.coords.lat)
              // setValue(`sosServiceOrder.currentLocation.coords.long`, locationData.coords.long)
            }
          }
        }

        const updateRouteDate = () => {
          setValue(`arrivalRoute.date`, new Date())
        }

        const setAutoliittoDateField = (fieldName: string) => {
          const date = extractDate(new Date())
          setValue(fieldName, date)
        }

        const setGeolocatedField = (geolocated: boolean) => {
          setValue('arrivalRoute.geolocated', geolocated)
        }

        const clear = () => {
          setLocation({ address: '', city: '', coords: { lat: null, long: null }, zipcode: '' }, `arrivalRoute`)
          setValue('arrivalRoute.time', '')
          setValue('arrivalRoute.date', null)
          setFieldValue('arrivalRoute.geolocated', false)

          if (isSos) {
            setLocation(
              { address: '', city: '', coords: { lat: null, long: null }, zipcode: '' },
              `sosServiceOrder.currentLocation`
            )
          }
        }

        const setLocation = (locationData: Geolocation, fieldName: string) => {
          setValue(`${fieldName}.address`, locationData.address)
          setValue(`${fieldName}.city`, locationData.city)
          setValue(`${fieldName}.coords.lat`, locationData.coords.lat)
          setValue(`${fieldName}.coords.long`, locationData.coords.long)
          setValue(`${fieldName}.zipcode`, locationData.zipcode)
        }

        const setRouteAction = (action: ArrivalRouteAction) => {
          console.log('setRouteAction', action)
          setAction(action)
          if (action === 'edit-arrived') {
            setDone(false)
          } else {
            setDone(true)
          }
        }

        return (
          <RouteForm>
            <ArrivalRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              isAutoliitto={isAutoliitto}
              setAutoliittoDateField={setAutoliittoDateField}
              setRouteLocation={setRouteLocation}
              updateRouteDate={() => updateRouteDate}
              clear={clear}
              setGeolocatedField={setGeolocatedField}
              initialValues={initialValues}
              isSos={isSos}
              routeDone={done}
              setLocation={setLocation}
              getCoordsFromAddress={getCoords}
              setValue={setValue}
              getValue={getValue}
              action={action}
              setAction={setRouteAction}
              jobStatus={towingRecord.jobStatus}
              stationId={towingRecord.stationId}
              towingRecordId={towingRecord.id}
            />

            <ButtonContainer floatAtBottom>
              <Loading loading={arrivalRouteLoading || updateArrivedLoading || updateFinishedLoading} />
              {!isSos && (
                <Button
                  category="save"
                  size="l"
                  label={saveLabel}
                  mobileLabel="Tallenna"
                  submit
                  maxWidth="100%"
                ></Button>
              )}
              {isSos &&
                action === 'edit-arrived' &&
                !arrivedAtBreakdownLocationEditableJobStatuses.includes(towingRecord.jobStatus) && (
                  <Button
                    category="save"
                    size="l"
                    label={saveLabel}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}
              {isSos &&
                action === 'edit-finished' &&
                !finishedAtBreakdownLocationEditableJobStatuses.includes(towingRecord.jobStatus) && (
                  <Button
                    category="save"
                    size="l"
                    label={saveLabel}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}

export const ArrivalRoutePage = injectIntl(RoutesIntl)
