import { Maybe, Time, TowingRecordRouteType } from '../../../../common/types'
import { TowingRecordRoute } from './types'

interface RouteDateTime {
  type: TowingRecordRouteType
  date: Maybe<Date>
  time: Maybe<Time | string>
}

interface ParsedDateTime {
  type: TowingRecordRouteType
  date: Date
}

export const validateRouteTimes = (route: RouteDateTime, routes: RouteDateTime[]) => {
  console.log('validateRouteTime')
  const parsedDateTime: ParsedDateTime = parseDateTime(route)
  const parsedRoutes: ParsedDateTime[] = routes.map(r => parseDateTime(r))

  parsedRoutes.forEach(route => {
    console.log('validateRouteTimes', {
      current: parsedDateTime.date,
      route: route.date,
      validate: parsedDateTime.date > route.date,
    })
    if (parsedDateTime.date < route.date) {
      throw Error(`${translateRouteType(route.type)} aikaleiman tulee olla ennen nykyistä reittipistettä`)
    }
  })
}

export const getRouteDateTimeByType = (type: TowingRecordRouteType, routes: TowingRecordRoute[]) => {
  const route = routes.find(route => route.type === type)
  const date = route && route.date ? route.date : new Date()
  const time = route ? route.time : ''
  return { date, time, type }
}

const parseDateTime = (route: RouteDateTime) => {
  const routeDate = route.date ? route.date.toDateString() : ''
  return { date: new Date(`${routeDate} ${route.time}`), type: route.type }
}

const translateRouteType = (type: TowingRecordRouteType) => {
  switch (type) {
    case TowingRecordRouteType.start:
      return 'Lähtöpaikan'
    case TowingRecordRouteType.arrival:
      return 'Kohteen'
    case TowingRecordRouteType.finish:
      return 'Työ valmis'
    case TowingRecordRouteType.finish_breakdown_location:
      return 'Valmis kohteessa'
    case TowingRecordRouteType.finish_destination:
      return 'Valmis määränpäässä'
  }
}
