import { format } from 'date-fns'
import { find } from 'lodash'

import { Maybe, Time, TowingRecordRouteType } from '../../../../../../common/types'
import { SosIncidentCause, SosServiceOrder, sosCauseFilters } from '../../../../../../components/SosSubForm/types'
import { createNewRoute } from '../../createNewRoute'
import { TowingRecordWithRoutes } from '../../types'
import { ArrivalRoute, EditArrivalRouteFormValues } from './types'

export function getInitialValues(towingRecord: TowingRecordWithRoutes): EditArrivalRouteFormValues {
  const routes = towingRecord.routes
  const routeFound = find(routes, ['type', TowingRecordRouteType.arrival])

  const route =
    routeFound && routeFound.type === TowingRecordRouteType.arrival
      ? ({ ...routeFound } as ArrivalRoute)
      : (createNewRoute(TowingRecordRouteType.arrival, true) as ArrivalRoute)

  if (!route.date) {
    route.date = new Date()
  }

  if (!route.time) {
    route.time = (format(new Date(), 'HH:mm') as unknown) as Time
  }

  let alValues = null
  let sosValues = null

  if (towingRecord.automobileAndTouringClubFinland) {
    const estimatedTimeOfCompletion =
      towingRecord.automobileAndTouringClubFinland.jobTimestamps.estimatedTimeOfCompletion

    alValues = {
      estimatedTimeOfCompletion: {
        date: estimatedTimeOfCompletion ? estimatedTimeOfCompletion.date : null,
        time: estimatedTimeOfCompletion ? estimatedTimeOfCompletion.time : null,
      },
    }
  }

  if (towingRecord.sosServiceOrder) {
    const completedAtBreakdownLocation = towingRecord.sosServiceOrder.jobTimestamps.completedAtBreakdownLocation
    const serviceOrder = towingRecord.sosServiceOrder

    sosValues = {
      actualTimeOfCompletion: {
        date: completedAtBreakdownLocation ? completedAtBreakdownLocation.date : new Date(),
        time: completedAtBreakdownLocation ? completedAtBreakdownLocation.time : null,
      },
      vehicleMileage: towingRecord.sosServiceOrder.vehicleMileage,
      vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
      additionalInfo: '',
      currentLocation: {
        coords: {
          lat: route && route.coords ? route.coords.lat : null,
          long: route && route.coords ? route.coords.long : null,
        },
        address: route && route.address ? route.address : '',
        city: route && route.city ? route.city : '',
        zipcode: route && route.zipcode ? route.zipcode : '',
      },
      cause: setCauseByDeliverable(serviceOrder),
      deliverable: serviceOrder.deliverable || serviceOrder.estimatedServiceType,
    }
  }

  return {
    arrivalRoute: route,
    id: towingRecord.id,
    automobileAndTouringClubFinland: alValues,
    sosServiceOrder: sosValues,
    notes: towingRecord.jobDetails.notes,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
  }
}
/**
 * Set the cause if the cause is consistent with sos tabel for serviceType-->serviceCause relation 
 * @param serviceOrder 
 */
const setCauseByDeliverable:(serviceOrder: SosServiceOrder) =>  Maybe<SosIncidentCause> = (serviceOrder) => {
  const cause = serviceOrder.cause || serviceOrder.estimatedCause
  const deliverable = serviceOrder.deliverable || serviceOrder.estimatedServiceType

  if (!deliverable) return null

  const relevantCauseList = deliverable && Object.keys(sosCauseFilters).includes(deliverable as string) ? sosCauseFilters[deliverable as string] : null;

  if (cause && relevantCauseList && relevantCauseList.includes(cause as string)) {
    return cause
  }

  return null
}
