import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  arcComponentOptions,
  arcDescriptionOptions,
  getBatteryServiceOptions,
  paymentTypeOptions,
} from '../../../../../../components/ALSubForm/options'
import { Button } from '../../../../../../components/Button'
import { EvenRouteRow, RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout'
import { NumberInput, SelectInput, TextAreaInput, TextInput } from '../../../../../../components/layout/form/input'
import { FormatMessageType, Maybe, Time, TowingRecordJobStatus } from '../../../../../../common/types'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { GeoLocateAddressData } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { AutoliittoSeparatorLine } from '../styled'
import { AutoliittoCoords, repairShopOptions, repairShops } from './options'
import { RepairShopModal } from './RepairShopModal'
import { LocationInputMap, toMapData } from '../../../../../../components/layout/form/input/LocationInputMap'
import { StateStore } from '../../../../../StoreProvider'
import { DateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { RouteNotes } from '../../RouteNotes'
import { FinishRouteAction } from '../../../../../../components/SosSubForm/FinishRoute/types'
import { SosFinishRoute } from '../../../../../../components/SosSubForm/FinishRoute'
import { Images } from '../../../../../../components/images'

interface FinishRouteProps {
  onCancel: () => void // should reset route values
  regNo: string
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate: () => void
  isAutoliitto: boolean
  isSos: boolean
  showAddresInputAs: ShowAddressOptions
  repairShopId?: string | null
  setAutoliittoRepairShopField: (value: string | null) => void
  translatedServiceTypeOptions: object[]
  serviceChannel: string | null
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  initialValues: any
  setLoading: (loading: boolean) => void
  formatMessage: FormatMessageType
  routeDone: boolean
  reportPosition: (location: Geolocation, name: string) => void
  getValue: any
  setValue: (field: string, value: any) => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
  towingRecordId: number
}

export enum ShowAddressOptions {
  DISABLED = 'disabled',
  ADDRESS = 'address',
  REPAIR_SHOP = 'repair_shop',
  SOS_REPAIR_SHOP = 'sos_repair_shop',
}

export const FinishRoute: React.FunctionComponent<FinishRouteProps> = ({
  onCancel,
  regNo,
  setRouteLocation,
  updateRouteDate,
  isAutoliitto,
  isSos,
  showAddresInputAs,
  repairShopId = null,
  setAutoliittoRepairShopField,
  translatedServiceTypeOptions,
  serviceChannel = null,
  clear,
  // initialValues,
  setLoading,
  // formatMessage,
  routeDone,
  // reportPosition,
  getValue,
  setValue,
  setAction,
  action,
  jobStatus,
  stationId,
  towingRecordId,
}) => {
  const [showLocationChange, setShowLocationChange] = useState(false)
  const [showAddressInput, setShowAddressInput] = useState<ShowAddressOptions>(showAddresInputAs)
  const [showImageModal, setShowImageModal] = useState<boolean>(false)

  // const client = useApolloClient()
  // const getLocation = false

  const { state } = useContext(StateStore)

  const closeModal = () => {
    setShowLocationChange(false)
  }

  const clearLocation = () => {
    setRouteLocation({
      address: '',
      city: '',
      zipcode: '',
      description: '',
      coords: {
        lat: null,
        long: null,
      },
    })
    setAutoliittoRepairShopField(null)
    setShowAddressInput(ShowAddressOptions.ADDRESS)
    closeModal()
  }

  const setLocation = (shopId: string | null) => {
    setLoading(true)
    const repairShop = repairShops.find(shop => shop.id === shopId)

    if (repairShop) {
      const latitude =
        typeof repairShop.location.coordinates === 'object'
          ? (repairShop.location.coordinates as AutoliittoCoords).lat
          : null
      const longitude =
        typeof repairShop.location.coordinates === 'object'
          ? (repairShop.location.coordinates as AutoliittoCoords).lon
          : null

      setRouteLocation({
        address: repairShop.location.address,
        city: repairShop.location.city,
        description: repairShop.name,
        zipcode: repairShop.location.postcode,
        coords: {
          lat: latitude,
          long: longitude,
        },
      })
    }
    setShowAddressInput(ShowAddressOptions.REPAIR_SHOP)
    closeModal()
    setLoading(false)
  }

  const changeServiceType = (serviceType: string | number | undefined) => {
    if (serviceType === 'towing') {
      setLocation(repairShopId)
      return
    }
    setShowAddressInput(ShowAddressOptions.DISABLED)
  }

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal)
  }

  if (showLocationChange && isAutoliitto) {
    return (
      <RepairShopModal
        repairShopId={repairShopId}
        onCancel={closeModal}
        onResetShop={clearLocation}
        onSetShop={setLocation}
        serviceChannel={serviceChannel}
      />
    )
  }

  const label = isSos ? 'Määränpää' : 'Työ valmis'
  const batteryServiceOptions = getBatteryServiceOptions(state.itemGroups, state.items)

  return (
    <RouteWrapper>
      <RouteHeader label={label} regNo={regNo} cancel={onCancel} toggleImageModal={toggleImageModal} />
      {showImageModal && <Images onClose={toggleImageModal} towingRecordId={towingRecordId} vehicleRegNo={regNo} />}
      {isSos ? (
        <SosFinishRoute
          setValue={setValue}
          getValue={getValue}
          setDateField={updateRouteDate}
          setRouteLocation={setRouteLocation}
          clear={clear}
          action={action}
          setAction={setAction}
          jobStatus={jobStatus}
          routeDone={routeDone}
          stationId={stationId}
        />
      ) : (
        <>
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          {showAddressInput === ShowAddressOptions.DISABLED && (
            <RouteRow>
              <RouteColumn>
                <TextInput name="disabledAddress" label="Täytä vain valmistumisaika" disabled />
              </RouteColumn>
              <RouteColumn>
                <DateTimeInput
                  label="Valmistumisaika"
                  name={`finishRoute`}
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setValue('finishRoute.date', new Date())
                    setValue('finishRoute.time', value)
                  }}
                  required={isAutoliitto}
                  showCurrentTimeBtn
                />
                <ClearButton
                  label="Tyhjennä"
                  category="cancel"
                  onClick={() => {
                    clear()
                  }}
                  fontWeight="normal"
                  maxWidth="100%"
                  size="s"
                />
              </RouteColumn>
            </RouteRow>
          )}
          {showAddressInput === ShowAddressOptions.ADDRESS && (
            <RouteRow>
              <RouteColumn>
                <LocationInputMap
                  addressLabel={`Osoite`}
                  addressName={`finishRoute.address`}
                  cityLabel={`Kaupunki`}
                  cityName={`finishRoute.city`}
                  coordsName={`finishRoute.coords`}
                  zipcodeName={`finishRoute.zipcode`}
                  required={isSos}
                  getValue={getValue}
                  values={toMapData(
                    getValue(`finishRoute.address`),
                    getValue(`finishRoute.city`),
                    getValue(`finishRoute.coords`),
                    getValue(`finishRoute.zipcode`)
                  )}
                  setValue={setValue}
                  setRouteLocation={setRouteLocation}
                  stationId={stationId}
                />
                <SideBySideContainer>
                  {isAutoliitto && (
                    <SelectLocationButton
                      label="Aseta korjaamo"
                      category="danger"
                      onClick={() => setShowLocationChange(true)}
                    />
                  )}
                </SideBySideContainer>
              </RouteColumn>
              <RouteColumn>
                <DateTimeInput
                  label="Valmistumisaika"
                  name={`finishRoute`}
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setValue('finishRoute.date', new Date())
                    setValue('finishRoute.time', value)
                  }}
                  required={isAutoliitto}
                  showCurrentTimeBtn
                />
                <ClearButton
                  label="Tyhjennä"
                  category="cancel"
                  onClick={() => {
                    clear()
                  }}
                  fontWeight="normal"
                  maxWidth="100%"
                  size="s"
                />
              </RouteColumn>
            </RouteRow>
          )}
          {showAddressInput === ShowAddressOptions.REPAIR_SHOP && (
            <RouteRow>
              <RouteColumn>
                <SelectInput
                  label="Korjaamo"
                  name="automobileAndTouringClubFinland.repairShopId"
                  options={repairShopOptions(serviceChannel, repairShopId)}
                  disabled
                />
                <ChangeLocationButton
                  label="Vaihda korjaamoa"
                  category="danger"
                  fontWeight="normal"
                  maxWidth="50%"
                  mobileWidth="100%"
                  onClick={() => setShowLocationChange(true)}
                />
              </RouteColumn>
              <RouteColumn>
                <DateTimeInput
                  label="Valmistumisaika"
                  name={`finishRoute`}
                  customOnChange={() => {
                    const value = (format(new Date(), 'HH:mm') as unknown) as Time
                    setValue('finishRoute.date', new Date())
                    setValue('finishRoute.time', value)
                  }}
                  required={isAutoliitto}
                  showCurrentTimeBtn
                />
                <ClearButton
                  label="Tyhjennä"
                  category="cancel"
                  onClick={() => {
                    clear()
                  }}
                  fontWeight="normal"
                  maxWidth="100%"
                  size="s"
                />
              </RouteColumn>
            </RouteRow>
          )}

          {!isSos && !isAutoliitto && state && state.settings && (
            <RouteRow columns="1fr 1fr 1fr">
              <RouteColumn>
                <SelectInput
                  label="Hinauksen syy"
                  name="towingReason"
                  options={state.settings.towingReasons.map((label: string) => ({
                    label,
                    value: label,
                  }))}
                />
              </RouteColumn>
            </RouteRow>
          )}

          {!isSos && (
            <RouteColumn>
              <TextAreaInput label="Lisäselvitykset" name="additionalInfo" />
            </RouteColumn>
          )}

          {isAutoliitto && (
            <>
              <AutoliittoSeparatorLine />
              <EvenRouteRow>
                <RouteColumn>
                  <SelectInput
                    label="Komponentti (ARC-vikakoodi)"
                    name="automobileAndTouringClubFinland.arcCodes.component.code"
                    options={arcComponentOptions}
                  />
                </RouteColumn>
                <RouteColumn>
                  <SelectInput
                    label="Vian syy (ARC-vikakoodi)"
                    name="automobileAndTouringClubFinland.arcCodes.description.code"
                    options={arcDescriptionOptions}
                  />
                </RouteColumn>
              </EvenRouteRow>
              <EvenRouteRow>
                <RouteColumn>
                  <SelectInput
                    label="Työn tyyppi"
                    name="serviceType"
                    options={translatedServiceTypeOptions}
                    onChange={e => {
                      changeServiceType(e)
                    }}
                  />
                </RouteColumn>
                <RouteColumn>
                  <NumberInput
                    label="Mittarilukema, km"
                    name="automobileAndTouringClubFinland.vehicleMileage"
                    required={isAutoliitto}
                    decimals={0}
                  />
                </RouteColumn>
              </EvenRouteRow>
              {getValue('serviceType') === 'battery_service' && (
                <>
                  <EvenRouteRow>
                    <RouteColumn>
                      <SelectInput
                        label="Toimitettu akku"
                        name="automobileAndTouringClubFinland.batteryService.battery"
                        options={batteryServiceOptions}
                        required={isAutoliitto}
                        searchable
                        onChange={e => {
                          if (e !== 'other') {
                            setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', e)
                          } else {
                            setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', '')
                          }
                        }}
                      />
                    </RouteColumn>
                    <RouteColumn>
                      <SelectInput
                        label="Asiakkaan maksutapa"
                        name="automobileAndTouringClubFinland.batteryService.paymentType"
                        options={paymentTypeOptions}
                        required={isAutoliitto}
                      />
                    </RouteColumn>
                  </EvenRouteRow>
                  {getValue('automobileAndTouringClubFinland.batteryService.battery') === 'other' && (
                    <EvenRouteRow>
                      <RouteColumn>
                        <TextInput
                          label="Toimitettu akku, muu"
                          name="automobileAndTouringClubFinland.batteryService.deliveredBattery"
                          required={isAutoliitto}
                        />
                      </RouteColumn>
                    </EvenRouteRow>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <RouteNotes removeLine={isSos} />
    </RouteWrapper>
  )
}

export const ClearButton = styled(Button)`
  margin-top: 1rem;
`

const ChangeLocationButton = styled(Button)`
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

const SelectLocationButton = styled(ChangeLocationButton)`
  margin-left: 1rem;
`

const SideBySideContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`
