import { cloneDeep } from 'lodash'

import { JobItemDiscountType, Maybe, TowingRecordPaymentType, TowingRecordRouteType } from '../../../common/types'
import { CustomerType } from '../../../common/types'
import {
  toSosServiceOrderEditMutationVariables,
  toSosServiceOrderMutationVariables,
} from '../../../components/SosSubForm/mutation/toMutationVariables'
import { SosServiceOrder } from '../../../components/SosSubForm/types'
import { JobItemInput } from '../../../components/TowingRecordItems/types'
import {
  toScalarBusinessId,
  toScalarEmail,
  toScalarPersonalIdentityCode,
  toScalarPhone,
  toScalarShortDate,
  toScalarTime,
} from '../../../util/convertToScalar'
import { vatMapping } from '../../../util/vatCalculations'
import { CustomerFormValues } from '../../Customer/types'
import { BillingInput } from '../components/billing/TowingRecordBilling/types'
import { getIndexOfRoute } from '../components/routes/getIndexOfRoute'
import { toALScalarTimestamp } from '../components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { EditTowingRecordFormValues } from '../EditTowingRecord/types'
import { AutomobileAndTouringClubFinlandInput, JobDetailsInput } from './towingRecordInputTypes'
import {
  AutomobileAndTouringClubFinlandInputMutation,
  BillingInputMutation,
  JobDetailsInputMutation,
  JobItemMutation,
  OrdererInputMutation,
  TowingRecordInput,
  TowingRecordRoute,
  TowingRecordRouteInputMutation,
} from './types'

type InputValues = EditTowingRecordFormValues

export const toTowingRecordInput = (
  inputValues: InputValues,
  useSosServiceOrderEditMutation?: boolean
): TowingRecordInput => {
  const values = cloneDeep(inputValues)
  const allJobItems = values.jobItems.map((jobItem) => jobItemstoMutationVariables(jobItem, values.itemsIncludeVat))

  if (useSosServiceOrderEditMutation) {
    values.routes[getIndexOfRoute(values.routes, TowingRecordRouteType.arrival)] = values.from
    values.routes[getIndexOfRoute(values.routes, TowingRecordRouteType.finish)] = values.to
  }

  if (inputValues.discountItem)
    allJobItems.push(jobItemstoMutationVariables(inputValues.discountItem, values.itemsIncludeVat, true))
  return {
    billing: billingtoMutationVariables(values.billing, values.paymentType),
    itemsIncludeVat: values.itemsIncludeVat,
    jobDetails: jobDetailsToMutationVariables(values.jobDetails),
    jobItems: allJobItems,
    stationId: values.stationId,
    operatorId: values.operatorId,
    vehicleInfoCheckId: values.vehicleInfoCheckId ? values.vehicleInfoCheckId : null,
    label: values.label,
    onCall: values.onCall,
    orderer: values.orderer ? customerToMutationVariables(values.orderer) : null,
    paymentType: values.paymentType,
    recordNumber: values.recordNumber,
    routes: values.routes.map((r) => routeToMutationVariables(r)),
    signature: null,
    tags: values.tags,
    type: values.type,
    vehicleDetails: {
      vehicleClass: values.vehicleDetails.vehicleClass,
      insuranceCompany: values.vehicleDetails.insuranceCompany,
      makeAndModel: values.vehicleDetails.makeAndModel,
      registrationNumber: values.vehicleDetails.registrationNumber,
      owner: values.vehicleDetails.owner,
      holder: values.vehicleDetails.holder,
    },
    automobileAndTouringClubFinland: values.automobileAndTouringClubFinland
      ? ALToMutationVariables(values.automobileAndTouringClubFinland, values.routes, values.jobDetails)
      : null,
    sosServiceOrder: values.sosServiceOrder
      ? resolveSosMutator(values.sosServiceOrder, useSosServiceOrderEditMutation)
      : null,
    lastModified: inputValues.lastModified,
    notificationMethod: inputValues.notificationMethod,
  }
}

const resolveSosMutator = (values: SosServiceOrder, useSosServiceOrderEditMutation?: boolean) => {
  if (useSosServiceOrderEditMutation) {
    return toSosServiceOrderEditMutationVariables(values)
  }
  return toSosServiceOrderMutationVariables(values)
}

export const jobItemstoMutationVariables = (jobItem: JobItemInput, itemsIncludeVat: boolean, isDiscountItem: boolean = false): JobItemMutation => {
  return {
    id: jobItem.id,
    itemId: jobItem.itemId,
    type: jobItem.type,
    name: jobItem.name,
    description: jobItem.description,
    quantity: jobItem.quantity,
    unitPrice: jobItem.unitPrice,
    // Allways have vat included in the discount row if the type is 'amount'
    unitPriceHasVatIncluded: isDiscountItem && jobItem.discountType === JobItemDiscountType.amount ? true : itemsIncludeVat,
    unit: jobItem.unit,
    // set the vat amount based on the vat category, eg "standard" => 24
    vat: jobItem.vatCategory ? vatMapping[jobItem.vatCategory].vat : 0,
    discountType: jobItem.discountType,
    discount: jobItem.discount,
    netAmount: jobItem.netAmount,
    grossAmount: jobItem.grossAmount,
    vatAmount: jobItem.vatAmount,
    vatUnit: '1',
    vatCategory: jobItem.vatCategory,
    limitedUsage: jobItem.limitedUsage,
  }
}

const jobDetailsToMutationVariables = (jobDetails: JobDetailsInput): JobDetailsInputMutation => {
  return {
    serviceType: jobDetails.serviceType,
    orderReference: jobDetails.orderReference,
    towingDate: jobDetails.towingDate ? toScalarShortDate(jobDetails.towingDate) : null,
    towingReason: jobDetails.towingReason,
    additionalInfo: jobDetails.additionalInfo,
    notes: jobDetails.notes,
    incidentCaseNo: jobDetails.incidentCaseNo,
  }
}

const routeToMutationVariables = (route: TowingRecordRoute): TowingRecordRouteInputMutation => {
  return {
    id: route.id,
    ordering: route.ordering,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    date: route.date ? toScalarShortDate(route.date) : null,
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    time: route.time ? toScalarTime(route.time) : null,
    type: route.type,
  }
}

const billingtoMutationVariables = (
  billing: BillingInput,
  paymentType: Maybe<TowingRecordPaymentType>
): BillingInputMutation | null => {
  if (!billing) {
    return null
  }

  if (!paymentType) {
    return null
  }

  if (paymentType === TowingRecordPaymentType.define_later) {
    return null
  }

  return {
    type: paymentType,
    paymentDetails: {
      debtorName: billing.paymentDetails.debtorName,
      receiptEmail: billing.paymentDetails.receiptEmail ? toScalarEmail(billing.paymentDetails.receiptEmail) : null,
      paymentDate: toScalarShortDate(billing.paymentDetails.paymentDate),
    },
  }
}

const customerToMutationVariables = (customer: CustomerFormValues): OrdererInputMutation | null => {
  if (customer.type === CustomerType.person && customer.personCustomer) {
    const { personCustomer } = customer
    return {
      id: customer.id ? customer.id : null,
      personCustomer: {
        type: CustomerType.person,
        ssn: personCustomer.ssn ? toScalarPersonalIdentityCode(personCustomer.ssn) : null,
        firstName: personCustomer.firstName,
        lastName: personCustomer.lastName,
        email: !personCustomer.email || personCustomer.email === '' ? null : toScalarEmail(personCustomer.email),
        phone: !personCustomer.phone || personCustomer.phone === '' ? null : toScalarPhone(personCustomer.phone),
      },
      companyCustomer: null,
    }
  }

  if (customer.type === CustomerType.company && customer.companyCustomer) {
    const { companyCustomer } = customer
    return {
      id: customer.id ? customer.id : null,
      personCustomer: null,
      companyCustomer: {
        type: CustomerType.company,
        businessId: companyCustomer.businessId === '' ? null : toScalarBusinessId(companyCustomer.businessId),
        companyName: companyCustomer.companyName,
        contactPerson: companyCustomer.contactPerson,
        contactPersonEmail:
          companyCustomer.contactPersonEmail === '' ? null : toScalarEmail(companyCustomer.contactPersonEmail),
        phone: companyCustomer.phone === '' ? null : toScalarPhone(companyCustomer.phone),
      },
    }
  }

  return null
}

const resolveALCompletionARC = (jobDetails: JobDetailsInput) => {
  switch (jobDetails.serviceType) {
    case 'phone_service':
      return '12'
    case 'battery_service':
      return '68'
    case 'road_service':
      return '96'
    case 'towing':
      return '6G'
    default:
      throw new Error(`Unrecognized service type for AL job '${jobDetails.serviceType}'`)
  }
}

const hasRouteAndDate = (index: number, routes: TowingRecordRoute[]) =>
  routes && routes[index] && routes[index].date && routes[index].time

// Autoliitto
const ALToMutationVariables = (
  al: AutomobileAndTouringClubFinlandInput,
  routes: TowingRecordRoute[],
  jobDetails: JobDetailsInput
): AutomobileAndTouringClubFinlandInputMutation => {
  const arrivalRouteIndex = getIndexOfRoute(routes, TowingRecordRouteType.arrival)
  // finish route date and time = Autoliitto completion timestamp
  const finishRouteIndex = getIndexOfRoute(routes, TowingRecordRouteType.finish)

  const actualTimeOfArrival = hasRouteAndDate(arrivalRouteIndex, routes)
    ? { date: routes[arrivalRouteIndex].date, time: routes[arrivalRouteIndex].time }
    : null

  const actualTimeOfCompletion = hasRouteAndDate(finishRouteIndex, routes)
    ? { date: routes[finishRouteIndex].date, time: routes[finishRouteIndex].time }
    : null

  const estimatedTimeOfArrival = al.jobTimestamps.estimatedTimeOfArrival

  const estimatedTimeOfCompletion = al.jobTimestamps.estimatedTimeOfCompletion

  return {
    arcCodes: {
      completion: {
        code: resolveALCompletionARC(jobDetails),
        label: null,
      },
      description: {
        code: al.arcCodes.description ? al.arcCodes.description.code : null,
        label: al.arcCodes.description ? al.arcCodes.description.label : null,
      },
      component: {
        code: al.arcCodes.component ? al.arcCodes.component.code : null,
        label: al.arcCodes.component ? al.arcCodes.component.label : null,
      },
    },
    caseNumber: al.caseNumber,
    delayedTowing: al.delayedTowing,
    delayedTowingReason: al.delayedTowingReason,
    distanceTowed: al.distanceTowed,
    jobId: al.jobId,
    personCount: al.personCount,
    repairShopId: al.repairShopId,
    serviceChannel: al.serviceChannel,
    towingInsistedByCustomer: al.towingInsistedByCustomer,
    vehicleMileage: al.vehicleMileage,
    jobTimestamps: {
      actualTimeOfArrival: actualTimeOfArrival ? toALScalarTimestamp(actualTimeOfArrival) : null,
      actualTimeOfCompletion: actualTimeOfCompletion ? toALScalarTimestamp(actualTimeOfCompletion) : null,
      estimatedTimeOfArrival: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
      estimatedTimeOfCompletion: estimatedTimeOfCompletion ? toALScalarTimestamp(estimatedTimeOfCompletion) : null,
    },
    billingInfo: al.billingInfo,
  }
}
