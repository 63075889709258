import React from 'react'
import { TowingRecordJobStatus } from '../../../common/types'
import { AutoliittoSeparatorLine } from '../../../containers/TowingRecordPage/components/routes/subforms/styled'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { CollapseHeader } from '../../Collapse/CollapseHeader'
import { CollapseProps } from '../../Collapse/types'
import { SosArrivalRouteForm } from '../ArrivalRouteForm'
import { ArrivalRouteAction, ArrivalRouteProps } from './types'

interface FinishedAtBreakdownLocationProps extends CollapseProps {
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
}

export const FinishedAtBreakdownLocation: React.FunctionComponent<
  FinishedAtBreakdownLocationProps & ArrivalRouteProps
> = ({ open, toggle, getValue, setValue, action, setAction, jobStatus }) => {
  return (
    <>
      <CollapseHeader label="VALMIS KOHTEESSA" open={open} position="bottom" toggle={toggle} />
      {open && (
        <>
          <CollapseContent>
            <SosArrivalRouteForm
              action={action}
              getValue={getValue}
              setValue={setValue}
              setAction={setAction}
              jobStatus={jobStatus}
              //   action={action}
            />
          </CollapseContent>
          <AutoliittoSeparatorLine marginBottom="0" marginTop="0" />
        </>
      )}
    </>
  )
}
