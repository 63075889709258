import ApolloClient from 'apollo-client'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Maybe, TypeIdentifier } from '../../common/types'
import { RouteHeader } from '../../containers/TowingRecordPage/components/routes/subforms/RouteHeader'
import { HalfButton } from '../Button'
import { Section, SectionColumn, Text } from '../layout'
import { HeaderContainer, ModalContainer, ModalWindow } from '../Map'
import { setErrorNotification } from '../notification'
import { Loading } from '../responses'
import { getVehicleInfo, isVehicleInfoError, queryVehicleInfo } from './query'
import TrafiSearch from './TrafiSearch'
import { VehicleInfo } from './types'

interface Props {
  vehicleRegistration: string
  client: ApolloClient<any>
  close: () => void
  vehicleInfoCheckId: Maybe<number>
  buttonText?: string
  buttonHandler?: (vehicleInfo: VehicleInfo) => void
}

export const ModalText = styled.div`
  margin: 1rem 0 1rem 0;
`

const VehicleSearchModal: React.FunctionComponent<Props> = ({
  vehicleRegistration = '',
  client,
  close,
  vehicleInfoCheckId,
  buttonText,
  buttonHandler,
}) => {
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfo | null>(null)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = useCallback(
    async (reload: boolean = false) => {
      try{
      setLoading(true)
        const result =
          vehicleInfoCheckId && !reload
            ? await getVehicleInfo(client, vehicleInfoCheckId)
            : await queryVehicleInfo(client, vehicleRegistration, reload)
        
        setError(false)

        // if we not successfull
        if(isVehicleInfoError(result)){
          setErrorNotification('Haku epäonnistui', result.error.message)
          setError(true)
          setLoading(false)
          return;
        }
        setVehicleInfo(result.vehicleInfo)
     } catch (error){
      setError(true)
      setErrorNotification('Virhe', 'Haussa tapahtui virhe')
     } 
      setLoading(false)
    },
    [client, vehicleRegistration, setLoading, vehicleInfoCheckId]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  const reFetch = useCallback(() => {
    fetch(true)
  }, [fetch])

  const onClick = useCallback(() => {
    if (buttonHandler && vehicleInfo) {
      buttonHandler(vehicleInfo)
    }
    close()
  }, [vehicleInfo, buttonHandler, close])

  return (
    <>
      <ModalContainer>
        <ModalWindow>
          <HeaderContainer>
            <RouteHeader cancel={close} marginBottom="0" />
          </HeaderContainer>
          <Container>
            {error && <WarningText size="medium">Tietojen haku epäonnistui</WarningText>}
            {vehicleInfo && vehicleInfo.searchMeta && vehicleInfo.searchMeta.daysSinceTrafiSearch > 0 && (
              <>
                {[TypeIdentifier.lahitapiola, 'sos'].includes(vehicleInfo.searchMeta.dataSource) ? (
                  <>
                    {vehicleInfo.searchMeta.dataSource === 'sos' && (
                      <WarningText size="medium">
                        <b>Huom!</b> Alla olevat tiedot on saatu SOS tilauksen yhteydessä{' '}
                        {vehicleInfo.searchMeta.trafiSearchDate.toLocaleDateString('fi')}.
                      </WarningText>
                    )}
                    {vehicleInfo.searchMeta.dataSource === TypeIdentifier.lahitapiola && (
                      <WarningText size="medium">
                        <b>Huom!</b> Alla olevat tiedot on saatu LähiTapiola tilauksen yhteydessä{' '}
                        {vehicleInfo.searchMeta.trafiSearchDate.toLocaleDateString('fi')}.
                      </WarningText>
                    )}
                  </>
                ) : (
                  <WarningText size="medium">
                    <b>Huom!</b> Alla olevat tiedot on haettu Traficomista{' '}
                    {vehicleInfo.searchMeta.trafiSearchDate.toLocaleDateString('fi')}.
                  </WarningText>
                )}
              </>
            )}          
            <Section justify="text" columns={1} height="auto">
              <TrafiButtonSectionColumn alignItems="start">
                {vehicleInfo && buttonText && (
                  <TrafiButton category="save" size="s" onClick={onClick}>
                    {buttonText}
                  </TrafiButton>
                )}
                {vehicleInfo && vehicleInfo.searchMeta && vehicleInfo.searchMeta.daysSinceTrafiSearch > 0 && (
                  <TrafiButton category="danger" size="s" onClick={reFetch}>
                    Hae Traficomista
                  </TrafiButton>
                )}
              </TrafiButtonSectionColumn>
            </Section>
            {vehicleInfo && vehicleInfo.searchMeta && vehicleInfo.searchMeta.daysSinceTrafiSearch === 0 && (
              <InfoText size="medium">{vehicleInfo.searchMeta.dataSource === 'sos' && (
               "Alla olevat tiedot on saatu SOS tilauksen yhteydessä"
              )}
              {vehicleInfo.searchMeta.dataSource === TypeIdentifier.lahitapiola && (
               "Alla olevat tiedot on saatu LähiTapiola tilauksen yhteydessä"
              )}
              {vehicleInfo.searchMeta.dataSource === 'cache' && (
               "Alla olevat tiedot on haettu Traficomista"
              )}
              {vehicleInfo.searchMeta.dataSource === 'trafi' && (
               "Alla olevat tiedot on haettu Traficomista"
              )}
              {' '}{vehicleInfo.searchMeta.trafiSearchDate.toLocaleDateString('fi')}
              </InfoText>
            )}
            {vehicleInfo && <TrafiSearch vehicleInfo={vehicleInfo} reFetch={reFetch} />}
          </Container>
          <Loading loading={loading} />
        </ModalWindow>
      </ModalContainer>
    </>
  )
}

export default VehicleSearchModal

const Container = styled.div`
  padding: 0 1rem 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0 0.5rem;
  }
`

const WarningText = styled(Text)`
  margin-bottom: 0.5rem;
`

const InfoText = styled(Text)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const TrafiButton = styled(HalfButton)`
  width: calc(16% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    width: calc(25% - 1rem);
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: 100%;
    max-width: 100%;
  }
`

const TrafiButtonSectionColumn = styled(SectionColumn)`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row;
  grid-gap: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-gap: 0.5rem;
  }
`
