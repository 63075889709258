import React, { useCallback, useEffect, useState } from 'react'
import { SosArrivedAtDestinationForm } from '../FinishRouteForm/ArrivalForm'
import { SosFinishedAtDestinationForm } from '../FinishRouteForm/FinishedForm'
import { FinishRouteForm, FinishRouteProps } from './types'

interface Props {
  routeDone: boolean
}

export const SosFinishRoute: React.FunctionComponent<FinishRouteProps & Props> = props => {
  const [selectedForm, setSelectedForm] = useState<FinishRouteForm>('arrived')
  const toggle = useCallback(() => {
    //   setSelectedForm(form)
    if (selectedForm === 'arrived') {
      setSelectedForm('finished')
    } else {
      setSelectedForm('arrived')
    }
  }, [selectedForm, setSelectedForm])

  useEffect(() => {
    if (props.routeDone && (props.action === 'edit-finished' || props.action === 'default')) {
      setSelectedForm('finished')
    }
  }, [props.action, props.routeDone, setSelectedForm])
  return (
    <>
      <SosArrivedAtDestinationForm open={selectedForm === 'arrived'} toggle={() => toggle()} {...props} />
      <SosFinishedAtDestinationForm open={selectedForm === 'finished'} toggle={() => toggle()} {...props} />
    </>
  )
}
