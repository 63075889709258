import { default as raw_repair_shops } from './repair_shops.json'

export interface RepairShop {
  id: string
  name: string
  location: {
    address: string
    postcode: string
    city: string
    coordinates: AutoliittoCoords
  }
  serviceChannels?: ServiceChannel[]
}

export interface AutoliittoCoords {
  lat: number
  lon: number
}

interface ServiceChannel {
  id: string
  type: string
  arcId: string
}

export const repairShops = raw_repair_shops as RepairShop[]

export const repairShopOptions = (serviceChannel: string | null, repairShopId: string | null) => {
  // return only the currently selected repair shop AND
  // repair shops with channel names equal to towingRecord.automobileAndTouringClubFinland.serviceChannel
  const filteredRepairShops = repairShops.filter(shop =>
    shop.serviceChannels
      ? shop.serviceChannels.find(channel => channel.type === serviceChannel) || shop.id === repairShopId
      : false
  )
  return filteredRepairShops.map(shop => {
    return {
      value: shop.id,
      label: `${shop.name}`,
    }
  })
}
