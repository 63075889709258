import gql from 'graphql-tag'

import { sosServiceOrderFragment } from '../../../components/SosSubForm/types'
import { vehicleInfoFragment } from '../../../components/VehicleSearchModal/query'
import { ResourceNotFoundError, UnauthorizedError } from '../../errorTypes'
import { EditTowingOrder } from './types'

export const EDIT_TOWING_ORDER_QUERY = gql`
  query TowingOrder($id: Int!) {
    towingOrder(id: $id) {
      ... on GetTowingOrderSuccess {
        towingOrder {
          id
          operatorId
          stationId
          vehicleInfoCheckId
          label
          type
          typeIdentifier
          visibility
          notificationMethod
          vehicleDetails {
            registrationNumber
            vehicleClass
            makeAndModel
            owner
            holder
            insuranceCompany
          }
          jobDetails {
            towingDateType
            towingDate
            towingEndDate
            towingTime
            towingEndTime
            additionalInfo
            orderReference
            towingReason
            notes
            incidentCaseNo
            serviceType
          }

          orderer {
            __typename
            id
            ... on PersonCustomer {
              ssn
              firstName
              lastName
              phone
              email
              address
              identifier
              extraInfo
              co
              city
              zipcode
              paymentTerms
              deliveryMethod
              additionalInfo
              notificationMethod
              originatorApp
            }
            ... on CompanyCustomer {
              companyName
              businessId
              contactPerson
              contactPersonEmail
              phone
              address
              identifier
              extraInfo
              co
              zipcode
              city
              eInvoiceAddress
              eInvoiceOperator
              paymentTerms
              deliveryMethod
              email
              additionalInfo
              notificationMethod
              originatorApp
            }
          }
          from {
            address
            city
            description
            zipcode
            coords {
              lat
              long
            }
          }
          to {
            address
            city
            description
            zipcode
            coords {
              lat
              long
            }
          }
          sosServiceOrder {
            ...SosServiceOrderFields
          }
          automobileAndTouringClubFinland {
            jobId
            caseNumber
            serviceChannel
            billingInfo
            personCount
            vehicleMileage
            arcCodes {
              component {
                code
                label
              }
              description {
                code
                label
              }
              completion {
                code
                label
              }
            }
            repairShopId
            distanceTowed
            towingInsistedByCustomer
            delayedTowing
            delayedTowingReason
            situationDescription
            jobTimestamps {
              estimatedTimeOfArrival {
                date
                time
              }
              actualTimeOfArrival {
                date
                time
              }
              estimatedTimeOfCompletion {
                date
                time
              }
              actualTimeOfCompletion {
                date
                time
              }
            }
          }
          vehicleInfoCheck {
            ...VehicleInfoFields 
          }
        }
      }
    }
  }
  ${sosServiceOrderFragment.success}
  ${vehicleInfoFragment.success}
`

export interface TowingOrderQueryVariables {
  id: number
}

export interface EditTowingOrderQueryResponse {
  towingOrder: TowingOrderOutput
}

export type TowingOrderOutput = TowingOrderSuccess | UnauthorizedError | ResourceNotFoundError

interface TowingOrderSuccess
  extends Readonly<{
    __typename: 'GetTowingOrderSuccess'
    towingOrder: EditTowingOrder
  }> {}
