// import { Field } from 'formik'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button } from '../../../../Button'
import { FieldButtonRow } from '../../../buttonlayout'
import { Label } from '../../style'
// import { DateTimeField } from '../../field/DateTimeField'
import { DateInput } from '../DateInput'
import { TimeInput } from '../TimeInput'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  className?: string
  customOnChange?: () => void
  showCurrentTimeBtn?: boolean
}

export const DateTimeInput: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  customOnChange,
  showCurrentTimeBtn,
  ...rest
}) => {
  const setCurrentTime = useCallback(() => {
    if (customOnChange) {
      customOnChange()
    }
  }, [customOnChange])
  return (
    <>
      <ComboInputWrapper
        showCurrentTimeBtn={showCurrentTimeBtn && !disabled}
        flexDirection={label ? 'column' : 'initial'}
      >
        {label && <Label required={required}>{label}</Label>}
        <FieldButtonRow sameBorderRadius fullWidth>
          <TimeInput
            name={`${name}.time`}
            required={required}
            disabled={disabled}
            onSetCurrentTime={setCurrentTime}
            {...rest}
            setTimeButton={false}
            // label={label}
          />
          <DateInput name={`${name}.date`} required={required} disabled={disabled} {...rest} />
          {showCurrentTimeBtn && !disabled && (
            <Button category="clock" label="" size="s" onClick={setCurrentTime} disabled={disabled} />
          )}
        </FieldButtonRow>
      </ComboInputWrapper>
    </>
  )
}

// .hLwWJX div:last-of-type > div

interface WrapperProps {
  showCurrentTimeBtn?: boolean
  flexDirection?: string
}

// .fjBRlN div:first-of-type > div

const ComboInputWrapper = styled.div<WrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'initial')};

  & > div:last-of-type {
    width: 100%;
  }

  & > div:first-of-type {
    width: 100%;
  }

  & div:last-of-type > div {
    border-radius: ${props => (props.showCurrentTimeBtn ? '0' : '0 5px 5px 0')};
  }

  & div:first-of-type > div {
    border-radius: 0 5px 5px 0;
  }

  & div:last-of-type > label {
    // visibility: hidden;
  }

  & div:first-of-type > div > input {
    border-right-color: transparent;
  }

  & div > .error-tooltip {
    top: -3rem;
    width: 10.1em;
  }
`
