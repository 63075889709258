import { useQuery } from '@apollo/react-hooks'
import React, { useState, useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { ButtonColumn, ButtonRow, Column, Heading2, Page, Row, FlexContainer } from '../../../components/layout'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { useHistoryPush } from '../../../components/router'
import { TowingRecordRow } from '../../../components/TowingRecordRow'
import { TOWING_JOBS_COMPLETED_QUERY, TowingJobsResponse } from '../TowingJobsQuery'
import Link from '../../../components/Link'
import styled from 'styled-components'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { messages } from '../messages'
import { StateStore } from '../../../containers/StoreProvider'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'

const HeaderContainer = styled(FlexContainer)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
  }
`

const AddButton = styled(Button)`
  max-width: 100%;
`

const CompletedJobsLink = styled(Link)`
  margin-left: 2rem;
`

const CompletedTowingJobsPage: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const [expandedRowId, clickOnRow] = useState<number | null>(null)
  const handleItemClick = (clickedId: number) => {
    clickOnRow(clickedId !== expandedRowId ? clickedId : null)
  }

  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingJobsResponse>
  const historyPush = useHistoryPush()

  const { formatMessage } = intl
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })
  const towingJobsQueryResult = useQuery<TowingJobsResponse>(TOWING_JOBS_COMPLETED_QUERY, {
    variables: {
      status: 'COMPLETED',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const { state } = useContext(StateStore)

  return (
    <TypedGenericResultHandler queryResult={towingJobsQueryResult} loading unauthorized>
      {data => {
        return (
          <Page noPadding>
            <Row space="double">
              <Column>
                <HeaderContainer>
                  <Heading2>Valmiit työt</Heading2>
                  <CompletedJobsLink to="/towing-jobs">Työlista</CompletedJobsLink>
                </HeaderContainer>
              </Column>
              <Column>
                <ButtonRow padding="0">
                  <ButtonColumn>
                    <AddButton
                      category="new"
                      label={formatMessage(messages.newTowingOrderButton)}
                      onClick={() => {
                        historyPush('/towing-order/add')
                      }}
                    />
                  </ButtonColumn>
                </ButtonRow>
              </Column>
            </Row>
            {data &&
              data.towingRecords.towingRecords.map(towingRecord => (
                <TowingRecordRow
                  expandedRow={expandedRowId}
                  handleItemClick={handleItemClick}
                  towingRecord={towingRecord}
                  translateMessage={translateMessage}
                  key={towingRecord.id}
                  slug={`/towing-record/${towingRecord.id}`}
                  station={getStationByIdFromSettings(towingRecord.stationId, state.settings)}
                  operatorName={getOperatorLabelFromId(
                    towingRecord.operatorId,
                    state.currentUser,
                    state.settings ? state.settings.operators : null
                  )}
                />
              ))}
          </Page>
        )
      }}
    </TypedGenericResultHandler>
  )
}

export default injectIntl(CompletedTowingJobsPage)
