import ApolloClient from 'apollo-client'
import { FormikProps, getIn } from 'formik'
import React, { useCallback } from 'react'
import { useApolloClient } from 'react-apollo'
import { LocationInputMapProps } from '../..'
import { LocationInput } from '../../..'
import { ComboInputWrapper } from '../../../../..'
import { ucfirst } from '../../../../../../../util/common'
import { GET_COORDS } from '../../../../../../RouteAddressSelector/query'
import { GeocodeResponse } from '../../../../../../RouteAddressSelector/types'
import { CityInput } from '../../../CityInput'

interface Props extends LocationInputMapProps {}

export const LocationInputWrapper: React.FunctionComponent<Props> = ({
  addressLabel,
  addressName,
  cityLabel,
  cityName,
  coordsName,
  zipcodeName,
  descriptionName,
  required,
  disabled,
  coordsRequired,
  setValue,
  includeNameInAddress = true,
}) => {
  const client = useApolloClient()

  const getCoords = useCallback(
    async (client: ApolloClient<any>, variables: { address: string }, address: string, city: string) => {
      try {
        const response = await client.query<GeocodeResponse>({ variables, query: GET_COORDS })

        if (response.data && response.data.geocode && response.data.geocode.__typename === 'GetGeocodeSuccess') {
          console.log('GetGeocodeSuccess')
          const { lat, long } = response.data.geocode.result
          const formattedAddress = ucfirst(address)
          const formattedCity = ucfirst(city)
          setValue(coordsName, { lat, long })
          setValue(addressName, formattedAddress)
          setValue(cityName, formattedCity)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [coordsName, setValue, addressName, cityName]
  )

  const handleLocationBlur = useCallback(
    (form: FormikProps<any>) => {
      if (coordsRequired) {
        const address = getIn(form.values, addressName)
        const coords = getIn(form.values, coordsName)
        const city = getIn(form.values, cityName)
        if ((!coords || (!coords.lat || !coords.long)) && city && address) {
          if (address.length >= 3 && city.length >= 2) {
            getCoords(client, { address: `${address}, ${city}` }, address, city)
          }
        }
      }
    },
    [coordsRequired, coordsName, cityName, addressName, getCoords, client]
  )

  const handleCityBlur = useCallback(
    (form: FormikProps<any>) => {
      if (coordsRequired) {
        const address = getIn(form.values, addressName)
        const coords = getIn(form.values, coordsName)
        const city = getIn(form.values, cityName)
        if ((!coords || (!coords.lat || !coords.long)) && city && address) {
          if (address.length >= 3 && city.length >= 2) {
            getCoords(client, { address: `${address}, ${city}` }, address, city)
          }
        }
      }
    },
    [coordsRequired, coordsName, cityName, addressName, getCoords, client]
  )

  return (
    <ComboInputWrapper>
      <LocationInput
        addressLabel={addressLabel}
        addressName={addressName}
        cityName={cityName}
        coordsName={coordsName}
        zipcodeName={zipcodeName}
        required={required}
        disabled={disabled}
        descriptionName={descriptionName}
        coordsRequired={coordsRequired}
        onBlur={handleLocationBlur}
        includeNameInAddress={includeNameInAddress}
      />
      <CityInput
        label={cityLabel}
        name={cityName}
        coordsName={coordsName}
        required={required}
        disabled={disabled}
        onBlur={handleCityBlur}
      />
    </ComboInputWrapper>
  )
}
