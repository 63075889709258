import React, { useState } from 'react'
import { MapType, MapData, GetValueType } from './types'
import { RouteHeader } from '../../containers/TowingRecordPage/components/routes/subforms/RouteHeader'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import { getApiKey } from './getApiKey'
import { Marker } from './Marker'
import { Coords } from '../../common/types'
import { useApolloClient } from 'react-apollo'
import { REVERSE_GEOCODE } from '../ReverseGeocodeButton/queries'
import { Loading } from '../responses'
// import { ButtonContainer } from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { Button } from '../Button'
import { Formik } from 'formik'
import { MapForm } from './MapForm'
import { LocationInputMapFormValues } from '../layout/form/input/LocationInputMap/types'
import { Station } from '../../containers/Parameters/types'

interface Props {
  type?: MapType
  onClose: () => void
  getValue?: GetValueType
  originalValues: MapData
  formData?: any
  setValue: (field: string, value: any) => void
  includeNameInAddress: boolean
  station: Station | undefined
}

export const GMapModal: React.FunctionComponent<Props> = ({
  onClose,
  formData,
  originalValues,
  setValue,
  includeNameInAddress,
  station,
}) => {
  const apiKey = getApiKey()
  const client = useApolloClient()
  const defaultCenter =
    station && station.coords.lat && station.coords.long
      ? { lat: station.coords.lat, lng: station.coords.long }
      : { lat: 60.169857, lng: 24.938379 }
  const { addressName, coordsName, cityName, zipcodeName } = formData
  const { coords, address, city, zipcode } = originalValues
  const [loading, setLoading] = useState<boolean>(false)

  const setRouteLocation = (locationData: LocationInputMapFormValues) => {
    if (locationData) {
      setValue(addressName, locationData.address)
      setValue(cityName, locationData.city)
      setValue(`${coordsName}.lat`, locationData.coords.lat)
      setValue(`${coordsName}.long`, locationData.coords.long)
      setValue(zipcodeName, locationData.zipcode)
      onClose()
    }
  }

  const initialValues: LocationInputMapFormValues = {
    address,
    city,
    zipcode,
    coords,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      render={({ setFieldValue, values }) => {
        const center =
          values.coords && values.coords.lat && values.coords.long
            ? { lat: values.coords.lat, lng: values.coords.long }
            : defaultCenter

        const setValues = (newValues: LocationInputMapFormValues) => {
          setFieldValue('address', newValues.address)
          setFieldValue('city', newValues.city)
          setFieldValue('coords', newValues.coords)
          setFieldValue('zipcode', newValues.zipcode)
        }

        const getAddressQuery = async (coords: Coords) => {
          if (coords.lat && coords.long) {
            setLoading(true)
            const data = await client.query({
              variables: { location: coords },
              query: REVERSE_GEOCODE,
            })

            if (data.data.reverseGeocode.__typename === 'GetReverseGeocodeSuccess') {
              setLoading(false)

              const {
                data: {
                  reverseGeocode: {
                    result: { address, city, zipcode },
                  },
                },
              } = data
              setValues({ address, city, zipcode, coords })
            }
          }
        }

        const clear = () => {
          setFieldValue('address', '')
          setFieldValue('city', '')
          setFieldValue('coords.lat', null)
          setFieldValue('coords.long', null)
          setFieldValue('zipcode', '')
        }

        return (
          <>
            <ModalContainer>
              <ModalWindow>
                <HeaderContainer>
                  <RouteHeader cancel={onClose} marginBottom="0" />
                </HeaderContainer>
                <ContentContainer>
                  <MapContainer>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: apiKey }}
                      defaultCenter={defaultCenter}
                      defaultZoom={14}
                      center={center}
                      onClick={async (value: any) => {
                        const { lat, lng } = value
                        const newCoords = { lat, long: lng }

                        getAddressQuery(newCoords)
                      }}
                    >
                      {values.coords.lat && values.coords.long && (
                        <Marker label={values.address} lat={values.coords.lat} lng={values.coords.long} />
                      )}
                    </GoogleMapReact>
                  </MapContainer>
                  <MapForm empty={clear} includeNameInAddress={includeNameInAddress} />
                  <Button
                    category="save"
                    size="l"
                    label="Tallenna"
                    onClick={() => {
                      setRouteLocation(values)
                    }}
                    maxWidth="100%"
                    disabled={loading}
                    padding="0"
                  />
                  <Loading loading={loading} />
                </ContentContainer>
              </ModalWindow>
            </ModalContainer>
          </>
        )
      }}
    />
  )
}

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  z-index: 1000;
  padding-bottom: 1rem;
`

export const ModalWindow = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin: 0 auto;
  border-radius: ${props => props.theme.radius.small};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 8px solid #fabf40;
`

const ContentContainer = styled.div`
  padding: 0.5rem 1.5rem;
`

const MapContainer = styled.div`
  width: 100%;
  height: 70vh;
  border: 1px solid;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`

export const HeaderContainer = styled.div`
  padding: 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0.5rem;
  }
`

export const getFormData = (addressName: string, cityName: string, coordsName: string, zipcodeName: string) => {
  return {
    addressName,
    cityName,
    coordsName,
    zipcodeName,
  }
}
