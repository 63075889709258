import { format } from 'date-fns'
import { find } from 'lodash'

import { Maybe, Time, TowingRecordRouteType } from '../../../../../../common/types'
import { Station } from '../../../../../Parameters/types'
import { TowingRecord } from '../../../../ViewTowingRecord/types'
import { createNewRoute } from '../../createNewRoute'
import { ArrivalRoute } from '../ArrivalRoute/types'
import { EditStartRouteFormValues, StartRoute } from './types'

export function getInitialValues(towingRecord: TowingRecord, stations: Maybe<Station[]>): EditStartRouteFormValues {
  const routes = towingRecord.routes
  const routeFound = find(routes, ['type', TowingRecordRouteType.start])
  let routeDone = false

  const route =
    routeFound && routeFound.type === TowingRecordRouteType.start
      ? ({ ...routeFound } as StartRoute)
      : (createNewRoute(TowingRecordRouteType.start, false) as StartRoute)

  if (route.date && route.time) {
    routeDone = true
  }

  if (!route.date) {
    route.date = new Date()
  }

  if (!route.time) {
    route.time = (format(new Date(), 'HH:mm') as unknown) as Time
  }

  if (!route.id && stations && towingRecord.sosServiceOrder && towingRecord.stationId) {
    const stationFound = find(stations, ['id', towingRecord.stationId])
    if (stationFound) {
      route.address = stationFound.address
      route.city = stationFound.city
      route.coords = stationFound.coords
      route.zipcode = stationFound.zipcode
    }
  }

  const arrivalRouteFound = find(routes, ['type', TowingRecordRouteType.arrival])
  const arrivalRoute =
    arrivalRouteFound && arrivalRouteFound.type === TowingRecordRouteType.arrival
      ? ({ ...arrivalRouteFound } as ArrivalRoute)
      : (createNewRoute(TowingRecordRouteType.arrival, true) as ArrivalRoute)

  let alValues = null
  let sosValues = null

  if (towingRecord.automobileAndTouringClubFinland) {
    const estimatedTimeOfArrival = towingRecord.automobileAndTouringClubFinland.jobTimestamps.estimatedTimeOfArrival
    alValues = {
      estimatedTimeOfArrival: {
        date: estimatedTimeOfArrival ? estimatedTimeOfArrival.date : null,
        time: estimatedTimeOfArrival ? estimatedTimeOfArrival.time : null,
      },
    }
  }

  if (towingRecord.sosServiceOrder) {
    const estimatedTimeOfArrival = towingRecord.sosServiceOrder.jobTimestamps.estimatedTimeOfArrival
    const sosJobStartedAt = towingRecord.sosServiceOrder.jobTimestamps.started
      ? towingRecord.sosServiceOrder.jobTimestamps
      : null
    const jobTimestamps = sosJobStartedAt ? sosJobStartedAt : towingRecord.jobTimestamps
    sosValues = {
      estimatedTimeOfArrival: {
        date: estimatedTimeOfArrival ? estimatedTimeOfArrival.date : null,
        time: estimatedTimeOfArrival ? estimatedTimeOfArrival.time : null,
      },
      currentLocation: {
        address: '',
        city: '',
        zipcode: '',
        coords: {
          lat: null,
          long: null,
        },
      },
      jobStarted: {
        date: jobTimestamps && jobTimestamps.started ? jobTimestamps.started.date : null,
        time: jobTimestamps && jobTimestamps.started ? jobTimestamps.started.time : null,
      },
    }
  }

  return {
    routeDone,
    startRoute: route,
    arrivalRoute,
    id: towingRecord.id,
    automobileAndTouringClubFinland: alValues,
    sosServiceOrder: sosValues,
    notes: towingRecord.jobDetails.notes,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
  }
}
