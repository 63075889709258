import { useApolloClient } from '@apollo/react-hooks'
import * as React from 'react'
import styled from 'styled-components'
import { Maybe } from '../../common/types'
import { Station } from '../../containers/Parameters/types'
import { Button, ButtonCategory } from '../Button'
import { Label } from '../layout/form/style/Label'
import { GET_COORDS } from '../RouteAddressSelector/query'
import { ApolloClientGeocodeResponse, Location } from '../RouteAddressSelector/types'

interface StationButtonGroupProps {
  stations: Maybe<Station[]>
  label?: string
  setRouteLocation: (location: Location) => void
  stationId?: number | null
  setStation: (stationId: number) => void
  cleared: boolean
  setLoading: (loading: boolean) => void
  required?: boolean
}

export const StationButtonGroup: React.FunctionComponent<StationButtonGroupProps> = ({
  stations,
  label,
  setRouteLocation,
  stationId,
  setStation,
  cleared,
  setLoading,
  required,
}) => {
  const getStationIndex = (stationsList: Maybe<Station[]>, selectedStation: number): Maybe<number> => {
    if (stationsList) {
      const indexOfStation = stationsList.findIndex(station => station.id === selectedStation)
      if (indexOfStation > -1) return indexOfStation
    }
    return null
  }

  const selectedBtnCategory: ButtonCategory = 'yellow'
  const unSelectedBtnCategory: ButtonCategory = 'new'

  const client = useApolloClient()

  const getCoords = async (addrOption: any) => {
    setLoading(true)
    const stationIndex = getStationIndex(stations, Number(addrOption))

    const address = stationIndex != null && stations ? stations[stationIndex].address : null
    const city = stationIndex != null && stations ? stations[stationIndex].city : null
    const coords = stationIndex != null && stations && stations[stationIndex].coords
    const zipcode = stationIndex != null && stations ? stations[stationIndex].zipcode : null
    const lat = coords ? coords.lat : null
    const long = coords ? coords.long : null
    if (lat && long) {
      setRouteLocation({
        address,
        city,
        zipcode,
        coords: {
          lat,
          long,
        },
      })
      setLoading(false)
      return
    }
    if (!isNaN(Number(addrOption))) {
      try {
        const { data }: ApolloClientGeocodeResponse = await client.query({
          variables: { address: `${address}, ${city}` },
          query: GET_COORDS,
          fetchPolicy: 'network-only',
        })
        if (data.geocode.__typename === 'GetGeocodeSuccess') {
          setRouteLocation({
            address,
            city,
            zipcode,
            coords: {
              lat: data.geocode.result.lat,
              long: data.geocode.result.long,
            },
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  const stationaryAddresses: Station[] = []
  if (stations) {
    stations.forEach(station => {
      if (station.type === 0 || station.type === 2) stationaryAddresses.push(station)
    })
  }

  if (stationaryAddresses.length === 1 && !stationId && !cleared) {
    getCoords(stationaryAddresses[0].id)
    setStation(stationaryAddresses[0].id)
  }
  return (
    <>
      {label && <Label required={required}>{label}</Label>}
      <Container>
        <Row>
          {stations &&
            stationaryAddresses.map((station: Station) => {
              const category = stationId && stationId === station.id ? selectedBtnCategory : unSelectedBtnCategory
              return (
                <CustomButton
                  key={station.id.toString()}
                  category={category}
                  label={`${station.address}`}
                  maxWidth="100%"
                  fontWeight="normal"
                  size="s"
                  onClick={() => {
                    getCoords(station.id)
                    setStation(station.id)
                  }}
                />
              )
            })}
        </Row>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding-top: 0.25rem;
`

const Row = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: minmax(120px, 1fr);
  }
`

export const CustomButton = styled(Button)`
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.yellow400};
    transition: all 0.5s;
    outline: none;
  }
`
