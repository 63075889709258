import React, { useState } from 'react'
import styled from 'styled-components'

import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  ImageWrapper,
  Section,
  SectionColumn,
} from '../../components/layout'
import { NumberInput, SelectInput, TextAreaInput, TextInput, DateInput, TimeInput } from '../../components/layout/form/input'
import { DateTimeInputWrapper } from '../../components/layout/form/style/ComboInputWrapper'
import { Divider } from '../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import { EditTowingOrder } from '../../containers/TowingOrderPage/EditTowingOrder/types'
import autoliitto from '../../images/autoliitto.png'
//import {persistentDelete, persistentGet} from '../../util/localStorage/localStorage'
import { arcComponentOptions, arcDescriptionOptions } from '../ALSubForm/options'
import { Button } from '../Button'
import { ALTimeChangeModal } from './ALTimeChangeModal'

interface ALOrderSubFormProps {
  serviceTypeOptions: object[]
  setFieldValue: (field: string, value: any) => void
  towingOrder: EditTowingOrder
  getOriginalValue: (field: string) => any
}

export const ALOrderSubForm: React.FC<ALOrderSubFormProps> = ({ serviceTypeOptions, setFieldValue, towingOrder, getOriginalValue}) => {
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false)
  // const [timeFieldEnabled, setTimeFieldEnabled] = useState<boolean>(false)
  /* const editTowingTime = useCallback(
    (delayedTowing: boolean, ) => {
      //setTimeFieldEnabled(true)
      setShowTimeModal(false)
      persistentDelete('ALTimeChangeData')
      if (onEditTime) {
        onEditTime(delayedTowing)
      }
    },
    [onEditTime]
  ) */

  const closeTimeChangeModal =()=> {
    
    setShowTimeModal(false)

    //persistentDelete('ALTimeChangeData')
    
  }
  return (
    <>
      {showTimeModal && (
        <ALTimeChangeModal label="Muuta aika" onClose={closeTimeChangeModal} isVisibleTimeInput={true} setFieldValue={setFieldValue} getOriginalFieldValue={getOriginalValue} useQueryOnly={false} towingOrder={towingOrder}/>
      )}
      <ALFormSubGroupWrapper>
        <ALFormSubGroupHeader>
          <FormSubGroupHeading>
            <ImageWrapper>
              <img src={autoliitto} alt="AL" width={`95px`} />
            </ImageWrapper>
          </FormSubGroupHeading>
            <Button category="on" label="Muuta aika" onClick={() => setShowTimeModal(true)} size="s" maxWidth='10rem' />
        </ALFormSubGroupHeader>
        <FormSubGroup>
          <Section columns={2}>
            {getOriginalValue('jobDetails.towingDateType') === 'single' 
              ? <><DateTimeInputWrapper>
                  <DateInput label={'Päivämäärä'} name="jobDetails.towingDate" disabled={true}/>
                  &nbsp;
                  <TimeInput label="Klo" name="jobDetails.towingTime" disabled={true} />
                </DateTimeInputWrapper> 
                <SectionColumn /></>
              : null}
            {getOriginalValue('jobDetails.towingDateType') === 'span'
            ? <><DateTimeInputWrapper>
                <DateInput label={"Pvm alkaen"} name="jobDetails.towingDate" disabled={true}/>
                &nbsp;<Divider alignSelfEnd />&nbsp;
                <DateInput label={"Pvm viimeistään"} name="jobDetails.towingEndDate" disabled={true}/>
              </DateTimeInputWrapper>
              <DateTimeInputWrapper>
                <TimeInput label="Alkaen klo" name="jobDetails.towingTime" disabled={true} />
                &nbsp;<Divider alignSelfEnd />&nbsp;
                <TimeInput label="Viimeistään klo" name="jobDetails.towingEndTime" disabled={true} />
              </DateTimeInputWrapper></>
            : null}
            {/* <SectionColumn>
            <TimeInput label="Klo" name="jobDetails.towingTime" />
          </SectionColumn> */}
            <SectionColumn>
              <TextInput label="Tilauksen viite" name="automobileAndTouringClubFinland.caseNumber" disabled />
            </SectionColumn>
            <SectionColumn>
              <TextInput label="Laskutus" name="automobileAndTouringClubFinland.billingInfo" disabled />
            </SectionColumn>
            <SectionColumn>
              <NumberInput label="Henkilömäärä" name="automobileAndTouringClubFinland.personCount" decimals={0} />
            </SectionColumn>
            <SectionColumn>
              <TextInput label="Palvelukanava" name="automobileAndTouringClubFinland.serviceChannel" disabled />
            </SectionColumn>

            {/* <SectionColumn>
            <NumberInput label="Mittarilukema, km" name="automobileAndTouringClubFinland.vehicleMileage" decimals={0} />
          </SectionColumn> */}

            {/*<SectionColumn>
            {serviceType === TowingRecordServiceType.TOWING && (
              <NumberInput label="Laskutettavat kilometrit" name="automobileAndTouringClubFinland.distanceTowed" />
            )}
          </SectionColumn>*/}
          </Section>
          <Section>
            <SectionColumn>
              <SelectInput label="Työn tyyppi" name="jobDetails.serviceType" options={serviceTypeOptions} />
            </SectionColumn>
            <SectionColumn>
              <SelectInput
                label="Komponentti (ARC-vikakoodi)"
                name="automobileAndTouringClubFinland.arcCodes.component.code"
                options={arcComponentOptions}
              />
            </SectionColumn>
            <SectionColumn>
              <SelectInput
                label="Vian kuvaus (ARC-vikakoodi)"
                name="automobileAndTouringClubFinland.arcCodes.description.code"
                options={arcDescriptionOptions}
              />
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <TextAreaInput label="Työselvitys Autoliitolle" name="jobDetails.additionalInfo" />
            </SectionColumn>
            <SectionColumn>
              <TextAreaInput label="Muistiinpanot (sisäiseen käyttöön)" name="jobDetails.notes" />
            </SectionColumn>

            {/*<SectionColumn>
            <TextAreaInput
              label="Autoliiton tilannearvio"
              name="automobileAndTouringClubFinland.situationDescription"
              disabled
            />
          </SectionColumn>*/}
          </Section>
        </FormSubGroup>
      </ALFormSubGroupWrapper>
    </>
  )
}

export const ALFormSubGroupHeader = styled(FormSubGroupHeader)`
  background-color: #fd0;
`
export const ALFormSubGroupWrapper = styled(FormSubGroupWrapper)`
  border-color: #fd0 !important;
`